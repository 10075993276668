import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PradeshSabha } from 'src/app/shared/models/pradeshSabha';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-pradesh-sabha',
  templateUrl: './add-pradesh-sabha.component.html',
  styleUrls: ['./add-pradesh-sabha.component.scss']
})
export class AddPradeshSabhaComponent implements OnInit {

  pratinidhiSabhaList:[];
  districtList:[];
  pradeshsabhaid:number =0;
  pradeshSabhaForm: FormGroup;
  btnSave = 'Save';
  constructor(private fb: FormBuilder,private router:Router,private route: ActivatedRoute, private _pratinidhi:PratinidhiSabhaService,private _pradeshsabha:PradeshSabhaService,private alertify: AlertyfyService,private spinningManager: SpinnerManagerService,private districtService:DistrictService) {
    this.pradeshSabhaForm = this.fb.group({
      districtId: '',
      name: '',
      description:'',
      pradeshSabhaCode:'',
    });
    this.pradeshsabhaid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

    if (this.pradeshsabhaid > 0) {
      this.editPradeshSabha();
    }
    this.spinningManager.hideSpinner();
  }

  ngOnInit(): void {
    this.getDistrict();
  }

  getDistrict(){
    this.districtService.getAllDistrict().subscribe(res=>{
      this.districtList = res;
      this.spinningManager.hideSpinner();
    },
    (error) => {
      this.alertify.error(error);
      this.spinningManager.hideSpinner();
    });
  }

  // getAllPratinidhi() {
  //   this._pratinidhi.getAllPratinidhiSabha().subscribe(
  //     (res) => {
  //       this.pratinidhiSabhaList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }


  savePradeshSabha() {
    if (this.pradeshsabhaid && this.pradeshsabhaid > 0) {
      const pradeshSabhaupdatedata = {
        id: this.pradeshsabhaid,
        name: this.pradeshSabhaForm.controls.name.value,
        description: this.pradeshSabhaForm.controls.description.value,
        pradeshSabhaCode: this.pradeshSabhaForm.controls.pradeshSabhaCode.value,
        pratinidhiSabhaId: this.pradeshSabhaForm.controls.pratinidhiSabhaId.value,
        districtId: this.pradeshSabhaForm.controls.districtId.value,
        
      };
      this._pradeshsabha.updatePradeshSabha(pradeshSabhaupdatedata).subscribe(
        (result) => {
          this.spinningManager.showSpinner('Saving.....');
          this.router.navigateByUrl('/managedelevery/pradesh-sabha-list');
          this.spinningManager.hideSpinner();
        },
        (error) => {
          this.spinningManager.hideSpinner();
        }
      );
    } else {
      var _pradeshsabha = new PradeshSabha();
      var pradeshsabha = this.pradeshSabhaForm.value;
      _pradeshsabha.id = Number(pradeshsabha.id) || 0;
      _pradeshsabha.name = pradeshsabha.name;
      _pradeshsabha.description = pradeshsabha.description;
      _pradeshsabha.pradeshSabhaCode = pradeshsabha.pradeshSabhaCode;
      _pradeshsabha.pratinidhiSabhaId = Number(pradeshsabha.pratinidhiSabhaId);
      _pradeshsabha.districtId =  Number(pradeshsabha.districtId);
      this._pradeshsabha.createPradeshSabha(_pradeshsabha).subscribe(
        (res) => {
          if (res) {
            this.pradeshSabhaForm.reset();
            this.router.navigateByUrl('/managedelevery/pradesh-sabha-list');
            this.spinningManager.hideSpinner();
          }
        },
        (error) => {
          this.alertify.error(error);
          this.spinningManager.hideSpinner();
        }
      );
    }
    (error) => {
      this.spinningManager.hideSpinner();
      this.router.navigateByUrl('/managedelevery/pradesh-sabha-list');
    };
  }

  editPradeshSabha() {
    this._pradeshsabha.getPradeshSabhaById(this.pradeshsabhaid).subscribe(
      (result) => {
        this.spinningManager.showSpinner('Loading.....');
        this.pradeshSabhaForm = this.fb.group({
          name: result['name'],
          description: result['description'],
          pradeshSabhaCode: result['pradeshSabhaCode'],
          // pratinidhiSabhaName: result['pratinidhiSabhaName'],
          pratinidhiSabhaId: result['pratinidhiSabhaId'],
          districtId: result['districtId'],
        });
        this.btnSave = 'Update';

      },
      (error) => {
        this.alertify.error(error.error);
        this.router.navigateByUrl('/managedelevery/pradesh-sabha-list');
      }
    );
    this.spinningManager.hideSpinner();
  }


}
