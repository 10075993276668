import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from 'src/app/shared/core/AuthGaurd';
import { systemPermissions } from 'src/app/shared/enums/systemPermissions';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: "User Profile ",
          breadcrumb: "User Profile"
        }
      },
      {
        path: 'list-user',
        component: ListUserComponent,
        canActivate: [AuthGuard],
        data: {
          title: "User List",
          systemPermissionIds:[systemPermissions.User_List],
          breadcrumb: "User List"
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: "Create User",
          breadcrumb: "Create User"
        }
      },
      {
        path: 'edit-user/:id',
        component: CreateUserComponent,
        data: {
          title: 'Edit User',
          breadcrumb: 'Edit User'
        }
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'Change Password',
          breadcrumb: 'Change Password'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
