<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-header">

      <ul class="user-listing">
          <li class="heading-list">
            User List
          </li>
          <!-- <li>
            <a *ngIf="this.mps.manageDataPermission([dataPermission.User_Add])" class="btn btn-success"
            [routerLink]="'/users/create-user'">Create User</a>
          </li> -->
          <!-- <li>
            <button *ngIf="
            this.mps.manageDataPermission([dataPermission.User_SyncData])
          " type="button" class="btn btn-success" (click)="syncIdentityUsers()">
              Sync User Data
            </button>
          </li> -->
          <li>
            <a href="javascript:void(0)" (click)="showHideFilter()" class="filter-content">
              <!-- filter -->
              <i class="fa fa-filter" aria-hidden="true"></i> Filter</a>
          </li>
      </ul>
</div>




    <div class="card-body margin-sm no-margs">
      <div class="row filter-contain" *ngIf="showFilters">




          <div class="col-md-6 col-sm-12">
            <label for="searchText">Search Text</label>
          <input type="text" class="form-control" id="searchText" (keyup)="filterTextChange($event)"
            (keyup.enter)="searchUserDetails()" [(ngModel)]="queryObject.filtertext" />
          </div>
          <!-- <div class="col-md-2 col-sm-12">
            <label for="exampleFormControlInput1">Role</label>
            <select class="form-control" (change)="selectChangeHandler($event)" [(ngModel)]="queryObject.roleId">
              <option value="0">-- Select --</option>
              <optgroup *ngFor="let group of roles" label="Level {{ group.groupId }}">
                <option *ngFor="let item of group.roles" [value]="item.id">
                  {{ item.displayName }}
                </option>
              </optgroup>
            </select>
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="storeId">Store</label>
          <select class="form-control" (change)="selectedStoreChange($event)" [(ngModel)]="queryObject.storeId">
            <option value="0">-- Select --</option>
            <option *ngFor="let item of storeList" [value]="item.id">
              {{ item.name }}
            </option>
          </select>
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="userStatus">Status</label>
          <select class="form-control" (change)="selectedUserStatus($event)" [(ngModel)]="queryObject.userStatusId">
            <option value="0">-- Select --</option>
            <option value="1">Registered</option>
            <option value="2">Email Verified</option>
            <option value="3">Unlocked</option>
            <option value="4">Locked</option>
            <option value="5">Registered + Locked</option>
            <option value="6">Registered + Unlocked</option>
            <option value="7">Email Verified + Locked</option>
            <option value="8">Email Verified + Unlocked</option>
          </select>
          </div> -->

          <div class="col-md-2 col-sm-12">

            <label style="visibility: hidden;display: none;">Action</label>
              <a class="btn btn-secondary w-100 mt-3 nomargs" style="color: #fff" (click)="searchUserDetails()">Search</a>

          </div>
          <div class="col-md-2 col-sm-12">

            <button type="button" class="btn btn-primary w-100 mt-3 nomargs" (click)="reset()">
              Reset
            </button>

          </div>








      </div>
      <div class="btn-popup pull-right"></div>
      <!-- <i (click)="exportToExcel();" class="fa fa-file-excel-o fa-3x pointer:hand;" style="color: red; margin-left: 8px;cursor: pointer;" aria-hidden="true"></i>
            <i (click)="exportToPdf();" class="fa fa-file-pdf-o fa-3x pointer:hand" aria-hidden="true" style="color: red; margin-left: 8px;cursor: pointer;" aria-hidden="true"></i> -->








      <div class="export-btn mobile-margs">

        <div class="d-flex justify-content-start flex-wrap">

          <div class="btn-group btn-group1 mr-2 mobile-margs" *ngIf="
          this.mps.manageDataPermission([dataPermission.User_Export_Excel])
        ">
            <button type="button" class="btn btn-secondary btn1">
              <i class="fa fa-file-excel-o" aria-hidden="true"></i> Export to Excel
            </button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button ngbDropdownItem (click)="exportToExcel(false)">
                  Current Page
                </button>
                <button ngbDropdownItem (click)="exportToExcel(true)">
                  All Page
                </button>
              </div>
            </div>
          </div>



          <div class="btn-group btn-group1" *ngIf="this.mps.manageDataPermission([dataPermission.User_Export_PDF])">
            <button type="button" class="btn btn-secondary btn1">
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Export to PDF
            </button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
              <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button ngbDropdownItem (click)="exportToPdf(false)">
                  Current Page
                </button>
                <button ngbDropdownItem (click)="exportToPdf(true)">
                  All Page
                </button>
              </div>
            </div>
          </div>

        </div>





      </div>



      <div class="category-table custom-datatable transcation-datatable user-image">
        <div class="table-responsive">
          <!-- <ng2-smart-table [settings]="settings" [source]="user_list"></ng2-smart-table> -->
          <table class="table text-nowrap table-bordered">
            <thead>
              <th *ngFor="let c of columns">
                <div *ngIf="c.isSortable" (click)="sortBy(c.key)">
                  {{ c.title }}
                  <i *ngIf="queryObject.sortBy === c.key" class="fa" [class.fa-sort-asc]="queryObject.isSortAsc"
                    [class.fa-sort-desc]="!queryObject.isSortAsc"></i>
                </div>
                <div *ngIf="!c.isSortable">
                  {{ c.title }}
                </div>
              </th>
            </thead>
            <tbody *ngIf="userlist?.length; else no_data_temp">
              <tr *ngFor="let user of userlist">
                <td>{{ user.id }}</td>
                <td>{{ user.firstName }}</td>
                <td>{{ user.lastName }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.phoneNumber }}</td>
                <td>{{ user.roleNames }}</td>

                <td class="no-whitespace" width="10%">
                  <a *ngIf="
                      this.mps.manageDataPermission([dataPermission.User_Edit])
                    " [routerLink]="['/users/edit-user', user.id]" class="btn btn-success mb-2 w-100">Edit</a>
                  <div *ngIf="user.roleNames.includes('Store Staff')">
                    <ng-container *ngIf="user.autoApproval">
                      <button class="btn btn-success mb-2 w-100" (click)="disableAutoApproval(user.id)">
                        Disable Auto Approval
                      </button>
                    </ng-container>
                    <ng-container *ngIf="!user.autoApproval">
                      <button *ngIf="
                          this.mps.manageDataPermission([
                            dataPermission.User_Enable_Auto_Approval
                          ])
                        " class="btn btn-success mb-2 w-100" (click)="enableAutoApproval(user.id)">
                        Enable Auto Approval
                      </button>
                    </ng-container>
                  </div>
                  <!-- <button *ngIf="
                      this.mps.manageDataPermission([
                        dataPermission.User_Reset_Password
                      ])
                    " class="btn btn-success mb-2 w-100" (click)="sendResetPasswordEmail(user.id)">
                    Reset Password
                  </button>
                  <button [hidden]="
                      !this.mps.manageDataPermission([
                        dataPermission.User_SyncData
                      ])
                    " *ngIf="user.isEmailVerified == false" class="btn btn-success mb-2 w-100"
                    (click)="sendEmailConfirmationEmail(user.id)">
                    Resend Email Verification Link
                  </button> -->
                  <button *ngIf="
                      this.mps.manageDataPermission([dataPermission.User_Lock])
                    " class="btn btn-success mb-2 w-100" (click)="lockUnlockUser(user)">
                    {{ user.isUserLocked ? "Unlock User" : "Lock User" }}
                  </button>
                </td>
              </tr>
            </tbody>
            <ng-template #no_data_temp>
              <tbody>
                <td colspan="7">
                  <h4 class="text-center d-block w-100 mt-3">
                    {{ this.noDataFound }}
                  </h4>
                </td>
              </tbody>
            </ng-template>
          </table>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <ngb-pagination [collectionSize]="queryObject.pageCount" [pageSize]="queryObject.pageSize"
              [page]="queryObject.page" (pageChange)="onPageChange($event)" aria-label="Default pagination">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
