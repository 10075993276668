import { Component, OnInit } from '@angular/core';
import { Municipality } from 'src/app/shared/models/municipality';
import { MuncipalityQueryObject, SearchQueryObject } from '../../../shared/models/queryObject';
import { Router } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { MunicipalityService } from 'src/app/shared/service/ManageDelivery/municipality.service';
import { environment } from 'src/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
import { CountryList } from '../province-list/province-list.component';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { ProvinceDropDownList } from '../district-list/district-list.component';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';

@Component({
  selector: 'app-municipality-list',
  templateUrl: './municipality-list.component.html',
  styleUrls: ['./municipality-list.component.scss']
})
export class MunicipalityListComponent implements OnInit {
  pageSize = environment.pageSize;
  public Municipality = [];
  public municipalityList: Municipality[];
  public municipalityItem: any = [];
  noDataFound: string;
  queryObject = new MuncipalityQueryObject();
  showFilters: Boolean = false;
  public dataPermission = DataPermissions;
  public provinceList: ProvinceDropDownList[] = [];
  public countryList: CountryList[] = [];
  public districtList: DistrictDropDown[] = [];

  columns = [
    // { title: ' ID', key: 'id', isSortable: true },
    { title: ' Municipality', key: 'municipality', isSortable: true },
    { title: 'Description', key: 'description', isSortable: true },
    { title: 'District', key: 'district', isSortable: true },
    { title: 'Province', key: 'province', isSortable: true },
    { title: 'Country', key: 'country', isSortable: true },
    { title: 'Action' }
  ];

  constructor(
    private municipalityService: MunicipalityService,
    private _router: Router,
    private alertyfy: AlertyfyService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private districtService: DistrictService
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }


  ngOnInit() {
    this.noDataFound = environment.noDataFound;
    this.getAllCountries();
    //this.getProvinces();
    this.Reset();
  }

  getMunicipalities() {
    this.spinningManager.showSpinner("Loading.....");
    this.municipalityService.getMunicipalities(this.queryObject).subscribe((res: any) => {
      this.queryObject.pageCount = res.totalItems;
      this.municipalityList = res.items;
      this.spinningManager.hideSpinner();
    }, error => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });
  }
  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {
    })
  }
  getProvinces() {
    this.provinceService.getAllProvince()
      .subscribe(res => {
        this.provinceList = res;
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      });
  }

  getAllDistrictsByProvinceId(provinceId) {
    this.districtService.getDistrictByProvince(provinceId)
      .subscribe(res => {
        this.districtList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.districtId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.districtList = null;
    this.provinceList = null;
    this.getMunicipalities();
  }
  public Search() {
    this.getMunicipalities();
  }



  public sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.getMunicipalities();
  }
  public onPageChange(page) {
    this.queryObject.page = page;
    this.getMunicipalities();
  }
  public changePageSize(event) {
    this.queryObject.pageSize = Number(event.target.value);
    this.getMunicipalities();
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;
  }
  onDeleteConfirm(id) {
    this.alertyfy.confirm
      (
        "Are you sure you want to delete?",
        () => {
          this.municipalityService.deleteMunicipalities(id)
            .subscribe(res => {
              this.alertyfy.success("Deleted Successfully.");
              this.getMunicipalities();
            },
              (error) => {
                this.alertyfy.error("Something went wrong. Delete Failed.");
              });
        }
      );
  }
  public exportToExcel(flag: boolean) {

    this.queryObject.printall = flag;
    this.municipalityService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'MunicipalityList.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.municipalityService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'MunicipalityList.pdf');
    });
  }
}
export interface DistrictDropDown {
  districtId: number;
  districtName: string;
}





