<form [formGroup]="municipalityListForm">

    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5> Add Municipality</h5>
            </div>

            <div class="row card-body tab2-card" >
                <div class="col-12">
                    <div class="form-group row">
                        <label for="countryId" class="col-xl-3 col-md-3">Country</label>
                        <div class="col-xl-9 col-md-9">
                             <select (change)="onCountrySelection($event)" class="form-control" formControlName="countryId" id="countryId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of countryList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="form-group row">
                        <label for="provinceId" class="col-xl-3 col-md-3">Province</label>
                        <div class="col-xl-9 col-md-9">
                            <select (change)="onProvinceSelection($event)" class="form-control" formControlName="provinceId" id="provinceId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of provinceList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="districtId" class="col-xl-3 col-md-3">District</label>
                        <div class="col-xl-9 col-md-9">

                            <select (change)="onDistrictSelection($event)" class="form-control" formControlName="districtId" id="districtId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of districtList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span> Municipality</label>
                        <div class="col-xl-9 col-lg-9">
                            <input class="form-control " formControlName="name"
                            name="name" type="text" required="">
                        </div>

                    </div>
                    <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-3" >Description</label>
                       <div class="col-xl-9 col-lg-9">
                          <textarea rows="4" class="w-100 form-control" formControlName="description" required></textarea>
                       </div>
                    </div>
                    <!-- <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-4"> Description</label>
                        <div class="col-12 mt-2" >
                           <ck-editor formControlName="description" id="description" language="en" [fullPage]="false"></ck-editor>
                        </div>
                     </div> -->
                    <!-- <div class="form-group row">
                        <label for="name" class="col-xl-3 col-md-3">Description</label>
                        <div class="col-xl-9 col-lg-9">
                            <input class="form-control " formControlName="description"
                        name="description" type="text" required="">
                        </div>
                    </div> -->
                     <div class="pull-right">

                        <button type="button" class="btn btn-primary " [routerLink]="['/managedelevery/municipality-list']">
                            Discard
                        </button>
                        <button type="button" class="btn btn-success ml-2" [routerLink]="['/managedelevery/municipality-list']" (click)="saveMunicipalityList()">
                            {{this.municipalityid>0?"Update":"Save"}}
                        </button>
                    </div>
                </div>
            </div>
         </div>
    </div>
</form>

