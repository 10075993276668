import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Doctor } from 'src/app/shared/models/doctor';
import { ChetraService } from 'src/app/shared/service/ManageDelivery/chetra.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { DoctorService } from 'src/app/shared/service/ManageDelivery/doctor.service';
import { MunicipalityService } from 'src/app/shared/service/ManageDelivery/municipality.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { ProgramService } from 'src/app/shared/service/ManageDelivery/program.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-doctor',
  templateUrl: './create-doctor.component.html',
  styleUrls: ['./create-doctor.component.scss']
})
export class CreateDoctorComponent implements OnInit {

  doctorListForm: FormGroup;
  btnSave = 'Save';
  doctorid: number = 0;
  countryList: any[];
  provinceList: any[];
  districtList: any[];
  pratinidhiList: any[];
  pradeshList: any[];
  chetraList: any[];
  municipalityList: any[];
  wardList: [];
  registedBranchList: [];
  genderList: any;
  currentCountryId: number;
  pradeshSabhaId: number;
  currentProvinceId: number;
  currentDistrictId: number;
  public response: { dbPath: '' };
  programList: [];
  formSubmitted: boolean = false;
  imageUrl: any = '';
  minDate:any;
  formStatus : string = 'Create';

  constructor(
    private formBuilder: FormBuilder,
    private doctorService: DoctorService,
    private spinningManager: SpinnerManagerService,
    private programService: ProgramService,
    private router: Router,
    private alertify: AlertyfyService,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private pratinidhiService: PratinidhiSabhaService,
    private pradeshService: PradeshSabhaService,
    private chetraService: ChetraService,
    private route: ActivatedRoute,
    private municipalityService: MunicipalityService
  ) {
    this.doctorListForm = this.formBuilder.group({
      id: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      sex: '',
      dateOfBirth: '',
      mobile: [''],
      phone: [''],
      job: '',
      countryId: '',
      provinceId: '',
      districtId: '',
      municipalitiesId: '',
      wardNo: '',
      pratinidhiSabhaId: '',
      pradeshSabhaId: '',
      //programsName: '',
      qualification : '',
      // chetraId: '',
      nmcNumber :  [''],
      nmcRegistrationDate : '',
      isPermanent: [false],
      designation: '',
      totalExperience: '',
      currentHospitalAddress: '',
      currentHospitalContactNo: '',
      isPrivateHospital : [false],
      affiliatedUniversity : '',
      collegeName : '',
      passedCountryId : '',
      passedDate : '',
      financialScheme: '',
      email :[''],
      bloodGroup: '',
      ndaNumber: '',
      imagePath: '',
      registedOnBranchId:  [null],
    });
    this.doctorid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

    // this.genderList = [{ value: 1, text: "Male", selected: "checked" }, { value: 2, text: "Female", selected: "" }];
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit(): void {
    // this.doctorListForm.controls["sex"].setValue(2);

    if (this.doctorid > 0) {
      this.editDoctor();
    }
    this.spinningManager.hideSpinner();
    this.getRegistedBranch();
    this.getAllCountries();
    this.getAllProgram();
  }

  memberFormControl() {
    return this.doctorListForm.controls;
  }

  getAllCountries() {
    this.countryService.getAllCountry().subscribe(
      (res) => {
        this.countryList = res;
        if (this.doctorid == 0) {
          var countryInx = this.countryList.findIndex(
            (a) => a.countryCode === 'NP'
          );
          this.currentCountryId = this.countryList[countryInx].id;
          this.loadProvince();
          this.doctorListForm.controls['countryId'].setValue(
            this.countryList ? this.countryList[countryInx].id : 0
          );
        }

        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }

  onCountrySelection(event) {
    this.currentCountryId = Number(event.target.value);
    this.loadProvince();
   
  }
 
  loadProvince() {
    this.provinceService.getProvinceByCountry(this.currentCountryId).subscribe(
      (res) => {
        this.provinceList = res;
        if (this.doctorid == 0) {
          var countryInx = this.provinceList.findIndex(
            (a) => a.provinceCode === 'MP'
          );
          this.currentProvinceId = this.provinceList[countryInx].id;
          this.doctorListForm.controls['provinceId'].setValue(
            this.currentProvinceId
          );
          this.districtList = [];
          this.loadDisctrict();
        }
      },
      (error) => {}
    );
  }

  get formControls(){
    return this.doctorListForm.controls;
  }

  onProvinceSelection(event) {
    this.currentProvinceId = Number(event.target.value);
    this.loadDisctrict();
  }

  loadDisctrict() {
    this.districtService
      .getDistrictByProvince(this.currentProvinceId)
      .subscribe(
        (res) => {
          this.districtList = res;
        },
        (error) => {}
      );
  }

  // onPratinidhiSelection(event){
  //   this.pradeshSabhaId=Number(event.target.value);
  //   this.pradeshService.getPradeshSabhaByPratinidhiId(Number(event.target.value))
  //   .subscribe(res=>{
  //     this.pradeshList=res;
  //   },
  //   error=>{
  //   });
  // }

  // getAllProvince() {
  //   this.provinceService.getAllProvince().subscribe(
  //     (res) => {
  //       this.provinceList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }
  // getAllDistrict() {
  //   this.districtService.getAllDistrict().subscribe(
  //     (res) => {
  //       this.districtList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }
  // getAllPratinidhi() {
  //   this.pratinidhiService.getAllPratinidhiSabha().subscribe(
  //     (res) => {
  //       this.pratinidhiList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //     (error) => {
  //       this.alertify.error(error);
  //       this.spinningManager.hideSpinner();
  //     }
  //   );
  // }

  getAllProgram() {
    this.programService.getAllPrograms().subscribe((res) => {
      this.programList = res;
    });
    this.getAllProvince();
    this.getAllMunicipalities();
    this.getAllPratinidhi();
    this.getAllPradesh();
    this.getAllDistrict();
  }
  getRegistedBranch(){
    this.doctorService.getRegistedBranch().subscribe((res)=>{
       this.registedBranchList = res;
    })
  }
  getAllMunicipalities() {
    this.municipalityService.getAllMunicipalities().subscribe((res) => {
      this.municipalityList = res;
    });
  }
  getAllProvince() {
    this.provinceService.getAllProvince().subscribe((res) => {
      this.provinceList = res;
    });
  }

  getAllDistrict() {
    this.districtService.getAllDistrict().subscribe((res) => {
      this.districtList = res;
    });
  }

  getAllPratinidhi() {
    this.pratinidhiService.getAllPratinidhiSabha().subscribe((res) => {
      this.pratinidhiList = res;
    });
  }

  onDistrictSelection(event) {
    this.currentDistrictId = Number(event.target.value);
    this.pratinidhiService
      .getPratinidhiSabhaByDistrict(Number(event.target.value))
      .subscribe(
        (res) => {
          this.pratinidhiList = res;
        },
        (error) => {}
      );
    this.municipalityService
      .getMunicipalitiesByDistrict(Number(event.target.value))
      .subscribe(
        (res) => {
          this.municipalityList = res;
        },
        (error) => {}
      );
    this.pradeshService
      .getPradeshSabhaByDistrictId(Number(event.target.value))
      .subscribe(
        (res) => {
          this.pradeshList = res;
        },
        (error) => {}
      );
  }
  getAllPradesh() {
    this.pradeshService.getAllPradeshSabha().subscribe(
      (res) => {
        this.pradeshList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }
  getAllChetra() {
    this.chetraService.getAllChetra().subscribe(
      (res) => {
        this.chetraList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }
  // private getNgbFormatDate(_date_) {
  //   var result = _date_.split("-");
  //   var ngbDate = {
  //     year: Number(result[0]),
  //     month: Number(result[1]),
  //     day: Number(result[2]),
  //   };
  //   return ngbDate;
  // }

  editDoctor() {
    this.doctorService.getMemberById(this.doctorid).subscribe(
      (result) => {
        this.spinningManager.showSpinner('Loading.....');
        this.doctorListForm = this.formBuilder.group({
          firstName: result['firstName'],
          lastName: result['lastName'],
          imagePath: result['imagepath'],
          //nmcRegistrationDate : result['nmcRegistrationDate'],
          nmcRegistrationDate : result['nmcRegistrationDate']!=null||''?result['nmcRegistrationDate'].split("T")[0]:"",
          sex: result['sex'],
          mobile: result['mobile'],
          phone: result['phone'],
          dateOfBirth: result['dateOfBirth'],
          job: result['job'],
          countryId: result['countryId'],
          provinceId: result['provinceId'],
          districtId: result['districtId'],
          municipalitiesId: result['municipalitiesId'],
          wardNo: result['wardNo'],
          pratinidhiSabhaId: result['pratinidhiSabhaId'],
          pradeshSabhaId: result['pradeshSabhaId'],
          // programsName: result['programsName'],
          // chetraId: result['chetraId'],
          qualification: result['qualification'],
          nmcNumber: result['nmcNumber'],
          isPermanent: result['isPermanent'],
          designation: result['designation'],
          totalExperience: result['totalExperience'],
          currentHospitalAddress: result['currentHospitalAddress'],
          currentHospitalContactNo: result['currentHospitalContactNo'],
          isPrivateHospital: result['isPrivateHospital'],

          //program : result['program'],
          affiliatedUniversity : result['affiliatedUniversity'],
          collegeName : result['collegeName'],
          passedCountryId : result['passedCountryId'],
          passedDate : result['passedDate'],
          financialScheme: result['financialScheme'],
          email : result['email'],
          bloodGroup: result['bloodGroup'],
          ndaNumber: result['ndaNumber'],
          registedOnBranchId: result['registedOnBranchId'],
        });
        this.imageUrl = environment.imageUrl + result['imagepath'];
        this.btnSave = 'Update';
        this.formStatus = 'Edit';

      },
      (error) => {
        this.alertify.error(error.error);
        this.router.navigateByUrl('/managedelevery/doctor-list');
      }
    );
    this.spinningManager.hideSpinner();
  }

  saveDoctorList() {
    if (this.doctorListForm.invalid) {
      // Mark all form controls as touched to show validation errors
      this.doctorListForm.markAllAsTouched();
      return;
    }

    if (this.doctorid && this.doctorid > 0) {
      const doctorupdatedata = {
        id: this.doctorid,
        firstName: this.doctorListForm.controls.firstName.value,
        lastName: this.doctorListForm.controls.lastName.value,
        imagepath: this.response?.dbPath.length>0 ? this.response?.dbPath : this.doctorListForm.controls.imagePath.value,
        sex: this.doctorListForm.controls.sex.value,
        dateOfBirth: this.doctorListForm.controls.dateOfBirth.value,
        mobile: this.doctorListForm.controls.mobile.value,
        phone: this.doctorListForm.controls.phone.value,
        job: this.doctorListForm.controls.job.value,
        countryId: this.doctorListForm.controls.countryId.value,
        provinceId: this.doctorListForm.controls.provinceId.value,
        districtId: this.doctorListForm.controls.districtId.value,
        pratinidhiSabhaId: this.doctorListForm.controls.pratinidhiSabhaId.value,
        pradeshSabhaId: this.doctorListForm.controls.pradeshSabhaId.value,
        // chetraId: this.doctorListForm.controls.chetraId.value,
        municipalitiesId: this.doctorListForm.controls.municipalitiesId.value,
        wardNo: this.doctorListForm.controls.wardNo.value,
        qualification: this.doctorListForm.controls.qualification.value,
        //programsName: this.doctorListForm.controls.programsName.value,
        nmcNumber: this.doctorListForm.controls.nmcNumber.value,
        nmcRegistrationDate: this.doctorListForm.controls.nmcRegistrationDate.value,
        isPermanent: this.doctorListForm.controls.isPermanent.value,
        designation: this.doctorListForm.controls.designation.value,
        totalExperience: this.doctorListForm.controls.totalExperience.value,
        currentHospitalAddress: this.doctorListForm.controls.currentHospitalAddress.value,
        currentHospitalContactNo: this.doctorListForm.controls.currentHospitalContactNo.value,
        isPrivateHospital: this.doctorListForm.controls.isPrivateHospital.value,
        //program: this.doctorListForm.controls.program.value,
        affiliatedUniversity: this.doctorListForm.controls.affiliatedUniversity.value,
        collegeName: this.doctorListForm.controls.collegeName.value,
        passedCountryId: this.doctorListForm.controls.passedCountryId.value,
        passedDate: this.doctorListForm.controls.passedDate.value,
        financialScheme: this.doctorListForm.controls.financialScheme.value,
        email: this.doctorListForm.controls.email.value,
        bloodGroup: this.doctorListForm.controls.bloodGroup.value,
        ndaNumber: this.doctorListForm.controls.ndaNumber.value,
        registedOnBranchId: this.doctorListForm.controls.registedOnBranchId.value,
      };
      this.doctorService.upsertMember(doctorupdatedata).subscribe(
        (result) => {
          this.spinningManager.showSpinner('Saving.....');
          this.router.navigateByUrl('/managedelevery/doctor-list');
          this.spinningManager.hideSpinner();
        },
        (error) => {
          this.spinningManager.hideSpinner();
        }
      );
    } else {
      if (this.doctorListForm.valid) {

        var _doctor = new Doctor();
        var doctor = this.doctorListForm.value;
        _doctor.id = Number(doctor.id) || 0;
        _doctor.firstName = doctor.firstName;
        _doctor.lastName = doctor.lastName;
        _doctor.sex = doctor.sex;
        _doctor.dateOfBirth = doctor.dateOfBirth;
        _doctor.mobile = doctor.mobile;
        _doctor.phone = doctor.phone;
        _doctor.job = doctor.job;
        _doctor.countryId = Number(doctor.countryId);
        _doctor.provinceId = Number(doctor.provinceId);
        _doctor.districtId = Number(doctor.districtId);
        _doctor.pratinidhiSabhaId = Number(doctor.pratinidhiSabhaId);
        _doctor.pradeshSabhaId = Number(doctor.pradeshSabhaId);
        // _doctor.chetraId = Number(doctor.chetraId);
        _doctor.municipalitiesId = Number(doctor.municipalitiesId);
        _doctor.wardNo = doctor.wardNo;
        _doctor.imagepath = this.response?.dbPath;
        _doctor.qualification = doctor.qualification;
        // _doctor.programsName = doctor.programsName;

        _doctor.nmcNumber = doctor.nmcNumber;
        _doctor.nmcRegistrationDate = doctor.nmcRegistrationDate;
        _doctor.isPermanent = doctor.isPermanent;
        _doctor.designation = doctor.designation;
        _doctor.totalExperience = doctor.totalExperience;
        _doctor.currentHospitalAddress = doctor.currentHospitalAddress;
        _doctor.currentHospitalContactNo = doctor.currentHospitalContactNo;
        _doctor.isPrivateHospital = doctor.isPrivateHospital;

        //_doctor.program = doctor.program;
        _doctor.affiliatedUniversity = doctor.affiliatedUniversity;
        _doctor.collegeName = doctor.collegeName;
        _doctor.passedCountryId = doctor.passedCountryId;
        _doctor.passedDate = doctor.passedDate;
        _doctor.financialScheme = doctor.financialScheme;

        _doctor.email = doctor.email;
        _doctor.bloodGroup = doctor.bloodGroup;
        _doctor.ndaNumber = doctor.ndaNumber;
        _doctor.registedOnBranchId = doctor.registedOnBranchId;


        this.doctorService.createMember(_doctor).subscribe(
          (res) => {
            if (res) {
              this.doctorListForm.reset();
              this.router.navigateByUrl('/managedelevery/doctor-list');
              this.spinningManager.hideSpinner();
            }
          },
          (error) => {
            this.alertify.error(error);
            this.spinningManager.hideSpinner();
          }
        );
      } 
      else {
        this.formSubmitted = true;
      }
    }
    (error) => {
      this.spinningManager.hideSpinner();
      this.router.navigateByUrl('/managedelevery/doctor-list');
    };
  }

  public uploadFinished = (event) => {
    this.response = event;
  };

  public createImgPath = (serverPath: string) => {
    return `${environment.imageUrl} ${serverPath}`;
  };
  
}
