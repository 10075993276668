<div class="box" style="    display: block;
width: 350px;
margin: auto;
background: #FFF;
padding: 17px;
margin-top: 200px;box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%);
}">
    <img src="../assets/images/mail.svg" style="width: 100px; display: block; margin:auto;"/>
    <p style="font-size:15px; margin-top: 15px; text-align: center;">You will be notified by email once your store is approved. Please visit following link</p>
    <a href="http://dev.kkinni.com/"><img src="../../assets/images/logo.png" style="width: 75px; display:block; margin:auto;"></a>
</div>
