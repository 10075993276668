import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RegionService } from 'src/app/shared/service/ManageDelivery/region.service';
// import { Router } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { Region } from 'src/app/shared/models/region';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-region',
  templateUrl: './add-region.component.html',
  styleUrls: ['./add-region.component.scss']
})
export class AddRegionComponent implements OnInit {
  regionListForm: FormGroup;
  countryList = [];
  dropdownSettings = {};
  regionid: number = 0;
  btnSave = 'Save';


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private regionService: RegionService,
    private countryService: CountryService,
    private alertyfy: AlertyfyService,
    private route: ActivatedRoute,
    private spinningManager: SpinnerManagerService
  ) {

    this.regionListForm = this.fb.group({
      id: '',
      countryId: '',
      name: '',
      description: ''

    });
    this.regionid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.getAllCountries();
    this.regionService.getRegionById(this.regionid).subscribe((result) => {
      this.regionListForm = this.fb.group({
        id: this.regionid,
        countryId: result["countryId"],
        name: result["name"],
        description: result["description"],

      });
      this.btnSave = 'Update';
    });
  }
  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      })
  }
  saveRegionList() {
    this.spinningManager.showSpinner("Saving.....");
    var _region = new Region();
    var regionObj = this.regionListForm.value;
    _region.id = Number(this.regionid) || 0;
    _region.countryId = Number(regionObj.countryId);
    _region.name = regionObj.name;
    _region.description = regionObj.description;
    this.regionService.upsertRegion(_region)
      .subscribe(res => {
        if (res) {
          this.alertyfy.success("Successfully Inserted.");
          this.regionListForm.reset();
          this.spinningManager.hideSpinner();
          this.router.navigateByUrl('/managedelevery/region-list');
        }
      },
        error => {
          this.spinningManager.hideSpinner();
          this.alertyfy.saveError("Region");
        });
  }
}
