export  enum DataPermissions{
    //Manage Location 
    Countries_ViewAll=4,
    Countries_Edit=5,
    Countries_Delete=6,
    Countries_Add=7,
    Countries_Export_Excel=9,
    Countries_Export_PDF=10,
    
    Province_ViewAll=119,
    Province_Edit=120,
    Province_Delete=121,
    Province_Add=122,
    Province_Export_Excel=123,
    Province_Export_PDF=124,

    District_ViewAll=125,
    District_Edit=126,
    District_Delete=127,
    District_Add=128,
    District_Export_Excel=129,
    District_Export_PDF=130,

    Muncipality_ViewAll=131,
    Muncipality_Edit=132,
    Muncipality_Delete=133,
    Muncipality_Add=134,
    Muncipality_Export_Excel=135,
    Muncipality_Export_PDF=136,

    Cities_ViewAll=137,
    Cities_Edit=138,
    Cities_Delete=139,
    Cities_Add=140,
    Cities_Export_Excel=141,
    Cities_Export_PDF=142,

    Regions_ViewAll=143,
    Regions_Edit=144,
    Regions_Delete=145,
    Regions_Add=146,
    Regions_Export_Excel=147,
    Regions_Export_PDF=148,


    

    //Products
    Products_Search=11,
    Products_Edit=12,
    Products_ViewAll=13,
    Products_Delete=14,
    Products_Add=15,
    Products_View_Version_Details=16,
    Product_Log=17,
    Products_Rating_Review=18,
    Products_Portal_Commission=19,
    Products_View_Details=20,
    Products_Export_Excel=21,
    Products_Export_PDF=22,
    Products_Approve=23,
    Products_Reject=24,

    //Inventory
    Inventory_Edit=25,
    Inventory_ViewAll=26,
    Inventory_Add=27,
    Inventory_Export_Excel=28,
    Inventory_Export_PDF=29,
    Inventory_Product_ViewAll=30,

    //Sales
    Orders_ViewAll=31,
    RunningOrders_ViewAll=32,
    Transactions_ViewALl=33,

     //Category
    Category_ViewAll = 34,
    Category_Add = 35,
    Category_Edit=54,
    MarketPlaceFee_ViewAll = 36,
    MasterList_ViewAll = 37,
    MasterList_Add = 38,
    MasterList_Edit = 39,
    MasterList_Delete = 40,
    MasterProperty_ViewAll = 42,
    MasterProperty_Add = 43,
    MasterProperty_Edit = 44,
    MasterProperty_Delete = 45,
    Category_Export_Excel = 46,
    Category_Export_PDF = 47,
    MarketPlaceFee_Export_Excel = 48,
    MarketPlaceFee_Export_PDF = 49,
    MasterList_Export_Excel = 50,
    MasterList_Export_PDF = 51,
    MasterProperty_Export_Excel = 52,
    MasterProperty_Export_PDF = 53,

    //Offers
    Offers_ViewAll=55,
    Offers_Add=56,
    Offers_Edit=57,
    Offers_Delete=58,
    Offers_Export_Excel = 59,
    Offers_Export_PDF = 60,

    //NewsLetter
    NewsLetter_ViewAll=61,
    NewsLetter_Add = 62,
    NewsLetter_Delete=63,
    NewsLetter_Export_Excel = 64,
    NewsLetter_Export_PDF = 65,
    NewsLetter_Edit = 66,
    Subscribers_ViewAll=67,
    Subscribers_Delete=68,
    Subscribers_Export_Excel = 69,
    Subscribers_Export_PDF = 70,

     //Slider
    Home_ViewAll=71,
    Home_Add=72,
    Home_Edit=73,
    Home_Delete=74,
    Affiliate_ViewAll = 75,
    Affiliate_Add = 76,
    Affiliate_Edit = 77,
    Affiliate_Delete = 78,
    Brand_Delete_slider=98,
    Brand_Edit_slider=99,
    Store_Delete_slider=100,
    Store_Edit_slider=101,

    //Email Management
    EmailTemplate_ViewAll=79,
    EmailTemplate_Add=80,
    EmailTemplate_Edit=81,
    EmailTemplate_Delete=82,
    EmailTemplate_Export_Excel=83,
    EmailTemplate_Export_PDF=84,
    EmailQueue_ViewAll=85,
    EmailQueue_Export_Excel=86,
    EmailQueue_Export_PDF=87,
    EmailLog_ViewAll=88,
    EmailLog_Export_Excel=89,
    EmailLog_Export_PDF=90,
    
    //Brands
    Brand_ViewAll=91,
    Brand_Add=92,
    Brand_Edit=93,
    Brand_Delete=94,
    Brand_Add_Slider=95,
    Brand_Export_Excel = 96,
    Brand_Export_PDF = 97,

    //Manage Delivery
    Delivery_Company_ViewAll=102,
    Delivery_Company_Add=103,
    Delivery_Company_Edit=104,
    Delivery_Company_Delete=105,
    Delivery_Company_Export_Excel = 106,
    Delivery_Company_Export_PDF=107,
    Shipping_Cost_variation_ViewAll=108,
    Shipping_Cost_variation_Add=109,
    Shipping_Cost_variation_Edit=110,
    Shipping_Cost_variation_Delete=111,
    Shipping_Cost_variation_Export_Excel = 112,
    Shipping_Cost_variation_Export_PDF=113,

    //Manage Store
    Store_ViewAll=149,
    Store_Edit=150,
    Store_Image=151,
    Store_Discard=152,
    Store_Auto_Approval=153,
    Store_Add_Slider=154,
    Store_Export_Excel=155,
    Store_Export_PDF=156,
    Store_Accept=157,

    //Manage Affiliate
     Add=158,
     Edit=159,
     Show_Status_Log=160,
     ViewAll=161,

     //Manage Push Notification
     GeneralReminder_ViewAll=162,
     GeneralReminder_Add=163,
     GeneralReminder_Edit=164,
     GeneralReminder_Delete=165,
     
     //Manage Home Page Settings
     TopRanks_VeiwAll=166,
     TopRanks_Add=167,
     TopRanks_Export_Excel=168,
     TopRanks_Export_PDF=169,
     TopRanks_Delete=170,

     TopSelection_Add=171,
     TopSelection_VeiwAll=172,
     TopSelection_Export_Excel=173,
     TopSelection_Export_PDF=174,
     TopSelection_Delete=175,

     NewArrivals_Add=176,
     NewArrivals_VeiwAll=177,
     NewArrivals_Export_Excel=178,
     NewArrivals_Export_PDF=179,
     NewArrivals_Delete=180,

     MoreToLove_Add=181,
     MoreToLove_VeiwAll=182,
     MoreToLove_Export_Excel=183,
     MoreToLove_Export_PDF=184,
     MoreToLove_Delete=185,

     FeaturedBrands_Add=186,
     FeaturedBrands_VeiwAll=187,
     FeaturedBrands_Export_Excel=188,
     FeaturedBrands_Export_PDF=189,
     FeaturedBrands_Delete=190,

     FeatureCatetories_Add=191,
     FeatureCatetories_VeiwAll=192,
     FeatureCatetories_Export_Excel=193,
     FeatureCatetories_Export_PDF=194,
     FeatureCatetories_Delete=195,

     FeatureStores_Add=196,
     FeatureStores_VeiwAll=197,
     FeatureStores_Export_Excel=198,
     FeatureStores_Export_PDF=199,
     FeatureStores_Delete=200,

     SliderRight_Add=201,
     SliderRight_VeiwAll=202,
     SliderRight_Export_Excel=203,
     SliderRight_Export_PDF=204,
     SliderRight_Delete=205,

     BelowSlider_Add=206,
     BelowSlider_VeiwAll=207,
     BelowSlider_Export_Excel=208,
     BelowSlider_Export_PDF=209,
     BelowSlider_Delete=210,

     FlashDeals_Add=211,
     FlashDeals_VeiwAll=212,
     FlashDeals_Export_Excel=213,
     FlashDeals_Export_PDF=214,
     FlashDeals_Delete=215,

     //Manage Page
    Page_ViewAll=216,
    Page_Add=217,
    Page_Edit=218,
    Page_Delete=219,
    Page_Export_Excel=220,
    Page_Export_PDF=221,

    PageCategory_ViewAll=222,
    PageCategory_Add=223,
    PageCategory_Edit=224,
    PageCategory_Delete=225,
    PageCategory_Export_Excel=226,
    PageCategory_Export_PDF=227,

    //Manage Reward Type
    RewardType_ViewAll=228,
    RewardType_Add=229,
    RewardType_Edit=230,
    RewardType_Delete=231,

    //Manage Ware House
    WareHouse_ViewAll=232,
    WareHouse_Add=233,
    WareHouse_Edit=234,
    WareHouse_Delete=235,

    //Manage Menu Category
    MenuCategory_ViewAll=236,
    MenuCategory_Add=237,
    MenuCategory_Edit=238,
    MenuCategory_Delete=239,
    MenuCategory_Export_Excel=240,
    MenuCategory_Export_PDF=241,

    //Manage Roles
    Roels_ViewAll=242,
    Roels_Add=243,
    Roels_Edit=244,
    Roels_Delete=245,

    //Permission Management
    Data_Permission_ViewAll=248,
    Data_Permission_Add=249,
    Data_Permission_Edit=254,
    Data_Permission_Delete=257,

    //Permission Management
    System_Permission_ViewAll=263,
    System_Permission_Add=266,
    System_Permission_Edit=267,
    System_Permission_Delete=271,

    //Users
    User_ViewAll=272,
    User_Add=273,
    User_Edit=274,
    User_Export_Excel=275,
    User_Export_PDF=276,
    User_SyncData=277,
    User_Reset_Password=278,
    User_Lock=279,
    User_Resend_Email_Verification=280,
    User_Enable_Auto_Approval=281
}

