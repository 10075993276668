import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { Router, ActivatedRoute } from '@angular/router';
import { District } from 'src/app/shared/models/district';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-district',
  templateUrl: './add-district.component.html',
  styleUrls: ['./add-district.component.scss'],
})
export class AddDistrictComponent implements OnInit {
  currentCountryId = 0;
  currentProvinceId = 0;
  districtListForm: FormGroup;
  provinceList = [];
  countryList = [];
  dropdownSettings = {};
  districtId: number = 0;
  btnSave = 'Save';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private districtService: DistrictService,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private alertyfy: AlertyfyService,
    private route: ActivatedRoute,
    private spinningManager: SpinnerManagerService
  ) {
    this.districtListForm = this.fb.group({
      id: '',
      provinceId: '',
      countryId: '',
      name: '',
      description: '',
      districtCode: '',
    });

    this.districtId =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
        // console.log(this.route.snapshot.params.id);

    if (this.districtId > 0) {
      this.getSetCurrentLocation();
      this.saveDistrict();

    } else {
      this.getAllCountries();
      this.districtId = 0;
    }
    this.spinningManager.showSpinner('Loading.....');
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };
  }

  getAllCountries() {
    this.countryService.getAllCountry().subscribe(
      (res) => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertyfy.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }
  getSetCurrentLocation() {
    this.districtService.getDistrictById(this.districtId).subscribe(
      (res) => {
        this.currentProvinceId = Number(res.provinceId);
        this.currentCountryId = Number(res.countryId);
        this.spinningManager.hideSpinner();
        this.districtListForm.setValue({
          id: res.id,
          provinceId: res.provinceId,
          countryId: res.countryId,
          name: res.name,
          description: res.description,
          districtCode: res.districtCode,
        });
        this.getLocation();
      },
      (error) => {
        this.spinningManager.hideSpinner();
      }
    );
  }
  getLocation() {
    this.countryService.getAllCountry().subscribe(
      (res) => {
        this.countryList = res;
        this.provinceService
          .getProvinceByCountry(this.currentCountryId)
          .subscribe(
            (res) => {
              this.provinceList = res;
            },
            (error) => {}
          );
      },
      (error) => {}
    );
  }
  onCountrySelection(event) {
    this.currentCountryId = Number(event.target.value);
    this.provinceService
      .getProvinceByCountry(Number(event.target.value))
      .subscribe(
        (res) => {
          this.provinceList = res;
        },
        (error) => {}
      );
  }
  onProvinceSelection(event) {
    this.currentProvinceId = Number(event.target.value);
  }

  saveDistrict() {
    if (this.districtId && this.districtId > 0) {
      const districtData = {
        id: this.districtId,
        name: this.districtListForm.controls.name.value,
        description: this.districtListForm.controls.description.value,
        districtCode: this.districtListForm.controls.districtCode.value,
        countryId: this.districtListForm.controls.countryId.value,
        provinceId:this.districtListForm.controls.provinceId.value
      };
      this.districtService.upsertDistrict(districtData).subscribe(
        (result) => {
          this.spinningManager.showSpinner('Saving.....');
          this.router.navigateByUrl('/managedelevery/district-list');
          this.spinningManager.hideSpinner();
        },
        (error) => {
          this.spinningManager.hideSpinner();
        }
      );
    } else {
      var district = new District();
      var districtObj = this.districtListForm.value;
      district.id = Number(this.districtId) || 0;
      district.provinceId = Number(districtObj.provinceId);
      district.countryId = Number(districtObj.countryId);
      district.name = districtObj.name;
      district.description = districtObj.description;
      district.districtCode = districtObj.districtCode;
      this.districtService.upsertDistrict(district).subscribe(
        (res) => {
          if (res) {
            this.alertyfy.success('Successfully Inserted.');
            this.districtListForm.reset();
            this.router.navigateByUrl('/managedelevery/district-list');
          }
        },
        (error) => {
          this.alertyfy.error(error);
        }
      );
    }
  }
}
