export class Member {
  id: number;
  imagepath: string;
  firstName: string;
  lastName: string;
  fullName: string;
  sex: string;
  dateOfBirth: string;
  mobile: string;
  phone: string;
  job: string;
  countryName: string;
  countryId: number;
  programsId: number;
  programsName: string;
  provinceName: string;
  provinceId: number;
  districtName: string;
  districtId: number;
  municipalitiesId: number;
  municipalitiesName: number;
  wardNo: string;
  pratinidhiSabhaName: string;
  pratinidhiSabhaId: number;
  pradeshSabhaName: string;
  pradeshSabhaId: number;
  chetraName: string;
  chetraId: number;
}
