import { Component, OnInit } from '@angular/core';
import { UserDetailsQueryObject } from 'src/app/shared/models/queryObject';
import { UserDetailsService } from 'src/app/shared/service/userDetails/userDetails.service';
import { environment } from '../../../../environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ActionhistoryService } from '../../../shared/service/actionhistory/actionhistory.service';
import { ActionHistoryModule, ActionHistoryType } from "../../../shared/enums/ActionHistoryModule";
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  public user_list = [];
  queryObject = new UserDetailsQueryObject();
  public storeList = [];
  noDataFound: string;
  public dataPermission = DataPermissions;

  columns = [
    { title: 'Id', key: 'Id', isSortable: true },
    { title: 'First Name', key: 'FirstName', isSortable: true },
    { title: 'Last Name', key: 'LastName', isSortable: true },
    { title: 'Email', key: 'Email', isSortable: true },
    { title: 'Phone Number', key: 'PhoneNumber', isSortable: true },
    // { title: 'Status', key: 'Status', isSortable: true},
    { title: 'Roles', key: 'Roles', isSortable: false },
    { title: 'Action' },
  ];
  public userlist: any;
  public roles: any;
  constructor(private alertyfy: AlertyfyService,
    private userDetails: UserDetailsService,
    private actionhistoryService: ActionhistoryService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }

  public settings = {
    columns: {
      avatar: {
        title: 'Avatar',
        type: 'html'
      },
      fName: {
        title: 'First Name',
      },
      lName: {
        title: 'Last Name'
      },
      email: {
        title: 'Email'
      },
      last_login: {
        title: 'Last Login'
      },
      role: {
        title: 'Role'
      },
    },
  };

  ngOnInit() {
    this.reset();
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = environment.pageSize;
    this.getUserDetails();
    this.getRoles();
  }

  selectChangeHandler(event: any) {
    this.queryObject.roleId = event.target.value;
  }

  selectedStoreChange(event: any) {
    this.queryObject.storeId = event.target.value;
  }

  filterTextChange(event: any) {
    this.queryObject.filtertext = event.target.value;
  }
  searchUserDetails() {
    this.getUserDetails();
  }

  sortBy(columnName: string) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.getUserDetails();
  }

  onPageChange(page: number) {
    this.queryObject.page = page;
    this.getUserDetails();
  }

  showFilters: Boolean = false;
  showHideFilter() {
    this.showFilters = !this.showFilters;
  }


  getRoles() {
    this.userDetails.getRoles().subscribe(x => {
      this.roles = x;
      this.spinningManager.hideSpinner();
    }, error => {
      this.spinningManager.hideSpinner();
    });
  }

  getUserDetails() {
    this.userDetails.getUserList(this.queryObject).subscribe((user: any) => {
      this.userlist = user?.items;
      this.queryObject.pageCount = user.totalItems;
      this.spinningManager.hideSpinner();
    }, error => {
      this.noDataFound = error.error;
      this.spinningManager.hideSpinner();
    });
  }
  public exportToExcel(flag: boolean) {
    this.queryObject.printall = flag;
    this.userDetails.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'UserDetail.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.userDetails.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'UserDetail.pdf');
    });
  }

  public enableAutoApproval(id) {
    this.alertyfy.confirm('Are you sure to enable auto approval?', () => {
      this.getUserDetails();
    });
  }
  public disableAutoApproval(id) {
    this.alertyfy.confirm('Are you sure to disable auto approval?', () => {
      this.getUserDetails();
    });
  }

  sendResetPasswordEmail(id) {
    this.alertyfy.confirm('Are you sure to send password reset email?', () => {
      this.userDetails.sendPasswordResetLink(id).subscribe(res => {
        this.actionhistoryService.create('Password reset link sent.', ActionHistoryType.UPDATE, ActionHistoryModule.USER, id).subscribe(result => {
        });
        this.alertyfy.success('Password reset link set successfully.');
      })
    });
  }

  sendEmailConfirmationEmail(id) {
    this.alertyfy.confirm('Are you sure to send confirmation email?', () => {
      this.userDetails.sendConfirmationLink(id).subscribe(res => {
        this.actionhistoryService.create('Confirmation link sent.', ActionHistoryType.UPDATE, ActionHistoryModule.USER, id).subscribe(result => {
        });
        this.alertyfy.success('Password reset link set successfully.');
      })
    });
  }

  lockUnlockUser(user) {
    let message: string = "Are you sure to " + (user.isUserLocked ? "unlock" : "lock") + " this account?";
    this.alertyfy.confirm(message, () => {
      this.userDetails.lockUnlockAccount(user.id).subscribe(res => {
        if (res) {
          this.actionhistoryService.create('Account ' + (user.isUserLocked ? 'unlocked' : 'locked'), ActionHistoryType.UPDATE, ActionHistoryModule.USER, user.id).subscribe(result => {
          });
          this.alertyfy.success('Account ' + (user.isUserLocked ? 'unlocked' : 'locked') + ' successfully.');
          this.searchUserDetails();
        } else {
          this.alertyfy.error('There was some problem. Please try again later.');
        }
      })
    });
  }

  selectedUserStatus(event: any) {
    this.queryObject.userStatusId = event.target.value;
  }

  syncIdentityUsers() {
    this.alertyfy.confirm('Are you sure you want to sync data?', () => {
      this.userDetails.syncIdentityUsers().subscribe(res => {
        this.alertyfy.success('Total ' + Number(res) + ' data synced successfully.');
      })
    });
  }
  public reset() {
    this.queryObject.filtertext = '';
    this.queryObject.roleId = 0;
    this.queryObject.storeId = 0
    this.queryObject.userStatusId = 0;
  }

}
