import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { QueryObject } from '../../models/queryObject';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private apiService: ApiService) { }
  getAllMember(){
    return this.apiService.get('Doctor/GetAll');
  }
  getRegistedBranch(){
    return this.apiService.get('RegistedBranch/GetAll');
  }
  getMemberById(id: any){
    return this.apiService.get('Doctor/getbyid?id='+id);
  }
  createMember(data:any){
    return this.apiService.post('Doctor/Create',data)
  }
  deleteMember(id:any){
    return this.apiService.post('Doctor/delete?id='+id);
  }
  upsertMember(data:any){
    return this.apiService.post('Doctor/update',data);
  }
  getMember(query: QueryObject){
    return this.apiService.post('Doctor/DoctorItem', query);
}
getMemberItems(query: QueryObject){
  return this.apiService.post('Doctor/GetQueryDoctor', query)
}

  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('Doctor/ExportToPdf',query);
  }

  public exportByIdPDF(data:any){
    return this.apiService.downloadFile('Doctor/ExportToPdf/'+data);
  }


  public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('Doctor/ExportToExcel',query);
  }

  public printBackPage(){
    return this.apiService.downloadFile('Doctor/ExportBackPdf','');
  }
}
