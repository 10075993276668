<div class="container-fluid">
    <div class="row">
      <div class="col-lg-12"></div>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row width-half">
              <div class="col-md-6 col-sm-7">
                <h5>
                 Work History
                </h5>
              </div>
              <div class="col-md-6 col-sm-7 text-right" >
                <button type="button" class="btn btn-success ml-2"  (click)="createDataPermisionPopUp(0, this.doctorid)">
                  Add New <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body margin-sm no-margs">
            <div class="table-responsive">
              <table class="table mt-3 text-nowrap table-bordered">
                <thead>
                  <tr>
                    <th *ngFor="let c of columns">
                      <div *ngIf="c.isSortable" (click)="sortBy(c.key)">
                        {{ c.title }}
                        <i *ngIf="queryObject.sortBy === c.key" class="fa" [class.fa-sort-asc]="queryObject.isSortAsc"
                          [class.fa-sort-desc]="!queryObject.isSortAsc"></i>
                      </div>
                      <div *ngIf="!c.isSortable">
                        {{ c.title }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="List?.length; else no_data_temp">
                  <tr *ngFor="let item of List">
                    <td class="edit-tableicon">
                      <a (click)="createDataPermisionPopUp(item.id, this.doctorid)">
                        <i class="fa fa-edit" ></i>
                      </a>
  
                      <i (click)="onDeleteConfirm(item.id)" class="fa fa-trash cursor-pointer">
                      </i>
                   
                    </td>
                   <td>
                      {{ item.hospitalName }}
                    </td>
                   <td>
                      {{ item.hospitalAddress }}
                    </td>
                   <td>
                      {{ item.hospitalContactNo }}
                    </td>
                   <td>
                      {{ item.workingHours }}
                    </td>
                   <td>
                      {{ item.startDate }}
                    </td>
                   <td>
                      {{ item.endDate }}
                    </td>
                   <td>
                      {{ item.salaryPerMonth }}
                    </td>
                   <td>
                      {{ item.designation }}
                    </td>
                   <td>
                      {{ item.doctorId }}
                    </td>
                   <td>
                      {{ item.isPartTime }}
                    </td>
                   <td>
                      {{ item.isPrivate }}
                    </td>
           
                  </tr>
                </tbody>
                <ng-template #no_data_temp>
                  <tbody>
                    <td colspan="14">
                      <h4 class="text-center d-block w-100 mt-3">
                        {{ this.noDataFound }}
                      </h4>
                    </td>
                  </tbody>
                </ng-template>
              </table>
  
              <ngb-pagination [(page)]="queryObject.page"  [maxSize]="queryObject.pageSize" [pageSize]="queryObject.pageSize" [collectionSize]="queryObject.pageCount" 
                  (pageChange)="onPageChange($event)" [rotate]="true" [boundaryLinks]="true"
                   aria-label="Default pagination">
              </ngb-pagination>
            </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>
  