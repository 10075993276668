import { Component, OnInit } from '@angular/core';
import { SystemPermissionQueryObject, QueryObject } from 'src/app/shared/models/queryObject';
import { SystemPermission } from 'src/app/shared/models/systemPermission';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { SystempermissionService } from 'src/app/shared/service/systemPermission/systempermission.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
@Component({
  selector: 'app-list-system-permission',
  templateUrl: './list-system-permission.component.html',
  styleUrls: ['./list-system-permission.component.scss']
})
export class ListSystemPermissionComponent implements OnInit {
  public systemPermissionList: SystemPermission[];
  public dataPermission = DataPermissions;
  columns = [
    { title: "Id", key: "Id", isSortable: true },
    { title: "Name", key: "Name", isSortable: true },
    { title: "Description", key: "Description", isSortable: true },
    { title: "Header Code", key: "Header Code", isSortable: true },
    { title: "Code", key: "Code", isSortable: true },
    { title: "Is Group Master", key: "IsGroupMaster", isSortable: true },
    { title: "Action" },
  ];
  queryObject = new SystemPermissionQueryObject();
  noDataFound: string;
  constructor(private systemPermsiionService: SystempermissionService,
    private alertyfy: AlertyfyService, public mps: ManagePermissionService,
    private spinningManager: SpinnerManagerService
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.noDataFound = environment.noDataFound;
    this.getAllItem();
  }
  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.getAllItem();

  }
  onPageChange(page) {
    this.queryObject.page = page;
    this.getAllItem();
  }
  public getAllItem() {
    this.systemPermsiionService.getAllItem(this.queryObject).subscribe((res: any) => {
      this.queryObject.pageCount = res.totalItems;
      this.systemPermissionList = res.items;
      this.spinningManager.hideSpinner();
    }, error => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });

  }
  public onDeleteConfirm(id: number) {
    if (confirm("Do you really want to delete?")) {
      this.systemPermsiionService.deletSystemPermission(id).subscribe((result) => {
        this.getAllItem();
        this.alertyfy.success("Deleted successfully");
      });
    }
  }
}
