import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {
  constructor(private apiService:ApiService) {

  }
  getAllDistrict(){
    return this.apiService.get('District/GetAll');
  }
  getAllIncludingCountry(){
    return this.apiService.get('District/GetAllIncludingCountry');
  }
  getDistrictById(id: any){
    return this.apiService.get('District/GetById/'+id);
  }
  upsertDistrict(data:any){
    return this.apiService.post('District/UpsertDistrict',data);
  }
  deleteDistrict(id){
    return this.apiService.post('District/DeleteDistrict/'+id);
  }
getDistrictItem(query: QueryObject){
    return this.apiService.post('District/DistrictItem', query);
}

getDistrictByProvince(provinceId){
  return this.apiService.get('District/GetDistrictByProvinceId/'+provinceId);
}
 public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('District/ExportToExcel',query)
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('District/ExportToPdf',query);
  }

}

