import { Component, Input, OnInit } from '@angular/core';
import { UserRegistrationfileService } from '../../../service/userRegistrationFile/user-registrationfile.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserDetailsService } from '../../../service/userDetails/userDetails.service';
import { environment } from '../../../../../environments/environment';
import { TreeModule } from '@circlon/angular-tree-component';



@Component({
  selector: 'app-user-document',
  templateUrl: './user-document.component.html',
  styleUrls: ['./user-document.component.scss']
})
export class UserDocumentComponent implements OnInit {
  public closeResult = '';
  public userRegistrationFiles: UserRegistrationFiles[] = [];
  public comment;
  public userdetails: any = [{}];
  public userRegistrationToUpdate: any;
  baseUrlForImage = environment.imageUrl;
  public show: boolean = false;
  public hide: boolean = true;
  public imageUrl: any;
  public popupHeader: any;
  @Input() userID: any;
  constructor(private userRegistrationFileServie: UserRegistrationfileService, private modalService: NgbModal, private userdetailsService: UserDetailsService) { }

  ngOnInit(): void {
    this.GetDocumentByUserId();

  }
  public GetDocumentByUserId() {
    this.userRegistrationFileServie.getDocumentByUserId(this.userID).subscribe((res: UserRegistrationFiles[]) => {
      this.userRegistrationFiles = res;
    })
  }
  // showLagreImage(item){
  //   item.show=!item.show;
  // }

  open(content, item, isVerfied) {
    this.userRegistrationToUpdate = {
      comment: item.comment != null ? item.comment : '',
      id: item.id,
      isVerfied: isVerfied,
      userId: item.userId
    };

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.updateUserDocument(this.userRegistrationToUpdate);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openImage(contentImage, item) {
    this.imageUrl = this.baseUrlForImage + "/" + item.fileDetails;
    this.popupHeader = item.masterRegistrationFile.type + "[" + item.masterRegistrationFile.name + "]";

    this.modalService.open(contentImage, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  public updateUserDocument(obj: any) {
    this.userRegistrationFileServie.updateUserDocument(obj).subscribe(res => {
      this.GetDocumentByUserId();
    }, error => {
    })
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
export interface UserRegistrationFiles {
  id: number;
  masterRegistraionFileId: number;
  userId: number;
  fileDetails: string;
  isVerfied: boolean;
  verfiedby: number;
  userDetails: object | null;
  verifiedOn: string | null;
  comment: string | null;
  createdBy: number;
  createdOn: string;
  updatedBy: number | null;
  updatedOn: string | null;
}
