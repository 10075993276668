import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { systemPermissions } from '../enums/systemPermissions';
import { ManagePermissionService } from './ManagePermissions/manage-permission.service';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	haspermission?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window,private managepermissionsService:ManagePermissionService) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Dashboard]), title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
    {
			path: '/managedelevery/member-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Dashboard]), title: 'Member', icon: 'user-plus', type: 'link', badgeType: 'primary', active: false
		},
		{
			path: '/managedelevery/doctor-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Dashboard]), title: 'Doctor', icon: 'user-plus', type: 'link', badgeType: 'primary', active: false
		},
		
		// {
		// 	title: 'Service Category',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Category]), icon: 'camera', type: 'sub', active: false,children:[
		// 		{path:'/category/list-category', haspermission:this.managepermissionsService.managePermissions([systemPermissions.Category_List]),title:'Manage Category', type:'link'},
		// 		{path:'/category/create-category',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Create_Category]), title:'Add Category', type:'link'},
		// 		// {path:'/category/marketplace-fee',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Marketplace_Fee]), title: 'Manage Marketplace Fee', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Services',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Product]), icon: 'box', type: 'sub', active: false,children:[
		// 		{path:'/product/list-product',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Product_List]), title:'Manage Services', type:'link'},
		// 		{path:'/product/create-product',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Product_Create]), title:'Add Service', type:'link'},
		// 		{path:'/product/rating-review',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Product_RatingReview]), title:'Rating & Reviews', type:'link'},
		// 	]
    	// },
		// {
		// 	title: 'Inventory', haspermission:this.managepermissionsService.managePermissions([systemPermissions.Inventory]), icon: 'box', type: 'sub', active: false,children:[
		// 		{path:'/inventory/inventory-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Inventory_List]) ,title:'Manage Inventory', type:'link'},
		// 		{path:'/inventory/add-inventory',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Inventory_Add]), title:'Add Inventory', type:'link'},
		// 		{path:'/inventory/inventory-products',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Inventory_Products]), title:'Inventory Products', type: 'link'},
		// 	]
		// },
		// {
		// 	title: 'Orders', icon: 'dollar-sign',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Sales]), type: 'sub', active: false, children: [
		// 		{ path: '/sales/orders',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Orders]), title: 'Manage Orders', type: 'link' },
		// 		{ path: '/sales/runningorders',haspermission:this.managepermissionsService.managePermissions([systemPermissions.RunningOrders]), title: 'Manage Running Orders', type: 'link' },
		// 		{ path: '/sales/transactions',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Transactions]), title: 'Manage Transactions', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Offers',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Offers]), icon: 'gift', type: 'sub', active: false,children:[
		// 		{path:'/offers/offer-detail',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Offers_Detail]), title:'Manage Offers', type:'link'},
		// 		{path:'/offers/add-offer-detail',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Create_Offer]), title:'Add Offer', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Newsletter', haspermission:this.managepermissionsService.managePermissions([systemPermissions.Newsletter]), icon: 'box', type: 'sub', active: false,children:[
		// 		{path:'/newsletter', title:'Manage Newsletter', haspermission:this.managepermissionsService.managePermissions([systemPermissions.Newsletter_List]), type:'link'},
		// 		{path:'/newsletter/add-newsletter', haspermission:this.managepermissionsService.managePermissions([systemPermissions.Newsletter_Add]), title:'Add Newsletter', type:'link'},
		// 		{path:'/newsletter/subscribers', haspermission:this.managepermissionsService.managePermissions([systemPermissions.Newsletter_Subscribers]), title:'Subscribers', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Slider',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Slider]), icon: 'image', type: 'sub', active: false,children:[
		// 		{path:'/carousel/carousel-list-homepage',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Slider_Carousel_List_Homepage]), title:'Manage Home Page', type:'link'},
        // 		{path:'/carousel/carousel-list-affiliate',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Slider_Carousel_List_Affiliate]), title:'Manage Affiliate', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Email Management',haspermission:this.managepermissionsService.managePermissions([systemPermissions.EmailManagement]), icon: 'settings', type: 'sub', active: false,children:[
		// 	  {path:'/EmailManagement/list-email-template',haspermission:this.managepermissionsService.managePermissions([systemPermissions.EmailTemplate]), title:'Manage Email Template', type:'link'},
		// 	  {path:'/EmailManagement/email-queue',haspermission:this.managepermissionsService.managePermissions([systemPermissions.EmailQueue]), title:'Manage Email Queue', type:'link'},
		// 	  {path:'/EmailManagement/email-log', title:'Manage Email Log',haspermission:this.managepermissionsService.managePermissions([systemPermissions.EmailLog]), type:'link'},


		//   ]
		// },
		// {
		// 	title: 'Brand',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Brand]), icon: 'tag', type: 'sub', active: false,children:[
		// 		{path:'/brands/brand-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.BrandList]), title:'Manage Brands', type:'link'},
		// 		{path:'/brands/add-brand',haspermission:this.managepermissionsService.managePermissions([systemPermissions.BrandCreate]), title:'Add Brand', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Manage Delivery',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ManageShipping]), icon: 'archive', type: 'sub', children: [
		// 		{ path: '/manageshipping/deliverycompany-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.DeliveryCompanies]), title: 'Manage Delivery Companies', type: 'link' },
		// 		{ path: '/manageshipping/shipping-cost-variation',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ShippingCostVariation]), title: 'Manage Shipping Cost Variation', type: 'link' },

		// 	]
		// },
		{
			title: 'Manage',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ManageDelivery]), icon: 'archive', type: 'sub', children: [
				{ path: '/managedelevery/country-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Countries]), title: 'Manage Countries', type: 'link',},
				{ path: '/managedelevery/province-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Provinces]),title: 'Manage Provinces', type: 'link' },
				{ path: '/managedelevery/district-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Districts]), title: 'Manage Districts', type: 'link' },
				{ path: '/managedelevery/pratinidhi-sabha-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Districts]), title: 'Manage Pratinidhi Sabha', type: 'link' },
				{ path: '/managedelevery/pradesh-sabha-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Districts]), title: 'Manage Pradesh Sabha', type: 'link' },
				// { path: '/managedelevery/chetra-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Districts]), title: 'Manage Chetra', type: 'link' },
				{ path: '/managedelevery/municipality-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Muncipalites]),title: 'Manage Municipalities', type: 'link' },
				{ path: '/managedelevery/program-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Muncipalites]),title: 'Manage Programs', type: 'link' },
				{ path: '/managedelevery/registed-branch-list', haspermission: this.managepermissionsService.managePermissions([systemPermissions.Muncipalites]),title: 'Registed Branch', type: 'link' },
				// { path: '/managedelevery/city-list',haspermission: this.managepermissionsService.managePermissions([systemPermissions.Cities]), title: 'Manage Cities', type: 'link' }
				// { path: '/managedelevery/region-list',haspermission: this.managepermissionsService.managePermissions([systemPermissions.Regions]), title: 'Manage Regions', type: 'link' }
			]
		},
		// {
		// 	title: 'Store',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Store]), icon: 'shopping-bag', type: 'sub', active: false, children: [
		// 		{ path: '/store/list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.StoreList]), title: 'Manage Store', type: 'link' },
		// 		{ path: '/store/account',haspermission:this.managepermissionsService.managePermissions([systemPermissions.StoreCreate]), title: 'Add Store', type: 'link' },

		// 	]
		// },
		// {
		// 	title: 'Affiliate',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ManageAffiliate]), icon: 'archive', type: 'sub', children: [
		// 		{ path: '/affiliate/list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ManageAffiliate]), title: 'Manage Affiliate', type: 'link' },
		// 		{ path: '/affiliate/addAffiliate',haspermission:this.managepermissionsService.managePermissions([systemPermissions.AddAffiliate]), title: 'Add Affiliate', type: 'link' },
		// 	]
		// },
		{
			title: 'Users', icon: 'user-plus',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Users]), type: 'sub', active: false, children: [
				{ path: '/users/list-user',haspermission:this.managepermissionsService.managePermissions([systemPermissions.User_List]), title: 'User List', type: 'link' },
				{ path: '/users/create-user',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Create_User]), title: 'Create User', type: 'link' },
			]
		},
		// {
		// 	title: 'Push Notification',haspermission:this.managepermissionsService.managePermissions([systemPermissions.PushNotification]), icon: 'archive', type: 'sub', active: false, children: [
        // 	{ path: '/push-notification/general-reminder',haspermission:this.managepermissionsService.managePermissions([systemPermissions.GeneralReminder]), title: 'Manage General Reminder', type: 'link' },
        // 	{ path: '/push-notification/create-general-reminder',haspermission:this.managepermissionsService.managePermissions([systemPermissions.CreateGeneralReminder]), title: 'Add General Reminder', type: 'link' }
		// ]
		// },
		// {
		// 	title: 'Permission Management',haspermission:this.managepermissionsService.managePermissions([systemPermissions.PermissionManagement]), icon: 'archive', type: 'sub', active: false, children: [
        //  	{ path: '/PermissionManagement/list-data-permission',haspermission:this.managepermissionsService.managePermissions([systemPermissions.DataPermission]), title: 'Data Permission', type: 'link' },
        //  	{ path: '/PermissionManagement/list-system-permission',haspermission:this.managepermissionsService.managePermissions([systemPermissions.SystemPermission]), title: 'System Permission', type: 'link' }
		// ]
		// },
		// {
		// 	title: 'Home Page Settings',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ClientHome]), icon: 'home', type: 'sub', active: false,children:[
		// 		{path:'/client-homepage/top-ranking-list', haspermission:this.managepermissionsService.managePermissions([systemPermissions.TopRankingList]),title:'Top Ranks', type:'link'},
		// 		{path:'/client-homepage/top-selection-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.TopSelectionList]), title:'Top Selections', type:'link'},
		// 		{path:'/client-homepage/flash-deal-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.FlashDealList]), title:'Flash Deals', type:'link'},
		// 		{path:'/client-homepage/new-arrival-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.NewArrivalList]), title:'New Arrivals', type:'link'},
		// 		{path:'/client-homepage/more-to-love',haspermission:this.managepermissionsService.managePermissions([systemPermissions.MoreToLove]), title:'More To Love', type:'link'},
		// 		{path:'/client-homepage/feature-brand-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.FeatureBrandList]), title:'Feature Brands', type:'link'},
		// 		{path:'/client-homepage/feature-category-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.FeatureCategoryList]), title:'Feature Categories', type:'link'},
		// 		{path:'/client-homepage/feature-store-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.FeatureStoreList]), title:'Feature Stores', type:'link'},
		// 		{path:'/client-homepage/slider-right-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.SliderRightList]), title:'Slider Right', type:'link'},
		// 		{path:'/client-homepage/slider-bottom-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.SliderBottomList]), title:'Below Slider', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Master List',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ClientHome]), icon: 'home', type: 'sub', active: false,children:[
		// 		{path:'/category/master-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Master_List]), title:'Manage Master List', type:'link'},
		// 		{path:'/category/add-master-list',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Create_Master_List]), title:'Add Master List', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Master List Property',haspermission:this.managepermissionsService.managePermissions([systemPermissions.ClientHome]), icon: 'home', type: 'sub', active: false,children:[
		// 		{path:'/category/master-property',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Master_Property]), title:'Manage Master Property', type:'link'},
		// 		{path:'/category/add-master-property',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Create_Master_Property]), title:'Add Master Property', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Page',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Page]), icon: 'box', type: 'sub', active: false,children:[
		// 		{path:'/Administration/list-llpage',haspermission:this.managepermissionsService.managePermissions([systemPermissions.LLPage]), title:'Manage Page', type:'link'},
		// 		{path:'/Administration/create-llpage',haspermission:this.managepermissionsService.managePermissions([systemPermissions.LLPage]), title:'Add Page', type:'link'},
		// 		{path:'/Administration/page-category',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Page_Category]), title:'Manage Page Category', type:'link'},
		// 		{path:'/Administration/create-pagecategory',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Page_Category]), title:'Add Page Category', type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Reward Type',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Administration]), icon: 'box', type: 'sub', active: false,children:[
		// 		{path:'/Administration/list-rewardtype', title:'Manage Reward Type',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Reward_Type]), type:'link'},
		// 		{path:'/Administration/create-rewardtype', title:'Add Reward Type',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Reward_Type]), type:'link'},
		// 	]
		// },
		// {
		// 	title: 'Ware House',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Administration]), icon: 'box', type: 'sub', active: false,children:[
		// 		{path:'/Administration/list-warehouse', title:'Manage Ware House',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Ware_House]), type:'link'},
		// 		{path:'/Administration/create-warehouse', title:'Add Ware House',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Ware_House]), type:'link'},
		// 	]
		// },
    // 	{
	// 	title: 'Administration',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Administration]), icon: 'box', type: 'sub', active: false,children:[
	// 		{path:'/menucategory/list-menucategory',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Menu_Category]), title:'Manage Menu Category', type:'link'},
	// 		{path:'/menucategory/create-menucategory',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Menu_Category]), title:'Add Menu Category', type:'link'},
	// 		{path:'/Administration/websettings',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Settings]),title:'Settings',type:'link'},
	// 		{path:'/Administration/list-role',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Roles]), title:'Manage Roles', type:'link'},
	// 		{path:'/Administration/create-role',haspermission:this.managepermissionsService.managePermissions([systemPermissions.Roles]), title:'Add Role', type:'link'},
    //   	]
    // 	},
	// 	{
	// 		title: 'Products',haspermission:false, icon: 'box', type: 'sub', active: false, children: [
	// 			{
	// 				title: 'Physical', type: 'sub', children: [
	// 					{ path: '/products/physical/category', title: 'Category', type: 'link' },
	// 					{ path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
	// 					{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
	// 					{ path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
	// 					{ path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				title: 'digital', type: 'sub', children: [
	// 					{ path: '/products/digital/digital-category', title: 'Category', type: 'link' },
	// 					{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
	// 					{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
	// 					{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		title: 'Coupons',haspermission:false, icon: 'tag', type: 'sub', active: false, children: [
	// 			{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
	// 			{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
	// 		]
	// 	},



	// {
	// 		title: 'Pages',haspermission:false, icon: 'clipboard', type: 'sub', active: false, children: [
	// 			{ path: '/pages/list-page', title: 'List Page', type: 'link' },
	// 			{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		title: 'Media',haspermission:false, path: '/media', icon: 'camera', type: 'link', active: false
	// 	},
	// 	{
	// 		title: 'Menus',haspermission:false, icon: 'align-left', type: 'sub', active: false, children: [
	// 			{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
	// 			{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		title: 'Vendors',haspermission:false, icon: 'users', type: 'sub', active: false, children: [
	// 			{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
	// 			{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
	// 		]
    // },
	// 	{
	// 		title: 'Localization',haspermission:false, icon: 'chrome', type: 'sub', children: [
	// 			{ path: '/localization/translations', title: 'Translations', type: 'link' },
	// 			{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
	// 			{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
	// 		]
	// 	},
		// {
		// 	title: 'Reports',haspermission:false, path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		// {
		// 	title: 'Settings',haspermission:false, icon: 'settings', type: 'sub', children: [
		// 		//{ path: '/settings/profile', title: 'Profile', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Invoice',haspermission:false, path: '/invoice', icon: 'archive', type: 'link', active: false
		// },
		// {
		// 	title: 'Login',haspermission:false,path: '/auth/login', icon: 'log-in', type: 'link', active: false
		// }
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
