import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DoctorWorkHistoryService } from 'src/app/shared/service/ManageDelivery/doctor-work-history.service';
import { DoctorWorkHistory } from 'src/app/shared/models/doctorworkhistory';

@Component({
  selector: 'app-upsert-doctor-work-history-component',
  templateUrl: './upsert-doctor-work-history-component.component.html',
  styleUrls: ['./upsert-doctor-work-history-component.component.scss']
})
export class UpsertDoctorWorkHistoryComponentComponent implements OnInit {
  @Input() doctorId: number = 0;
  @Input() doctorworkhistoryId: number = 0;

  upsertform: FormGroup;
  countryList = [];
  dropdownSettings = {};
  Id: number = 0;
  btnSave = 'Save';
  formStatus :string = "Create";
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _service: DoctorWorkHistoryService,
    private spinningManager: SpinnerManagerService,
    private alertify: AlertyfyService,
    public activeModal: NgbActiveModal

  ) {
    this.upsertform = this.fb.group({
      id: '',
      hospitalName: '',
      hospitalAddress: '',
      hospitalContactNo: '',
      workingHours: '',
      startDate: '',
      endDate: '',
      salaryPerMonth: '0.00',
      designation: '',
      doctorId: this.doctorId,
      isPartTime: [false],
      isPrivate: [false],
    });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    if(this.doctorworkhistoryId > 0){
      this.Id = this.doctorworkhistoryId;
    }
    if (this.Id > 0) {
      this.edit();
    }
    this.spinningManager.hideSpinner();
  }
  edit() {
    this._service.getById(this.Id).subscribe((result) => {
      this.spinningManager.showSpinner("Loading.....");
      this.upsertform = this.fb.group({
        hospitalName: result["hospitalName"],
        hospitalAddress: result["hospitalAddress"],
        hospitalContactNo: result["hospitalContactNo"],
        workingHours: result["workingHours"],
        startDate: result["startDate"],
        endDate: result["endDate"],
        salaryPerMonth: result["salaryPerMonth"],
        designation: result["designation"],
        isPartTime: result["isPartTime"],
        isPrivate: result["isPrivate"],
      });
      this.btnSave = 'Update';
      this.formStatus = "Edit";
    }, (error) => {
      this.alertify.error(error.error);
      this.router.navigateByUrl('/managedelevery/country-list');
    })
    this.spinningManager.hideSpinner();
  }

  save() {
    this.spinningManager.showSpinner('Loading.....');
    if (this.Id && this.Id > 0) {
      const upsertform = {
        id: this.Id,
        hospitalName: this.upsertform.controls.hospitalName.value,
        isPrivate: this.upsertform.controls.isPrivate.value,
        hospitalAddress: this.upsertform.controls.hospitalAddress.value,
        hospitalContactNo: this.upsertform.controls.hospitalContactNo.value,
        isPartTime: this.upsertform.controls.isPartTime.value,
        workingHours: this.upsertform.controls.workingHours.value,
        startDate: this.upsertform.controls.startDate.value,
        endDate: this.upsertform.controls.endDate.value,
        salaryPerMonth: this.upsertform.controls.salaryPerMonth.value,
        designation: this.upsertform.controls.designation.value,
        doctorId: this.doctorId,
      };
      this._service.update(upsertform).subscribe(result => {
        this.spinningManager.showSpinner("Saving.....");
      this.alertify.success("Updated Sucessfully");

        this.activeModal.close({ isSavePressed: true });
        this.spinningManager.hideSpinner();
      },
       error => {
        this.spinningManager.hideSpinner();
      })
    } else {
      var _form = new DoctorWorkHistory();
      var form = this.upsertform.value;
      _form.id = Number(form.id) || 0;
      _form.hospitalName = form.hospitalName;
      _form.isPrivate = form.isPrivate;
      _form.hospitalAddress = form.hospitalAddress;
      _form.hospitalContactNo = form.hospitalContactNo;
      _form.isPartTime = form.isPartTime;
      _form.workingHours = form.workingHours;
      _form.startDate = form.startDate;
      _form.endDate = form.endDate;
      _form.salaryPerMonth = form.salaryPerMonth;
      _form.designation = form.designation;
      _form.doctorId = this.doctorId;
      this._service.create(_form)
        .subscribe(res => {
          if (res) {
            this.upsertform.reset();
            this.alertify.success("Saved Sucessfully");

            this.activeModal.close({ isSavePressed: true });
            this.spinningManager.hideSpinner();
          }
        }, error => {
          this.alertify.error(error);
          this.spinningManager.hideSpinner();
        }
        );
    }
    error => {
      this.spinningManager.hideSpinner();
      this.activeModal.close({ isSavePressed: true });


    }

  }

}
