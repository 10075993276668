import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { City } from 'src/app/shared/models/city';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { CityService } from 'src/app/shared/service/ManageDelivery/citylist.service';
import { environment } from 'src/environments/environment';
import { CityListSearchQueryObject, SearchQueryObject } from '../../../shared/models/queryObject';
import { saveAs as importedSaveAs } from 'file-saver';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
import { CountryList } from '../province-list/province-list.component';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { ProvinceDropDownList } from '../district-list/district-list.component';
import { ProvinceService } from '../../../shared/service/ManageDelivery/province.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { DistrictDropDown } from '../municipality-list/municipality-list.component';
import { municipalityListDropDown } from '../city-create/city-create.component';
import { MunicipalityService } from 'src/app/shared/service/ManageDelivery/municipality.service';



@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss']
})
export class CityListComponent implements OnInit {
  pageSize = environment.pageSize;
  public City = [];
  public cityList: City[];
  public district = [];
  public citytItem: any = [];
  noDataFound: string;
  public districtList: DistrictDropDown[] = [];
  public muncipalityList: municipalityListDropDown[] = [];
  countryList: CountryList[] = [];
  public provinceList: ProvinceDropDownList[] = [];
  showFilters: Boolean = false;
  queryObject = new CityListSearchQueryObject();
  public dataPermission = DataPermissions;
  columns = [
    { title: 'ID', key: 'id', isSortable: true },
    { title: 'City Name', key: 'name', isSortable: true },
    { title: 'Muncipality Name', key: 'muncipalityName', isSortable: true },
    { title: 'District Name', key: 'districtName', isSortable: true },
    { title: 'Province Name', key: 'provinceName', isSortable: true },
    { title: 'Country Name', key: 'countryName', isSortable: true },
    { title: 'Action' }
  ];

  constructor(
    private cityService: CityService,
    private _router: Router,
    private alertyfy: AlertyfyService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private muncipalityService: MunicipalityService
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }


  ngOnInit() {
    this.noDataFound = environment.noDataFound;
    this.getAllCountries();
    this.Reset();
  }
  getCityList() {
    this.cityService.getCityList(this.queryObject).subscribe((res: any) => {
      this.queryObject.pageCount = res.totalItems;
      this.cityList = res.items;
      this.spinningManager.hideSpinner();
    }, error => {
      this.spinningManager.hideSpinner();
      this.noDataFound = error;
    });
  }

  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.districtId = 0;
    this.queryObject.municipalitiesId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.countryList = null;
    this.muncipalityList = null;
    this.provinceList = null;
    this.districtList = null;
    this.getCityList();
  }

  public Search() {
    this.getCityList();
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;
  }

  public sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.getCityList();
  }

  public onPageChange(page) {
    this.queryObject.page = page;
    this.getCityList();
  }

  public changePageSize(event) {
    this.queryObject.pageSize = Number(event.target.value);
    this.getCityList();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.cityService.deleteCity(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getCityList();
          },
            (error) => {
              this.alertyfy.error("Something went wrong. Delete Failed.");
            });
      });
  }
  public exportToExcel(flag: boolean) {

    this.queryObject.printall = flag;
    this.cityService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'CityList.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.cityService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'CityList.pdf');
    });
  }
  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {

    })
  }
  getAllDistrictsByProvinceId(provinceId) {
    this.districtService.getDistrictByProvince(provinceId).subscribe(res => {
      this.districtList = res;
      this.spinningManager.hideSpinner();
    },
      error => {
        this.alertyfy.error(error);
        this.spinningManager.hideSpinner();
      });
  }
  getAllMuncipalitiesByDistirctId(districtId) {
    this.muncipalityService.getMunicipalitiesByDistrict(districtId).subscribe(res => {
      this.muncipalityList = res;
    }, error => {

    })
  }

}





