import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserDetailsService } from '../../../shared/service/userDetails/userDetails.service';
import { Router } from '@angular/router';
import { AlertyfyService } from '../../../shared/service/alertyfy.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  ChangePasswordForm: FormGroup;
  submitted = false; loading = false; error = false; errormessage = '';
  constructor(private alertify: AlertyfyService, private formBuilder: FormBuilder,
    private router: Router,
    private userDetailService: UserDetailsService) { }

  ngOnInit() {
    this.ChangePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    },
    );

  }

  get cp() { return this.ChangePasswordForm.controls; }

  changePassword() {
    if (this.ChangePasswordForm.valid) {
      this.error = false; this.errormessage = '';
      this.submitted = true;
      this.loading = true;
      if (!this.ChangePasswordForm.valid) return;

      var password = this.cp.password.value;
      var confirmPassword = this.cp.confirmPassword.value;
      this.userDetailService.changePassword({ password, confirmPassword }).subscribe(x => {
        this.loading = false;
        this.alertify.success("Password changed successfully.");
        this.router.navigate(['/users/change-password']);
      }, err => {
        this.loading = false;
        this.error = true;
        this.errormessage = err.error;
      });
    } else {
      this.ChangePasswordForm.markAllAsTouched();
    }
  }

}
