<form [formGroup]="regionListForm">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Add Region</h5>
            </div>
            <div class="row card-body tab2-card">
                <div class="col-12">
                    <div class="form-group row">
                        <label for="countryId" class="col-xl-3 col-md-3"><span>*</span> Country</label>
                        <div class="col-xl-9 col-md-9">
                            <select placeholder="Select a Country" class="custom-select form-control"
                            formControlName="countryId" required="required">
                            <option *ngFor="let obj of countryList; let i = index;" [value]='obj.id' type="number">
                                {{ obj.name }}
                            </option>
                        </select>
                        </div>   
                       
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span>Name</label>
                        <div class="col-xl-9 col-md-9">
                            <input class="form-control " formControlName="name" id="name" type="text"
                            required="">    
                        </div>
                        
                    </div>
                    <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-4"> Description</label>
                        <div class="col-12 mt-2" >
                           <ck-editor formControlName="description" id="description" language="en" [fullPage]="false"></ck-editor>
                        </div>
                     </div>
                    <!-- <div class="form-group row">
                        <label for="name" class="col-xl-3 col-md-3">Description</label>
                        <div class="col-xl-9 col-md-9">
                            <input class="form-control " formControlName="description" id="description"
                            type="text" required="">
                        </div>
                        
                    </div> -->
                    <div class="pull-right">
                        
                        <button type="button" class="btn btn-primary" [routerLink]="['/managedelevery/region-list']">
                            Discard
                        </button>
                        <button type="button" class="btn btn-success ml-2" (click)="saveRegionList()">
                            {{btnSave}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
