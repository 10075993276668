<div class="container-fluid">
    <div class="row">
      
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Data Permission   <button *ngIf="this.mps.manageDataPermission([dataPermission.Data_Permission_Add])" type="button" class="btn btn-success ml-2" [routerLink]="['/PermissionManagement/create-data-permission']">
                        Add New Data Permission <i class="fa fa-plus" aria-hidden="true"></i>

                    </button></h5>
             
                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th *ngFor='let c of columns'>
                                    <div *ngIf="c.isSortable" (click)="sortBy(c.key)">
                                        {{c.title}}
                                        <i *ngIf="queryObject.sortBy===c.key" class="fa" [class.fa-sort-asc]="queryObject.isSortAsc" [class.fa-sort-desc]="!queryObject.isSortAsc"></i>
                                    </div>
                                    <div *ngIf="!c.isSortable">
                                        {{c.title}}
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody *ngIf="dataPermissionList?.length;else no_data_temp">
                            <tr *ngFor="let item of dataPermissionList">
                                <td>
                                    {{item.id}}
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    <div [innerHTML]="item.description"></div>

                                </td>
                                <td>
                                    {{item.headerCode}}
                                </td>
                                <td>
                                    {{item.code}}
                                </td>

                                <td>
                                    <a *ngIf="this.mps.manageDataPermission([dataPermission.Data_Permission_Edit])" routerLink="/PermissionManagement/create-data-permission/{{item.id}}" style="margin-right: 8px"><i class="fa fa-edit"
                                  style="font-size: 25px;color: rgb(10, 2, 116); cursor: pointer;"></i></a>

                                    <i *ngIf="this.mps.manageDataPermission([dataPermission.Data_Permission_Delete])" (click)="onDeleteConfirm(item.id)" class="fa fa-trash" style="font-size: 25px; color: red;cursor: pointer;"></i>
                                </td>

                            </tr>
                        </tbody>
                        <ng-template #no_data_temp>
                          <h4 class="text-center d-block w-100 mt-3">{{this.noDataFound}}</h4>
                        </ng-template>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>