import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  constructor(private apiService:ApiService) {

  }
  getAllRegion(){
    return this.apiService.get('Region/GetAll');
  }
  getAllIncludingCountry(){
    return this.apiService.get('Region/GetAllIncludingCountry');
  }
  getRegionById(id: any){
    return this.apiService.get('Region/GetById/'+id);
  }
  upsertRegion(data:any){
    return this.apiService.post('Region/UpsertRegion',data);
  }
  deleteRegion(id:any){
    return this.apiService.post('Region/DeleteRegion/'+id);
  }
   getRegion(query: QueryObject){
    return this.apiService.post('Region/RegionItem', query);
}
public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('Region/ExportToExcel',query)
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('Region/ExportToPdf',query);
  }
}

