import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramQueryObject } from 'src/app/shared/models/queryObject';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ProgramService } from 'src/app/shared/service/ManageDelivery/program.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {

  showFilters: Boolean = false;
  noDataFound: string;
  queryObject = new ProgramQueryObject();
  pageSize = environment.pageSize;

  columns = [
    { title: 'Program', key: 'Name', isSortable: true },
    { title: 'Description', key: 'Description', isSortable: true },
    { title: 'Code', key: 'Code', isSortable: true },
    { title: 'Action' }

  ];
  programList: [];

  constructor(private alertyfy: AlertyfyService, private _router: Router, private programService: ProgramService) { }

  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.getProgram();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.programService.deleteProgram(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getProgram();
          },
            (error) => {
              this.alertyfy.error(error);
            })

      });
  }

  getProgram() {
    this.programService.getAllPrograms().subscribe(res => {
      this.programList = res;
    });
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;
  }

  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    // this.loadCountry();
  }

  onPageChange(page) {
    this.queryObject.page = page;
    // this.loadCountry();
  }

  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/add-program');
  }
  public exportToExcel(flag: boolean) {

    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.xlsx');
    // });
  }
  public exportToPdf(flag: boolean) {
    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.pdf');
    // });
  }
  public Search() {
    this.getProgram();
  }

  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.pageSize = this.pageSize;
    this.getProgram();
  }
}
