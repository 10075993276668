<form [formGroup]="Form">
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h5>Create Registed Branch</h5>
        </div>
        <div class="row card-body tab2-card">
          <div class="col-12">
            <div class="form-group row mr-0">
              <label for="name" class="col-xl-3 col-md-3"
                > Name</label
              >
              <input
                class="form-control col-xl-9 col-md-9"
                formControlName="name"
                id="name"
                type="text"
                required=""
              />
            </div>
  
            <div class="form-group row editor-label mr-0">
              <label class="col-xl-3 col-md-4">Description</label>
              <div class="col-xl-9 col-md-8 p-0 m-0">
                <textarea
                  rows="4"
                  class="w-100 form-control"
                  formControlName="description"
                  required
                ></textarea>
              </div>
            </div>
           
  
            <div class="row">
              <div class="col-12">
                <div class="pull-right">
                  <button
                    [routerLink]="['/managedelevery/registed-branch-list']"
                    type="button"
                    class="btn btn-primary"
                  >
                    Discard
                  </button>
                  <button
                    type="button"
                    id="btnSave"
                    class="btn btn-success ml-2"
                    (click)="saveProgramList()"
                  >
                    {{ btnSave }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Container-fluid Ends-->
  