<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div *ngIf="userbasicinfo" class="profile-details text-center">
            <div class="container">
              <!-- overlay -->

              <input
                class="custom-file-input"
                type="file"
                (change)="editProfilePicture($event)"
              />
              <img
                [src]="userbasicinfo.profilePictureUrl"
                alt="{{ userbasicinfo.firstName }} {{ userbasicinfo.lastName }}"
                class="image"
              />
              <div class="btn btn-secondary w-100 mb-3">
                Change Profile <i class="fa fa-edit"></i>
              </div>
            </div>
            <h5 class="f-w-600 mb-0">
              {{ userbasicinfo.firstName }} {{ userbasicinfo.lastName }}
            </h5>
            <span>{{ userbasicinfo.email }}</span>
            <div class="social">
              <div class="form-group btn-showcase">
                <button class="btn social-btn btn-fb d-inline-block">
                  <i class="fa fa-facebook"></i>
                </button>
                <button class="btn social-btn btn-twitter d-inline-block">
                  <i class="fa fa-google"></i>
                </button>
                <button class="btn social-btn btn-google d-inline-block mr-0">
                  <i class="fa fa-twitter"></i>
                </button>
              </div>
            </div>
          </div>
          <hr />
          <!-- <div class="project-status">
            <h5 class="f-w-600">Employee Status</h5>
            <div class="media">
              <div class="media-body">
                <h6>Performance<span class="pull-right">80%</span></h6>
                <div class="progress sm-progress-bar">
                  <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    style="width: 90%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6>Overtime <span class="pull-right">60%</span></h6>
                <div class="progress sm-progress-bar">
                  <div
                    class="progress-bar bg-secondary"
                    role="progressbar"
                    style="width: 60%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6>Leaves taken<span class="pull-right">70%</span></h6>
                <div class="progress sm-progress-bar">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 70%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-xl-8">
      <div class="card tab2-card">
        <div class="card-body">
          <ngb-tabset class="tab-coupon profile-tabs">
            <ngb-tab>
              <ng-template ngbTabTitle>
                <app-feather-icons [icon]="'user'"></app-feather-icons
                >Profile</ng-template
              >
              <ng-template ngbTabContent>
                <div
                  *ngIf="userdetails"
                  class="tab-pane fade show active"
                  id="top-profile"
                  role="tabpanel"
                  aria-labelledby="top-profile-tab"
                >
                  <h5 class="f-w-600">Profile</h5>
                  <div class="grid-table">
                    <div class="tbody">
                      <form class="tr" [formGroup]="profileForm">
                        <div class="tr">
                          <div class="td">First Name:</div>
                          <div class="td">
                            <ng-template #doNotEditfirstName>
                              <span>{{ userdetails.firstName }}</span>
                            </ng-template>
                            <input
                              *ngIf="editFields; else doNotEditfirstName"
                              class="form-control"
                              formControlName="firstName"
                            />
                            <span
                              *ngIf="
                                editFields &&
                                !profileForm.controls.firstName.valid &&
                                profileForm.controls.firstName.touched
                              "
                              class="error-block"
                              >First Name is required.</span
                            >
                          </div>
                        </div>
                        <div class="tr">
                          <div class="td">Last Name:</div>
                          <div class="td">
                            <ng-template #doNotEditlastName>
                              <span>{{ userdetails.lastName }}</span>
                            </ng-template>
                            <input
                              *ngIf="editFields; else doNotEditlastName"
                              class="form-control"
                              formControlName="lastName"
                            />
                            <span
                              *ngIf="
                                editFields &&
                                !profileForm.controls.lastName.valid &&
                                profileForm.controls.lastName.touched
                              "
                              class="error-block"
                              >Last Name is required.</span
                            >
                          </div>
                        </div>
                        <div class="tr">
                          <div class="td">Email:</div>
                          <div class="td">
                            <ng-template #doNotEditemail>
                              <span>{{ userdetails.email }}</span>
                            </ng-template>
                            <input
                              *ngIf="editFields; else doNotEditemail"
                              class="form-control"
                              formControlName="email"
                            />
                            <span
                              *ngIf="
                                editFields &&
                                !profileForm.controls.email.valid &&
                                profileForm.controls.email.touched
                              "
                              class="error-block"
                              >Email is required.</span
                            >
                          </div>
                        </div>
                        <div class="tr">
                          <div class="td">Gender:</div>
                          <div class="td">
                            <ng-template #doNotEditgender_>
                              <span>{{ userdetails.gender_ }}</span>
                            </ng-template>
                            <select
                              *ngIf="editFields; else doNotEditgender_"
                              class="form-control"
                              formControlName="gender"
                              id="gender"
                            >
                              <option
                                *ngFor="let obj of genders; let i = index"
                                [value]="obj.value"
                              >
                                {{ obj.key }}
                              </option>
                            </select>
                            <span
                              *ngIf="
                                editFields &&
                                !profileForm.controls.gender.valid &&
                                profileForm.controls.gender.touched
                              "
                              class="error-block"
                              >Gender is required.</span
                            >
                          </div>
                        </div>
                        <div class="tr">
                          <div class="td">Contact Number:</div>
                          <div class="td">
                            <ng-template #doNotEditphoneNumber>
                              <span>{{ userdetails.phoneNumber }}</span>
                            </ng-template>
                            <input
                              *ngIf="editFields; else doNotEditphoneNumber"
                              class="form-control"
                              formControlName="phoneNumber"
                            />
                          </div>
                        </div>
                        <div class="tr">
                          <div class="td">DOB:</div>
                          <div class="td">
                            <ng-template #doNotEditdateofBirth>
                              <span>{{ dateofBirth }}</span>
                            </ng-template>
                            <div
                              *ngIf="editFields; else doNotEditdateofBirth"
                              class="input-group"
                            >
                              <input
                                class="form-control"
                                placeholder="yyyy-mm-dd"
                                name="dateofBirth_"
                                [(ngModel)]="dateofBirth_"
                                ngbDatepicker
                                #d="ngbDatepicker"
                                formControlName="dateofBirth"
                                id="dateofBirth"
                              />
                              <div class="input-group-append">
                                <button
                                  class="btn btn-outline-secondary calendar"
                                  (click)="d.toggle()"
                                  type="button"
                                >
                                  <span class="fa fa-calendar"></span>
                                </button>
                              </div>
                            </div>
                            <span
                              *ngIf="
                                editFields &&
                                !profileForm.controls.dateofBirth.valid &&
                                profileForm.controls.dateofBirth.touched
                              "
                              class="error-block"
                              >Date of Birth is required.</span
                            >
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      *ngIf="editFields; else doNotEdit"
                      type="button"
                      class="btn btn-success m-b-10"
                      data-original-title="edit"
                      (click)="saveProfile()"
                    >
                      Save
                    </button>
                    <ng-template #doNotEdit>
                      <button
                        type="button"
                        class="btn btn-secondary m-b-10"
                        data-original-title="edit"
                        (click)="editProfile()"
                      >
                        Edit
                      </button>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
            <!-- <ngb-tab>
              <ng-template ngbTabTitle>
                <i data-feather="briefcase"></i> Location
              </ng-template>
              <ng-template ngbTabContent>
                <div
                  class="tab-pane fade show active"
                  id="top-profile"
                  role="tabpanel"
                  aria-labelledby="top-profile-tab"
                >
                  <h5 class="f-w-600">Location</h5>

                  <div class="table-responsive profile-table">
                    <div class="grid-table">
                      <div class="tbody">
                        <form class="tr" [formGroup]="addressForm">
                          <div class="tr">
                            <div class="td">Country:</div>
                            <div class="td">
                              <ng-template #doNotEditcountry>
                                <span>{{ primaryUserAddress.country }}</span>
                              </ng-template>
                              <select
                                *ngIf="editAddressFields; else doNotEditcountry"
                                (change)="onCountrySelection($event)"
                                class="form-control"
                                formControlName="countryId"
                                id="countryId"
                              >
                                <option [value]="">--Select--</option>
                                <option
                                  *ngFor="let obj of countries; let i = index"
                                  [value]="obj.id"
                                >
                                  {{ obj.name }}
                                </option>
                              </select>
                              <span
                                *ngIf="
                                  editAddressFields &&
                                  !addressForm.controls.countryId.valid &&
                                  addressForm.controls.countryId.touched
                                "
                                class="error-block"
                                >Country is required.</span
                              >
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">Province:</div>
                            <div class="td">
                              <ng-template #doNotEditprovince>
                                <span>{{ primaryUserAddress.province }}</span>
                              </ng-template>
                              <select
                                *ngIf="
                                  editAddressFields;
                                  else doNotEditprovince
                                "
                                (change)="onProvinceSelection($event)"
                                class="form-control"
                                formControlName="provinceId"
                                id="provinceId"
                              >
                                <option [value]="">--Select--</option>
                                <option
                                  *ngFor="let obj of provinces; let i = index"
                                  [value]="obj.id"
                                >
                                  {{ obj.name }}
                                </option>
                              </select>
                              <span
                                *ngIf="
                                  editAddressFields &&
                                  !addressForm.controls.provinceId.valid &&
                                  addressForm.controls.provinceId.touched
                                "
                                class="error-block"
                                >Province is required.</span
                              >
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">District:</div>
                            <div class="td">
                              <ng-template #doNotEditdistrict>
                                <span>{{ primaryUserAddress.district }}</span>
                              </ng-template>
                              <select
                                *ngIf="
                                  editAddressFields;
                                  else doNotEditdistrict
                                "
                                (change)="onDistrictSelection($event)"
                                class="form-control"
                                formControlName="districtId"
                                id="districtId"
                              >
                                <option [value]="">--Select--</option>
                                <option
                                  *ngFor="let obj of districts; let i = index"
                                  [value]="obj.id"
                                >
                                  {{ obj.name }}
                                </option>
                              </select>
                              <span
                                *ngIf="
                                  editAddressFields &&
                                  !addressForm.controls.districtId.valid &&
                                  addressForm.controls.districtId.touched
                                "
                                class="error-block"
                                >District is required.</span
                              >
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">City:</div>
                            <div class="td">
                              <ng-template #doNotEditcity>
                                <span>{{ primaryUserAddress.city }}</span>
                              </ng-template>
                              <select
                                *ngIf="editAddressFields; else doNotEditcity"
                                (change)="onCitySelection($event)"
                                class="form-control"
                                formControlName="cityId"
                                id="cityId"
                              >
                                <option [value]="">--Select--</option>
                                <option
                                  *ngFor="let obj of cities; let i = index"
                                  [value]="obj.id"
                                >
                                  {{ obj.name }}
                                </option>
                              </select>
                              <span
                                *ngIf="
                                  editAddressFields &&
                                  !addressForm.controls.cityId.valid &&
                                  addressForm.controls.cityId.touched
                                "
                                class="error-block"
                                >City is required.</span
                              >
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">Address:</div>
                            <div class="td">
                              <ng-template #doNotEditaddress>
                                <span>{{ primaryUserAddress.address }}</span>
                              </ng-template>
                              <input
                                *ngIf="editAddressFields; else doNotEditaddress"
                                class="form-control"
                                formControlName="address"
                              />
                              <span
                                *ngIf="
                                  editAddressFields &&
                                  !addressForm.controls.address.valid &&
                                  addressForm.controls.address.touched
                                "
                                class="error-block"
                                >Address is required.</span
                              >
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">Tole:</div>
                            <div class="td">
                              <ng-template #doNotEdittole>
                                <span>{{ primaryUserAddress.tole }}</span>
                              </ng-template>
                              <input
                                *ngIf="editAddressFields; else doNotEdittole"
                                class="form-control"
                                formControlName="tole"
                              />
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">Ghar Number:</div>
                            <div class="td">
                              <ng-template #doNotEditgharNumber>
                                <span>{{ primaryUserAddress.gharNumber }}</span>
                              </ng-template>
                              <input
                                *ngIf="
                                  editAddressFields;
                                  else doNotEditgharNumber
                                "
                                class="form-control"
                                formControlName="gharNumber"
                              />
                            </div>
                          </div>
                          <div class="tr">
                            <div class="td">Contact Number:</div>
                            <div class="td">
                              <ng-template #doNotEditphoneNumber>
                                <span>{{
                                  primaryUserAddress.phoneNumber
                                }}</span>
                              </ng-template>
                              <input
                                *ngIf="
                                  editAddressFields;
                                  else doNotEditphoneNumber
                                "
                                class="form-control"
                                formControlName="phoneNumber"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="pull-right">
                      <button
                        *ngIf="editAddressFields; else doNoteditAddressFields"
                        type="button"
                        class="btn btn-primary m-b-10"
                        data-original-title="edit"
                        (click)="saveAddress()"
                      >
                        Save
                      </button>
                      <button
                        *ngIf="editAddressFields"
                        type="button"
                        class="btn btn-primary m-b-10"
                        style="margin-left: 1em"
                        data-original-title="edit"
                        (click)="cancelAddressEdit()"
                      >
                        Cancel
                      </button>
                      <ng-template #doNoteditAddressFields>
                        <button
                          type="button"
                          class="btn btn-primary m-b-10"
                          data-original-title="edit"
                          (click)="editAddress()"
                        >
                          Edit
                        </button>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab> -->
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
