import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ApiService } from 'src/app/shared/service/api.service';
import { environment } from 'src/environments/environment';
import { MemberService } from 'src/app/shared/service/ManageDelivery/member.service';
import { Member } from 'src/app/shared/models/member';

@Component({
  selector: 'app-member-pic-upload',
  templateUrl: './member-pic-upload.component.html',
  styleUrls: ['./member-pic-upload.component.scss'],
})
export class MemberPicUploadComponent implements OnInit {
  public progress: number;
  public message: string;
  memberList: [];
  memberImageUrl = environment.imageUrl;

  @Output() public onUploadFinished = new EventEmitter();

  @Input() public imageUrl: any = "../../assets/images/yourImage.png"
  @Input() public doctorForm: boolean = false;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private memberService: MemberService
  ) {}
  ngOnInit() {
  }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);


    this.http
      .post(!this.doctorForm? `${environment.apiUrl}Doctor/upload` : `${environment.apiUrl}Member/upload`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round((100 * event.loaded) / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  };


  onPhotoChange(event){
    if(event.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event : any) => {
        this.imageUrl = event.target.result;
      }
    }
  }
}
