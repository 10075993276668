import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { Country } from 'src/app/shared/models/country';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';


@Component({
  selector: 'app-country-create',
  templateUrl: './create-country.component.html',
  styleUrls: ['./create-country.component.scss']
})
export class CreateCountryComponent implements OnInit {
  countryListForm: FormGroup;
  countryList = [];
  dropdownSettings = {};
  countryid: number = 0;
  btnSave = 'Save';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private countryService: CountryService,
    private spinningManager: SpinnerManagerService,
    private alertify: AlertyfyService
  ) {
    this.countryListForm = this.fb.group({
      id: '',
      name: '',
      description: '',
      countryCode: ''
    });

    this.countryid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    if (this.countryid > 0) {
      this.editCountry();
    }
    this.spinningManager.hideSpinner();
  }
  editCountry() {
    this.countryService.getCountryById(this.countryid).subscribe((result) => {
      this.spinningManager.showSpinner("Loading.....");
      this.countryListForm = this.fb.group({
        name: result["name"],
        description: result["description"],
        countryCode: result["countryCode"],
      });
      this.btnSave = 'Update';
    }, (error) => {
      this.alertify.error(error.error);
      this.router.navigateByUrl('/managedelevery/country-list');
    })
    this.spinningManager.hideSpinner();
  }

  saveCountryList() {
    if (this.countryid && this.countryid > 0) {
      const countrydataupdate = {
        id: this.countryid, name: this.countryListForm.controls.name.value,
        description: this.countryListForm.controls.description.value,
        countryCode: this.countryListForm.controls.countryCode.value
      };
      this.countryService.upsertCountry(countrydataupdate).subscribe(result => {
        this.spinningManager.showSpinner("Saving.....");
        this.router.navigateByUrl('/managedelevery/country-list');
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      })
    } else {
      var _country = new Country();
      var country = this.countryListForm.value;
      _country.id = Number(country.id) || 0;
      _country.name = country.name;
      _country.description = country.description;
      _country.countryCode = country.countryCode;

      this.countryService.createCountry(_country)
        .subscribe(res => {
          if (res) {
            this.countryListForm.reset();
            this.router.navigateByUrl('/managedelevery/country-list');
            this.spinningManager.hideSpinner();
          }
        }, error => {
          this.alertify.error(error);
          this.spinningManager.hideSpinner();
        }
        );
    }
    error => {
      this.spinningManager.hideSpinner();
      this.router.navigateByUrl('/managedelevery/country-list');
    }

  }
  // edit(id)
  // {
  //   this.countryService.getCountryById(id).subscribe(id=>
  //     {
  //       this.countryListForm.patchValue(id)
  //     });

  // } 
}

