<form [formGroup]="memberListForm">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h5>Create Member</h5>
      </div>
      <div class="row card-body tab2-card">
        <div class="col-12">
          <div class="form-group row mr-0">
            <label for="programId" class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              > Program</label
            >
            <select
              placeholder="Select Program"
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="programsId"
              id="programId"
            >
              <option value="">--Select Program--</option>
              <option
                *ngFor="let program of programList; let i = index"
                [value]="program.id"
              >
                {{ program.name }}
              </option>
            </select>
          </div>
          <div class="form-group row mr-0">
            <label for="firstName" class="col-xl-3 col-md-3"
              ><span>*</span> First Name</label
            >
           <div class="col-xl-9 col-md-9 pl-0 pr-0">
            <input
            class="form-control "
            formControlName="firstName"
            id="firstName"
            type="text"
            required
          />
          <span class="text-danger ml-1"
            *ngIf="(f.firstName.touched) && f.firstName.errors?.required">
            *Required
          </span>
           </div>
          </div>
          <div class="form-group row mr-0">
            <label for="lastName" class="col-xl-3 col-md-3"
              ><span>*</span> Last Name</label
            >
            <div class="col-xl-9 col-md-9 pl-0 pr-0">
              <input
              class="form-control "
              formControlName="lastName"
              id="lastName"
              type="text"
              required=""
            />
              <span class="text-danger ml-1"
                *ngIf="(f.lastName.touched) && f.lastName.errors?.required">
                *Required
              </span>
            </div>
          </div>
          <div class="form-group row mr-0">
            <label for="uploadPhoto" class="col-xl-3 col-md-3"
              > Upload Photo</label
            >
            <app-member-pic-upload [imageUrl]="imageUrl"
              class="col-xl-9 col-md-9 mr--10"
              (onUploadFinished)="uploadFinished($event)"
            ></app-member-pic-upload>
          </div>
          <div class="form-group row mr-0">
            <label for="sex" class="col-xl-3 col-md-3">Sex</label>
            <div class="row ml-3">
              <div class="form-check form-check-inline mr-3">
                <input
                  class="form-check-input"
                  type="radio"
                  id="male"
                  formControlName="sex"
                  value="male"
                />

                <label class="form-check-label" for="male">Male</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="sex"
                  value="female"
                  id="female"
                />
                <label class="form-check-label" for="female">Female</label>
              </div>
            </div>
          </div>
          <div class="form-group row mr-0">
            <label for="dateOfBirth" class="col-xl-3 col-md-3"
              >Date of Birth</label
            >
            <input
              class="form-control col-xl-9 col-md-9"
              formControlName="dateOfBirth"
              id="dateOfBirth"
              type="date"
            />
          </div>
          <div class="form-group row mr-0">
            <label for="mobile" class="col-xl-3 col-md-3"
              >Mobile</label
            >
            <input
              class="form-control col-xl-9 col-md-9"
              formControlName="mobile"
              id="mobile"
              maxlength="10"
              type="text"
            />
            <span class="error" *ngIf="formSubmitted && memberListForm.get('mobile').hasError('maxLength')">
              *Invalid Mobile No.
           </span>
          </div>
          <div class="form-group row mr-0">
            <label for="phone" class="col-xl-3 col-md-3"
              >Phone
            </label>
            <input
              class="form-control col-xl-9 col-md-9"
              formControlName="phone"
              id="phone"
              maxlength="9"
              type="text"
            />
            <span class="error" *ngIf="formSubmitted && memberListForm.get('phone').hasError('maxLength')">
              *Invalid Phone No.
           </span>
          </div>
          <div class="form-group row mr-0">
            <label for="job" class="col-xl-3 col-md-3"
              >Job
            </label>
            <input
              class="form-control col-xl-9 col-md-9"
              formControlName="job"
              id="job"
              type="text"
            />
          </div>
          <div class="form-group row mr-0">
            <label for="countryId" class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              > Country</label
            >
            <select
            (change)="onCountrySelection($event)"
              placeholder="Select a Country"
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="countryId"
              id="countryId"
            >
              <option value="" disabled>--Select Country--</option>
              <option
                *ngFor="let country of countryList; let i = index"
                [value]="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="form-group row mr-0">
            <label for="provinceId" class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              >Province</label
            >
            <select
             (change)="onProvinceSelection($event)"
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="provinceId"
            >
              <option value="" disabled>--Select Province--</option>
              <option
                *ngFor="let province of provinceList; let i = index"
                [value]="province.id"
              >
                {{ province.name }}
              </option>
            </select>
          </div>
          <div class="form-group row mr-0">
            <label for="districtId" class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              > District</label
            >
            <select
            (change)="onDistrictSelection($event)"
              placeholder="Select a District"
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="districtId"
            >
              <option value="" disabled>--Select District--</option>
              <option
                *ngFor="let district of districtList; let i = index"
                [value]="district.id"
              >
                {{ district.name }}
              </option>
            </select>
          </div>
          <div class="form-group row mr-0">
            <label
              for="municipalitiesId"
              class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              >Municipality</label
            >
            <select
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="municipalitiesId"
            >
              <option value="" disabled>--Select Municipality--</option>
              <option
                *ngFor="let municipality of municipalityList; let i = index"
                [value]="municipality.id"
              >
                {{ municipality.name }}
              </option>
            </select>
          </div>
          <div class="form-group row mr-0">
            <label
              for="wardNo"
              class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              >Ward</label
            >
            <input
              class="form-control col-xl-9 col-md-9"
              formControlName="wardNo"
              id="wardNo"
              type="text"
            />
          </div>
          <div class="form-group row mr-0">
            <label
              for="pratinidhiSabhaId"
              class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              >Pratinidhi Sabha</label
            >
            <select
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="pratinidhiSabhaId"
            >
              <option value="" disabled>--Select Pratinidhi Sabha--</option>
              <option
                *ngFor="let pratinidhi of pratinidhiList; let i = index"
                [value]="pratinidhi.id"
              >
                {{ pratinidhi.name }}
              </option>
            </select>
          </div>
          <div class="form-group row mr-0">
            <label
              for="pradeshSabhaId"
              class="col-xl-3 col-lg-3 col-md-3 col-md-3"
              >Pradesh Sabha</label
            >
            <select
              placeholder="Select a Pradesh Sabha"
              class="custom-select form-control col-xl-9 col-md-8"
              formControlName="pradeshSabhaId"
            >
              <option value="" disabled>--Select Pradesh Sabha--</option>
              <option
                *ngFor="let pradesh of pradeshList; let i = index"
                [value]="pradesh.id"
              >
                {{ pradesh.name }}
              </option>
            </select>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="pull-right">
                <button
                  [routerLink]="['/managedelevery/member-list']"
                  type="button"
                  class="btn btn-primary"
                >
                  Discard
                </button>
                <button
                  type="button"
                  id="btnSave"
                  class="btn btn-success ml-2"
                  (click)="saveMemberList()"
                >
                  {{ btnSave }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- Container-fluid Ends-->
