<img src="../assets/images/main-logo.png" class="d-block mx-auto"  style="margin-top: 250px; width: 200px;"/>


<style>
    
@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
}

img {
    animation: zoominoutsinglefeatured 1s infinite ;
}
</style>