<form [formGroup]="pradeshSabhaForm">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h5>Add Pradesh Sabha</h5>
      </div>
      <div class="row card-body tab2-card">
        <div class="col-12">
          <div class="form-group row">
            <label for="name" class="col-xl-3 col-lg-3 col-md-3"
              ><span>*</span>Pradesh Sabha</label
            >
            <div class="col-xl-9 col-md-8">
              <input
                class="form-control"
                formControlName="name"
                name="name"
                type="text"
                required=""
              />
            </div>
          </div>
          <div class="form-group row editor-label">
            <label class="col-xl-3 col-lg-3 col-md-3">Description</label>
            <div class="col-xl-9 col-md-8">
              <textarea
                rows="4"
                class="w-100 form-control"
                formControlName="description"
                required
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="pradeshSabhaCode" class="col-xl-3 col-lg-3 col-md-3"
              >Pradesh Sabha Code</label
            >
            <div class="col-xl-9 col-md-8">
              <input
                class="form-control"
                formControlName="pradeshSabhaCode"
                id="pradeshSabhaCode"
                type="text"
                required=""
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="districtId" class="col-xl-3 col-lg-3 col-md-3"
              >District</label
            >
            <div class="col-xl-9 col-md-8">
              <select
                class="form-control"
                formControlName="districtId"
                id="districtId"
              >
              <option value="" disabled>--Select District--</option>
              <!-- <option
                [value]=""
                type="number"
              >
                {{ obj.name }}
              </option> -->
              <option
              *ngFor="let obj of districtList; let i = index"
              [value]="obj.id"
            >
              {{ obj.name }}
            </option>
              </select>
            </div>
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-primary"
              [routerLink]="['/managedelevery/pradesh-sabha-list']"
            >
              Discard
            </button>
            <button
              type="button"
              class="btn btn-success ml-2"
              (click)="savePradeshSabha()"
            >
              {{ btnSave }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>





