export enum ActionHistoryModule
{
    CATEGORY='CATEGORY',
    USER='USER',
}

export enum ActionHistoryType{
    CREATE = 10,
    UPDATE = 20,
    DELETE = 30
}