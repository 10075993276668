<form [formGroup]="countryListForm">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Create Country</h5>
            </div>
            <div class="row card-body tab2-card" >
                <div class="col-12">
                    <div class="form-group row  mr-0 ">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span> Name</label>
                        <input class="form-control col-xl-9 col-md-9" formControlName="name"
                        id="name" type="text" required="">
                    </div>
                    <!-- <div class="form-group row mr-0">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span>Description</label>
                        <input class="form-control col-xl-9 col-md-9" formControlName="description"
                        id="description" type="text" required="">
                    </div> -->
                    <!-- <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-4"> Description</label>
                        <div class="col-12 mt-2" >
                           <ck-editor formControlName="description" id="description" language="en" [fullPage]="false"></ck-editor>
                        </div>
                     </div> -->
                    <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-4" >Description</label>
                       <div class="col-xl-8 col-md-7 p-0 m-0">
                          <textarea rows="4" class="w-100 form-control" formControlName="description" required></textarea>
                       </div>
                    </div>
                    <div class="form-group row  mr-0">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span>Country Code </label>
                        <input class="form-control col-xl-9 col-md-9" formControlName="countryCode"
                        id="countryCode" type="text" required="">
                    </div>
                   
                        <div class="row">
                            <div class="col-12">
                                <div class="pull-right">
                                    <button [routerLink]="['/managedelevery/country-list']" type="button" class="btn btn-primary">
                                        Discard
                                    </button>
                                    <button type="button" id="btnSave" class="btn btn-success ml-2" (click)="saveCountryList()">
                                        {{btnSave}}
                                    </button>
                                </div>
                            </div>
                        </div>
                       
                   
                </div>
            </div>
                               
         </div>
    </div>
</form> 
<!-- Container-fluid Ends-->