import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { Municipality} from 'src/app/shared/models/municipality';
import { MunicipalityService } from 'src/app/shared/service/ManageDelivery/municipality.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { query } from '@angular/animations';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';


@Component({
  selector: 'app-add-municipality',
  templateUrl:'./add-municipality.component.html',
  styleUrls: ['./add-municipality.component.scss']
})
export class AddMunicipalityComponent implements OnInit {
  municipalityListForm:FormGroup;
  municipalityList=[];
  districtList=[];
  countryList=[];
  provinceList=[];
  currentCountryId=0;
  currentProvinceId=0;
  currentDistrictId=0;
  dropdownSettings = {};
  municipalityid:number=0;
  btnSave='Save';

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private municipalityService:MunicipalityService,
    private districtService:DistrictService,
    private countryService: CountryService,
    private provinceService: ProvinceService,
    private alertyfy:AlertyfyService,
    private route: ActivatedRoute,
    private spinningManager: SpinnerManagerService
  ) {

    this.municipalityListForm = this.fb.group({
      provinceId: '',
      districtId:'',
      countryId:'',
      name: '',
      description:''
      });
      this.municipalityid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    if(this.municipalityid>0){
      this.getSetCurrentLocation();
    }else{
      this.getAllCountries();
    }
  this.spinningManager.showSpinner("Loading.....");
  }

  getAllCountries(){
    this.countryService.getAllCountry()
    .subscribe(res=>{
      this.countryList=res;
      this.spinningManager.hideSpinner();
    },
    error=>{
      this.alertyfy.error(error);
      this.spinningManager.hideSpinner();
    });
  }

  getSetCurrentLocation(){
    this.municipalityService.getMunicipalityById(this.municipalityid).subscribe(res=>{
      this.currentDistrictId=res.districtId;
      this.currentProvinceId=res.provinceId;
      this.currentCountryId=res.countryId;
      this.spinningManager.hideSpinner();
      this.municipalityListForm.setValue({
        provinceId: res.provinceId,
        districtId:res.districtId,
        countryId:res.countryId,
        name: res.name,
        description:res.description
      });
      this.getLocation();
    },error=>{
      this.spinningManager.hideSpinner();
    });
  }

  saveMunicipalityList(){
    this.spinningManager.showSpinner("Saving.....");
    this.municipalityid=Number(this.municipalityid);
    if(this.municipalityid && this.municipalityid > 0){
      const _municipality=
        {
          id: this.municipalityid, name: this.municipalityListForm.controls.name.value,
          description: this.municipalityListForm.controls.description.value,
          districtId: this.municipalityListForm.controls.districtId.value,
          countryId: this.municipalityListForm.controls.countryId.value,
          provinceId: this.municipalityListForm.controls.provinceId.value
        };
       this.municipalityService.upsertMunicipalities(_municipality).subscribe(result =>{
        this.router.navigateByUrl('/managedelevery/municipality-list');
         this.spinningManager.hideSpinner();
      },error=>{
         this.spinningManager.hideSpinner();
      });
    }else{
    var _municipality = new Municipality();
    var municipalityObj=this.municipalityListForm.value;
    _municipality.id=Number(municipalityObj.id)||0;
    _municipality.name=municipalityObj.name;
    _municipality.description=municipalityObj.description;
    _municipality.districtId=Number(municipalityObj.districtId);
    _municipality.provinceId=Number(municipalityObj.provinceId);
    _municipality.countryId=Number(municipalityObj.countryId);
      this.municipalityService.upsertMunicipalities(_municipality)
      .subscribe(res=>{
        if(res){
          this.alertyfy.success("Successfully Inserted.");
          this.municipalityListForm.reset();
          this.router.navigateByUrl('/managedelevery/municipality-list');
        }
      },
      error=>{
        this.alertyfy.saveError("Municipality");
      });
    }
  }
  getLocation(){
    this.countryService.getAllCountry()
    .subscribe(res=>{
      this.countryList=res;
      this.provinceService.getProvinceByCountry(this.currentCountryId)
      .subscribe(res=>{
        this.provinceList=res;
        this.districtService.getDistrictByProvince(this.currentProvinceId)
        .subscribe(res=>{
          this.districtList=res;
        },
        error=>{
        });
      },
      error=>{
      });
    },
    error=>{
    });
  }

  onCountrySelection(event){
    this.currentCountryId=Number(event.target.value);
    this.provinceService.getProvinceByCountry(Number(event.target.value))
    .subscribe(res=>{
      this.provinceList=res;
      this.districtList=[];
    },
    error=>{
    });
  }
  onProvinceSelection(event){
    this.currentProvinceId=Number(event.target.value);
    this.districtService.getDistrictByProvince(Number(event.target.value))
    .subscribe(res=>{
      this.districtList=res;
    },
    error=>{
    });
  }
  onDistrictSelection(event){
    this.currentDistrictId=Number(event.target.value);
    this.municipalityService.getMunicipalitiesByDistrict(Number(event.target.value))
    .subscribe(res=>{
    },
    error=>{
    });
  }

}



