import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {

  constructor (private apiService:ApiService) {

  }
    getAllMunicipalities(){
      return this.apiService.get('Municipalities/GetAll');
    }
    getAllIncludingCountry(){
      return this.apiService.get('Municipalities/GetAllIncludingCountry');
    }
    getMunicipalityById(id: any){
      return this.apiService.get('Municipalities/GetById/'+id);
    }
    upsertMunicipalities(data:any){
      return this.apiService.post('Municipalities/UpsertMunicipalities',data);
    }
    deleteMunicipalities(id:any){
      return this.apiService.post('Municipalities/DeleteMunicipalities/'+id);
    }
    getMunicipalitiesByDistrict(districtId){
      return this.apiService.get('Municipalities/GetByDistrictId/'+districtId);
    }
    getMunicipalities(query: QueryObject){
      return this.apiService.post('Municipalities/GetQueryMunicipalities', query);
    }
    public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('Municipalities/ExportToExcel',query)
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('Municipalities/ExportToPdf',query);
  }

  }

