import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import {authConfig} from '../login/sso.config';

@Component({
  selector: 'app-loginsuccessful',
  templateUrl: './loginsuccessful.component.html',
  styleUrls: ['./loginsuccessful.component.scss']
})
export class LoginsuccessfulComponent implements OnInit {

  userId: any;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

  }

}
