import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { Router } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { environment } from 'src/environments/environment';
import { CountryQueryObject, QueryObject } from '../../../shared/models/queryObject';
import { Country } from 'src/app/shared/models/country';
import { identifierName } from '@angular/compiler';
import { saveAs as importedSaveAs } from 'file-saver';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { NavService } from 'src/app/shared/service/nav.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';



@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss']
})
export class CountryListComponent implements OnInit {
  public country = [];
  public countryList: Country[] = [];
  public countryItem: any = [];
  public dataPermission = DataPermissions;
  noDataFound: string;
  showFilters: Boolean = false;
  queryObject = new CountryQueryObject();
  pageSize = environment.pageSize;

  //queryObject = new QueryObject();
  columns = [
    { title: 'Country', key: 'Name', isSortable: true },
    { title: 'Description', key: 'Description', isSortable: true },
    { title: 'Country Code', key: 'CountryCode', isSortable: true },
    { title: 'Action' }

  ];


  constructor
    (
      public mps: ManagePermissionService,
      private countryService: CountryService,
      private _router: Router,
      private alertyfy: AlertyfyService,
      private spinningManager: SpinnerManagerService,
  ) {
    //this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit() {
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.loadCountry();
    //this.dp_countries_Edit =this.mps.manageDataPermission([DataPermissions.Countries_Edit,DataPermissions.Countries_Delete]);
  }
  getCountry() {
    this.spinningManager.showSpinner("Loading.....");
    this.countryService.getCountry(this.queryObject).subscribe((res: any) => {
      this.countryList = [];
      this.queryObject.pageCount = res.totalItems;
      this.spinningManager.hideSpinner();
      for (const item of res.items) {
        const countryItem = new Country();
        countryItem.id = item.id;
        countryItem.name = item.name;
        countryItem.description = item.description;
        countryItem.countryCode = item.countryCode;
        this.countryList.push(countryItem);
      }
    }, error => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    })
  }
  //pagination code
  loadCountry() {
    this.getCountry();
  }

  //Sorting Code
  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.loadCountry();
  }
  //pagination code
  onPageChange(page) {
    this.queryObject.page = page;
    this.loadCountry();
  }


  onAddNewButtonClick() {
    this._router.navigateByUrl('/category/add-master-list')
  }
  showHideFilter() {
    this.showFilters = !this.showFilters;

  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.countryService.deleteCountry(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getCountry();
          },
            (error) => {
              this.alertyfy.error(error);
            })

      });
  }
  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/create-country');
  }
  public exportToExcel(flag: boolean) {

    this.queryObject.printall = flag;
    this.countryService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'CountryList.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.countryService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'CountryList.pdf');
    });
  }
  public Search() {
    this.loadCountry();

  }
  public Reset() {
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.pageSize = this.pageSize;
    this.loadCountry();
  }
}
