import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { City } from 'src/app/shared/models/city';
import { CityService } from 'src/app/shared/service/ManageDelivery/citylist.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { query } from '@angular/animations';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { MunicipalityService } from '../../../shared/service/ManageDelivery/municipality.service';

@Component({
  selector: 'app-city-create',
  templateUrl: './city-create.component.html',
  styleUrls: ['./city-create.component.scss']
})
export class CityCreateComponent implements OnInit {
  cityListForm: FormGroup;
  cityList = [];
  districtList = [];
  countryList = [];
  provinceList = [];
  currentCountryId = 0;
  currentProvinceId = 0;
  currentDistrictId = 0;
  dropdownSettings = {};
  public municipalityList: municipalityListDropDown[] = [];
  cityid: number = 0;
  btnSave = 'Save';


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cityService: CityService,
    private districtService: DistrictService,
    private alertyfy: AlertyfyService,
    private route: ActivatedRoute,
    private provinceService: ProvinceService,
    private countryService: CountryService,
    private spinningManager: SpinnerManagerService,
    private municipalityService: MunicipalityService

  ) {
    this.cityListForm = this.fb.group({
      id: '',
      name: '',
      districtId: '0',
      description: '',
      provinceId: '0',
      countryId: '0',
      municipalitiesId: '0'
    });
    this.cityid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
    if (this.cityid > 0) {
      this.getSetCurrentLocation();
    } else {
      this.getAllCountries();
    }
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.spinningManager.hideSpinner();
  }
  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
      },
        error => {
          this.alertyfy.error(error);
        });
  }
  getSetCurrentLocation() {
    this.cityService.getCityById(this.cityid)
      .subscribe(res => {
        this.currentDistrictId = res.result.districtId;
        this.currentProvinceId = res.result.provinceId;
        this.currentCountryId = res.result.countryId;
        this.cityListForm.setValue({
          id: res.id,
          provinceId: res.result.provinceId,
          districtId: res.result.districtId,
          countryId: res.result.countryId,
          name: res.result.name,
          description: res.result.description
        });
        this.getLocation();
      });
  }
  saveCity() {
    this.spinningManager.showSpinner("Saving.....");
    var _city = new City();
    var cityObj = this.cityListForm.value;
    _city.id = Number(this.cityid);
    _city.name = cityObj.name;
    _city.description = cityObj.description;
    _city.districtId = (cityObj.districtId);
    _city.provinceName = (cityObj.provinceName);
    _city.countryName = (cityObj.countryName);
    _city.municipalitiesId = (cityObj.municipalitiesId);

    this.cityService.upsertCityList(_city)
      .subscribe(res => {
        if (res) {
          this.alertyfy.success("Successfully Inserted.");
          this.cityListForm.reset();
          this.router.navigateByUrl('/managedelevery/city-list');
          this.spinningManager.hideSpinner();
        }
      },
        error => {
          this.spinningManager.hideSpinner();
          this.alertyfy.error(error);
        });
  }
  getLocation() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.provinceService.getProvinceByCountry(this.currentCountryId)
          .subscribe(res => {
            this.provinceList = res;
            this.districtService.getDistrictByProvince(this.currentProvinceId)
              .subscribe(res => {
                this.districtList = res;
              },
                error => {

                });
          },
            error => {

            });
      },
        error => {

        });
  }
  onCountrySelection(event) {
    this.currentCountryId = Number(event.target.value);
    this.provinceService.getProvinceByCountry(Number(event.target.value))
      .subscribe(res => {
        this.provinceList = res;
        this.districtList = [];
      },
        error => {

        });
  }
  onProvinceSelection(event) {
    this.currentProvinceId = Number(event.target.value);
    this.districtService.getDistrictByProvince(Number(event.target.value))
      .subscribe(res => {
        this.districtList = res;
      },
        error => {

        });
  }

  onDistrictSelection(event) {
    this.currentDistrictId = Number(event.target.value);
    this.getMuncipalityByDistrictId(this.currentDistrictId);
    this.cityService.getCityByDistrictId(Number(event.target.value))
      .subscribe(res => {
      },
        error => {

        });
  }
  getMuncipalityByDistrictId(districtId: number) {
    this.municipalityService.getMunicipalitiesByDistrict(districtId).subscribe(res => {
      this.municipalityList = res;
    },
      error => {

      })
  }
  onMuncipalitySelection(event) {

  }
}
export interface municipalityListDropDown {
  municipalitiesId: number;
  municipalityName: string;
}


