export class PradeshSabha {
  id: number;
  name: string;
  description: string;
  pratinidhiSabhaId: number;
  pradeshSabhaCode: string;
  pratinidhiSabhaName: string;
  districtId:number;
  districtName:string;
}
