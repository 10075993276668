import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private apiService: ApiService) { }
  getAllMember(){
    return this.apiService.get('Member/GetAll');
  }
  getMemberById(id: any){
    return this.apiService.get('Member/getbyid?id='+id);
  }
  createMember(data:any){
    return this.apiService.post('Member/create',data)
  }
  deleteMember(id:any){
    return this.apiService.post('Member/delete?id='+id);
  }
  upsertMember(data:any){
    return this.apiService.post('Member/update',data);
  }
  getMember(query: QueryObject){
    return this.apiService.post('Member/MemberItem', query);
}

getMemberItems(query: QueryObject){
  return this.apiService.post('Member/GetQueryMember', query)
}

  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('Member/ExportToPdf',query);
  }

  public exportByIdPDF(data:any){
    return this.apiService.downloadFile('Member/ExportToPdf/'+data);
  }


  public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('Member/ExportToExcel',query);
  }

  public printBackPage(){
    return this.apiService.downloadFile('Member/ExportBackPdf','');
  }
}
