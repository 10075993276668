<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12"></div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row width-half">
            <div class="col-md-6 col-sm-7">
              <h5>
                Country List
                <button *ngIf="
                    this.mps.manageDataPermission([
                      dataPermission.Countries_Add
                    ])
                  " type="button" class="btn btn-success ml-2" (click)="onCreateConfirm()">
                  Add New <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </h5>
            </div>
            <div class="col-md-6 col-sm-5">
              <a href="javascript:void(0)" (click)="showHideFilter()" class="filter">
                <i class="fa fa-filter" aria-hidden="true"></i> Filter
              </a>
            </div>
          </div>
        </div>

        <div class="card-body margin-sm">
          <div class="row filter-contain" *ngIf="showFilters">
                <div class="col-md-4 col-sm-12">
                <input class="form-control" placeholder="Search Text" id="wareHouse" type="text" required=""
                    [(ngModel)]="queryObject.searchString" (keyup.enter)="Search()" />
                </div>
                <div class="col-md-8 col-sm-12">
                    <div class="row btn-manage">
                        <div class="col-md-4 col-sm-12">
                            <div class="d-flex justify-content-start flexwrap-mobile">
                                <button type="button" class="btn btn-secondary mr-2 w-100 margin-mobile" (click)="Search()">
                                    Search
                                </button>                                
                                <button type="button" class="btn btn-primary w-100" (click)="Reset()">
                                  Reset
                              </button>
                            </div>                           
                        </div>
                    </div>
                </div>
          </div>
          <div class="row export-btn">
            <div class="col-12">
              <div *ngIf="
                  this.mps.manageDataPermission([
                    dataPermission.Countries_Export_Excel
                  ])
                " class="btn-group btn-group1 mr-1">
                <button type="button" class="btn btn-secondary btn1">
                  Export to Excel
                </button>
                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                  <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button ngbDropdownItem (click)="exportToExcel(false)">
                      Current Page
                    </button>
                    <button ngbDropdownItem (click)="exportToExcel(true)">
                      All Page
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="
                  this.mps.manageDataPermission([
                    dataPermission.Countries_Export_PDF
                  ])
                " class="btn-group btn-group1">
                <button type="button" class="btn btn-secondary btn1">
                  Export to PDF
                </button>
                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                  <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button ngbDropdownItem (click)="exportToPdf(false)">
                      Current Page
                    </button>
                    <button ngbDropdownItem (click)="exportToPdf(true)">
                      All Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table mt-3 text-nowrap table-bordered">
              <thead>
                <tr>
                  <th *ngFor="let c of columns">
                    <div *ngIf="c.isSortable" (click)="sortBy(c.key)">
                      {{ c.title }}
                      <i *ngIf="queryObject.sortBy === c.key" class="fa" [class.fa-sort-asc]="queryObject.isSortAsc"
                        [class.fa-sort-desc]="!queryObject.isSortAsc"></i>
                    </div>
                    <div *ngIf="!c.isSortable">
                      {{ c.title }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="countryList?.length; else no_data_temp">
                <tr *ngFor="let item of countryList">
                  <td>
                    {{ item.name }}
                  </td>

                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.countryCode }}
                  </td>
                  <td class="edit-tableicon">
                    <a *ngIf="
                        this.mps.manageDataPermission([
                          dataPermission.Countries_Edit
                        ])
                      " [routerLink]="['/managedelevery/create-country', item.id]"><i class="fa fa-edit"></i></a>

                    <i *ngIf="
                        this.mps.manageDataPermission([
                          dataPermission.Countries_Delete
                        ])
                      " (click)="onDeleteConfirm(item.id)" class="fa fa-trash">
                    </i>
                  </td>
                </tr>
              </tbody>
              <ng-template #no_data_temp>
                <tbody>
                  <td colspan="4">
                    <h4 class="text-center d-block w-100 mt-3">
                      {{ this.noDataFound }}
                    </h4>
                  </td>
                </tbody>
              </ng-template>
            </table>
          </div>
          <ngb-pagination [collectionSize]="queryObject.pageCount" [pageSize]="queryObject.pageSize"
            [page]="queryObject.page" (pageChange)="onPageChange($event)" aria-label="Default pagination">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
