export class City {
    id: number;
    name:string;
    description: string;
    districtId:number;
    districtCode:string;
    districtName: string;
    countryName:string;
    provinceName:string;
    municipalitiesId:number;
    muncipalityName:string;
}