import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { PaginationComponent } from './components/pagination/pagination.component';//not our Coupon; from original template
//stepper
import { TagInputModule } from 'ngx-chips';
import { BarRatingModule } from 'ngx-bar-rating';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserDocumentComponent } from './components/userDocument/user-document/user-document.component';


@NgModule({
  declarations: [
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    PaginationComponent,
    PageNotFoundComponent,
    UserDocumentComponent,



  ],
  imports: [
    TagInputModule,
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    CdkStepperModule,
    BarRatingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCaHBw3I3koLTVY4pzVb1AeQpTFfpmqSWI',
      libraries: ['places']
    })
  // FormArray,
  ],
  providers: [NavService, WINDOW_PROVIDERS],
  exports: [
     FeatherIconsComponent
    , CdkStepperModule,
    BarRatingModule,
    UserDocumentComponent

  ]
})
export class SharedModule { }
