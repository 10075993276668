import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ngx-ckeditor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ManagedeleveryRoutingModule } from './managedelevery-routing.module';
import { CityCreateComponent } from './city-create/city-create.component';
import { CityListComponent } from './city-list/city-list.component';
import { CreateCountryComponent } from './create-country/create-country.component';
import { CountryListComponent } from './country-list/country-list.component';
import {AddRegionComponent} from './add-region/add-region.component';
import { RegionListComponent } from './region-list/region-list.component';
import { AddDistrictComponent } from './add-district/add-district.component';
import { DistrictListComponent } from './district-list/district-list.component';
import { AddProvinceComponent } from './add-province/add-province.component';
import { ProvinceListComponent } from './province-list/province-list.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MunicipalityListComponent } from './municipality-list/municipality-list.component';
import {AddMunicipalityComponent}  from './add-municipality/add-municipality.component';

import { from } from 'rxjs';
import { MemberListComponent } from './member-list/member-list.component';
import { CreateMemberComponent } from './create-member/create-member.component';
import { PratinidhiSabhaListComponent } from './pratinidhi-sabha-list/pratinidhi-sabha-list.component';
import { PradeshSabhaListComponent } from './pradesh-sabha-list/pradesh-sabha-list.component';
import { ChetraListComponent } from './chetra-list/chetra-list.component';
import { AddPratinidhiSabhaComponent } from './add-pratinidhi-sabha/add-pratinidhi-sabha.component';
import { AddPradeshSabhaComponent } from './add-pradesh-sabha/add-pradesh-sabha.component';
import { AddChetraComponent } from './add-chetra/add-chetra.component';
import { MemberPicUploadComponent } from './member-pic-upload/member-pic-upload.component';
import { ProgramComponent } from './program/program.component';
import { AddProgramComponent } from './add-program/add-program.component';
import { DoctorListComponent } from './doctor-list/doctor-list.component';
import { CreateDoctorComponent } from './create-doctor/create-doctor.component';
import { UpsertDoctorWorkHistoryComponentComponent } from './upsert-doctor-work-history-component/upsert-doctor-work-history-component.component';
import { ListDoctorWorkHistoryComponentComponent } from './list-doctor-work-history-component/list-doctor-work-history-component.component';
import { ListRegistedBranchComponent } from './RegistedBranch/list-registed-branch/list-registed-branch.component';
import { UpsertRegistedBranchComponent } from './RegistedBranch/upsert-registed-branch/upsert-registed-branch.component';
import { AddDecimalDirective } from './upsert-doctor-work-history-component/add-decimal.directive';


@NgModule({
  declarations: [CityCreateComponent, CityListComponent, CreateCountryComponent, CountryListComponent, AddRegionComponent, RegionListComponent, AddDistrictComponent, DistrictListComponent, AddProvinceComponent, ProvinceListComponent, AddMunicipalityComponent, MunicipalityListComponent, MemberListComponent, CreateMemberComponent, PratinidhiSabhaListComponent, PradeshSabhaListComponent, ChetraListComponent, AddPratinidhiSabhaComponent, AddPradeshSabhaComponent, AddChetraComponent, MemberPicUploadComponent, ProgramComponent, AddProgramComponent, DoctorListComponent, CreateDoctorComponent, UpsertDoctorWorkHistoryComponentComponent, ListDoctorWorkHistoryComponentComponent, ListRegistedBranchComponent, UpsertRegistedBranchComponent, AddDecimalDirective, ],
  imports: [
    CommonModule,
    ManagedeleveryRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
  ]
})
export class ManagedeleveryModule { }