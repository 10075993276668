export class Province {
    id: number;
    name: string;
    description: string;
    provincecode:string;
    countryId: number;
    countryCode:string;
    countryName: string;
    provinceCode: string;
}
