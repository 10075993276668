import { Component, OnInit } from '@angular/core';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { Router } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ProvinceQueryObject, QueryObject } from '../../../shared/models/queryObject';
import { environment } from 'src/environments/environment';
import { Province } from 'src/app/shared/models/province';
import { saveAs as importedSaveAs } from 'file-saver';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';

@Component({
  selector: 'app-province-list',
  templateUrl: './province-list.component.html',
  styleUrls: ['./province-list.component.scss']
})
export class ProvinceListComponent implements OnInit {
  public provinceList: Province[];
  public province = [];
  public provinceItem: any = [];
  noDataFound: string;
  queryObject = new ProvinceQueryObject();
  showFilters: Boolean = false;
  public dataPermission = DataPermissions;
  pageSize = environment.pageSize;
  countryList: CountryList[] = [];

  columns = [
    { title: 'Province', key: 'Name', isSortable: true },
    { title: 'Description', key: 'Description', isSortable: true },
    { title: 'Province Code', key: 'ProvinceCode', isSortable: true },
    // { title: 'Country Id', key: 'CountryId', isSortable: true },
    // { title: 'Country Code', key: 'CountryCode', isSortable: true },
    { title: 'Country', key: 'CountryName', isSortable: true },
    { title: 'Action' }

  ];

  constructor(
    private provinceService: ProvinceService,
    private _router: Router,
    private alertyfy: AlertyfyService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
    private countryService: CountryService

  ) {
    this.spinningManager.showSpinner("Loading.....");
  }


  ngOnInit() {
    this.noDataFound = environment.noDataFound;
    //this.getAllProvince();
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.getAllCountries();
    this.loadProvince();
  }
  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getProvince() {
    this.provinceService.getProvince(this.queryObject).subscribe((res: any) => {
      this.provinceList = [];
      this.queryObject.pageCount = res.totalItems;
      this.spinningManager.hideSpinner();
      for (const item of res.items) {
        const provinceItem = new Province();
        provinceItem.id = item.id;
        provinceItem.name = item.name;
        provinceItem.description = item.description;
        provinceItem.provinceCode = item.provinceCode;
        provinceItem.countryId = item.countryId;
        provinceItem.countryCode = item.countryCode;
        provinceItem.countryName = item.countryName;
        this.provinceList.push(provinceItem);
      }
    }, error => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });
  }
  //pagination code
  loadProvince() {
    this.getProvince();
  }

  //Sorting Code
  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.loadProvince();
  }
  //pagination code
  onPageChange(page) {
    this.queryObject.page = page;
    this.loadProvince();
  }


  //delete code
  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.provinceService.deleteProvince(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getProvince();
          },
            (error) => {
              this.alertyfy.error("Something went wrong. Delete Failed.");

            })

      });
    id.confirm.resolve();/*Makes delete functional*/
    this._router.navigateByUrl('/managedelevery/country-create');
  }
  public exportToExcel(flag: boolean) {

    this.queryObject.printall = flag;
    this.provinceService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'ProvinceList.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.provinceService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'ProvinceList.pdf');
    });
  }
  public showHideFilter() {
    this.showFilters = !this.showFilters;
  }
  public Search() {
    this.loadProvince();
  }
  public Reset() {
    this.queryObject.searchString = '';
    this.queryObject.countryId = 0;
    this.queryObject.page = 1;
    this.queryObject.pageSize = this.pageSize;
    this.loadProvince();
  }

}
export interface CountryList {
  countryId: number;
  countryName: string;
}

