import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable() export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authService: AuthService) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    //return true;
    return this.authService.isLoggedIn
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn){
            this.router.navigate(['/sign-in'],{ queryParams: { returnUrl: state.url }});
            return false;
          }else{
            if (localStorage.getItem('userDetails') != null) {
              const datapermissionIds = route.data.datapermissionIds as Array<string>;
              const systemPermissionIds = route.data.systemPermissionIds as Array<string>;
              const item: any = JSON.parse(localStorage.getItem('userDetails'));
              if (true) {
                if (systemPermissionIds == undefined) {
                  return true;
                }
                else {
                  let flag: boolean = false;
                  systemPermissionIds.forEach(element => {
                    const resultsp = item.userSystemPermissions.filter(
                      (s: any) => { return s.systemPermissionId == element });
                    if (resultsp != null && resultsp.length > 0 && resultsp[0].systemPermissionId === element) {
                      flag = true;
                      //return true;
                    }
                    else {
                      //this.router.navigate(['/auth/login']);
                      //this.router.navigateByUrl("shared/components/page-not-found");
                      flag = false;
                      // return false;
                    }
                    if (flag) {
                      return true;
                    }
                    else {
                      this.router.navigateByUrl("shared/components/page-not-found");
                      return false;
                    }
                  })
                }
                if (datapermissionIds != undefined) {
                  datapermissionIds.forEach(element => {
                    const result = item.userDataPermissions.filter(
                      (s: any) => { return s.id == element });
                    if (result != null && result.length > 0 && result[0].id === element) {
                      return true;
                    }
                    else {
                      this.router.navigateByUrl("shared/components/page-not-found");
                      return false;
                    }
                  });
                }
                return true;
              }
            }
          }
          //return true;
        })
      )
    
    //this.router.navigateByUrl("auth/login");
    //this.router.navigate(['components/auth/login']);
  }
}