import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { District } from 'src/app/shared/models/district';
import { Member } from 'src/app/shared/models/member';
import { PradeshSabha } from 'src/app/shared/models/pradeshSabha';
import { PratinidhiSabha } from 'src/app/shared/models/pratinidhiSabha';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { MemberService } from 'src/app/shared/service/ManageDelivery/member.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { DoctorService } from 'src/app/shared/service/ManageDelivery/doctor.service';
import { Doctor } from 'src/app/shared/models/doctor';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  public dashboardSummar;

  public salesReport = [];
  public saleReportLabel = [];

  public purchaseReport = [];
  public purchaseReportLabel = [];

  public buySellReport = [];
  public buySellReportLabel = [];
  public buysellReportType = 'monthly';

  public orderByLocation;
  public salesByLocation;

  public dashboardSelesReportDetails;
  public dashboardPurchaseReportDetails;

  memberList: Member[];
  districtList: District[];
  pratinidhiList: PratinidhiSabha[];
  pradeshList: PradeshSabha[];
  doctorlist: Doctor[];

  constructor(
    private memberService: MemberService,
    private districtService: DistrictService,
    private pratinidhiService: PratinidhiSabhaService,
    private pradeshService: PradeshSabhaService,
    private doctotrService: DoctorService,
    private router: Router
  ) {
    Object.assign(this, { doughnutData, pieData });
  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  // lineChart
  //public lineChartData = chartData.lineChartData;
  public lineChartData = this.buySellReport;
  public lineChartLabels = chartData.lineChartLabels;
  //public lineChartLabels = this.buySellReportLabel;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = this.salesReport;
  public smallLineChartLabels = this.saleReportLabel;

  //public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = this.purchaseReport;
  public smallLine2ChartLabels = this.purchaseReportLabel;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;

  // events
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}

  ngOnInit() {
    this.getAllMember();
    this.getAllDistrict();
    this.getAllPratinidhi();
    this.getAllPradesh();
    this.getAllDoctor();
  }

  getAllMember() {
    this.memberService.getAllMember().subscribe((res) => {
      this.memberList = res;
    });
  }

  getAllDistrict() {
    this.districtService.getAllDistrict().subscribe((res) => {
      this.districtList = res;
    });
  }

  getAllPratinidhi() {
    this.pratinidhiService.getAllPratinidhiSabha().subscribe((res) => {
      this.pratinidhiList = res;
    });
  }
  getAllDoctor() {
    this.doctotrService.getAllMember().subscribe((res) => {
      this.doctorlist = res;

    });
  }

  getAllPradesh() {
    this.pradeshService.getAllPradeshSabha().subscribe((res) => {
      this.pradeshList = res;
    });
  }

  goToMember() {
    this.router.navigateByUrl('/managedelevery/member-list');
  }
  goToPratinidhiSabha() {
    this.router.navigateByUrl('/managedelevery/pratinidhi-sabha-list');
  }
  goToDistrict() {
    this.router.navigateByUrl('/managedelevery/district-list');
  }
  goToPradesh() {
    this.router.navigateByUrl('/managedelevery/pradesh-sabha-list');
  }
}
