import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ManagePermissionService {

  constructor(private router: Router) { }
  managePermissions(systemPermissionIds: any[]): boolean {
    let x: boolean = false;
    if (localStorage.getItem('userDetails') != null) {
      const item: any = JSON.parse(localStorage.getItem('userDetails'));
      if (true) {
        if (systemPermissionIds == undefined) {
          x = true;
          //return true;
        }
        else {
          systemPermissionIds.forEach(element => {
            const resultsp = item.userSystemPermissions?.filter(
              (s: any) => { return s.systemPermissionId == element });
            if (resultsp != null && resultsp.length > 0 && resultsp[0].systemPermissionId === element) {
              x = true;
              //return true;
            }
            else {
              x = false;
              //return false;
            }
          })
          return x;
        }


      }
    }
  }
  manageDataPermission(datapermissionIds: any[]): boolean {
    if (localStorage.getItem('userDetails') != null) {
      let x: boolean = true;
      const item: any = JSON.parse(localStorage.getItem('userDetails'));
      if (true) {
        if (datapermissionIds != undefined) {
          datapermissionIds.forEach(element => {
            const result = item.userDataPermissions.filter(
              (s: any) => { return s.dataPermissionId == element });
            if (result != null && result.length > 0 && result[0].dataPermissionId === element) {
              x = true;
              //return true;
            }
            else {
              x = false
              //return false;
            }
          });
        }
        return x;
      }
    }

  }

}

