export class userdetails
{
    id:number;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    email:string;
    gender:number;
    gender_:string;
    dateofBirth:Date;
    imagePath:string;
    userAddress:userAddress[];
}
export class userAddress
{
    id:number;
    userId:number;
    countryId :string;
    country:string;
    provinceId:string;
    province:string;
    districtId:string;
    district:string;
    cityId:string;
    city:string;
    address:string;
    addressTypeId:number;
    addressType:string;
    isActive:boolean;
    phoneNumber:string;
    tole :string;
    gharNumber:string;
    isPrimaryAdress:boolean;
    googleAddress:string;
    latitude:string;
    longitude:string;

    constructor(){
        this.id = 0;
        this.userId = 0;
        this.countryId = '';
        this.country = '';
        this.provinceId = '';
        this.province = '';
        this.districtId = '';
        this.district = '';
        this.cityId = '';
        this.city = '';
        this.address = '';
        this.addressTypeId = 0;
        this.addressType = '';
        this.isActive = false;
        this.phoneNumber = '';
        this.tole = '';
        this.gharNumber = '';
        this.isPrimaryAdress = false;
        this.googleAddress = '';
        this.latitude = '';
        this.longitude = '';
    }
}

export class userbasicinfo
{
    id:number;
    firstName:string;
    lastName:string;
    email:string;
    profilePictureUrl:string;
    facebookLink:string;
    googlePlusLink:string;
    twitterLink:string;
}
