import { Injectable } from '@angular/core';
import { DataPermissionQueryObject, QueryObject } from '../../models/queryObject';
import {ApiService} from '../api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystempermissionService {

  constructor(private apiService:ApiService,private http:HttpClient) { }
   public upsertSystemPermission(obj:any){
    return this.apiService.post('SystemPermission/UpsertSystemPermission',obj);
  }
   public getAll(){
    return this.apiService.post('SystemPermission/GetAll');
  }
  public getSystemPermissionById(id:number){
    return this.apiService.get('SystemPermission/GetById/'+id);
  }
  public deletSystemPermission(id:number){
    return this.apiService.post('SystemPermission/Delete/'+id)
  }
   public getAllItem(query:DataPermissionQueryObject){
    return this.apiService.post('SystemPermission/SystemPermissionItem',query);
  }
}
