import { Injectable } from '@angular/core';
import { DataPermissionQueryObject, QueryObject } from '../../models/queryObject';
import {ApiService} from '../api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatapermissionService {

  constructor(private apiService:ApiService,private http:HttpClient) { }
  public upsertDataPermission(obj:any){
    return this.apiService.post('DataPermission/UpsertDataPermission',obj);
  }
  public getAll(){
    return this.apiService.post('DataPermission/GetAll');
  }
  public getDataPermissionById(id:number){
    return this.apiService.get('DataPermission/GetById/'+id);
  }
  public deletDataPermission(id:number){
    return this.apiService.post('DataPermission/Delete/'+id)
  }
  public getAllItem(query:DataPermissionQueryObject){
    return this.apiService.post('DataPermission/DataPermissionItem',query);
  }
}
