import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CKEditorModule } from 'ngx-ckeditor';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedModule } from '../../shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { CreateSystemPermissionComponent } from '../permission-management/create-system-permission/create-system-permission.component';
import { ListSystemPermissionComponent } from '../permission-management/list-system-permission/list-system-permission.component';
import { CreateDataPermissionComponent } from '../permission-management/create-data-permission/create-data-permission.component';
import { ListDataPermissionComponent } from '../permission-management/list-data-permission/list-data-permission.component';
import { PermissionmanagementRoutingModule } from './permissionmanagement-routing.module';



@NgModule({
  declarations: [
     CreateSystemPermissionComponent,
    ListSystemPermissionComponent,
    CreateDataPermissionComponent,
    ListDataPermissionComponent,

  ],
  imports: [
    PermissionmanagementRoutingModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    CKEditorModule,
    NgxDropzoneModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    SharedModule,
    TabsModule.forRoot(),
    NgBootstrapFormValidationModule
  ]
})
export class PermissionManagementModule { }
