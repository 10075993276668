
<div class="page-wrapper">
    <!-- <div class="authentication-box"> -->
        <div class="">
            <div class="row">
                <div class="col-lg-5 registerbox" style=" background: #FFF; height: 100vh;">
                    <img src="../assets/images/logo.png" style="width: 100px; margin-bottom: 30px;"/>
                    <h3 style="font-size:20px; color: #ff8084; margin-bottom: 15px;">Register your store</h3>
                            <form [formGroup]="registerForm" 
                                class="form-horizontal auth-form" novalidate (ngSubmit)="onSubmit()">
                                <div class="form-group" >
                                    <input required="" name="firstName" type="text"
                                        class="form-control" placeholder="First Name"
                                        formControlName="firstName">
                                    
                                        <div *ngIf="submitted && r.firstName.errors">
                                            <div class="errmsg" *ngIf="r.firstName.errors.required" >First Name is required</div>
                                        </div>
                                </div>
                                <div class="form-group" >
                                    <input required="" name="lastName" type="text"
                                        class="form-control" placeholder="Last Name"
                                        formControlName="lastName">

                                        <div *ngIf="submitted && r.lastName.errors" >
                                            <div class="errmsg"  *ngIf="r.lastName.errors.required">Last Name is required</div>
                                        </div>
                                </div>
                                <div class="form-group" >
                                    <input required="" name="phoneNumber" type="text"
                                        class="form-control" placeholder="Phone Number"
                                        formControlName="phoneNumber">
                                        <div *ngIf="submitted && r.phoneNumber.errors">
                                            <div  class="errmsg"  *ngIf="r.phoneNumber.errors.required">Phone Number is required</div>
                                        </div>
                                </div>
                                <div class="form-group" >
                                    <input required="" name="userName" type="email"
                                        class="form-control" placeholder="Email"
                                        formControlName="userName">

                                        <div *ngIf="submitted && r.userName.errors">
                                            <div class="errmsg"  *ngIf="r.userName.errors.required">Email is required</div>
                                            <div class="errmsg"  *ngIf="r.userName.errors.email">Email must be a valid email address</div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <input required="" name="Password" type="password"
                                        class="form-control" placeholder="Password"
                                        formControlName="password">
                                        <div *ngIf="submitted && r.password.errors" >
                                            <div class="errmsg"  *ngIf="r.password.errors.required">Password is required</div>
                                            <div class="errmsg"  *ngIf="r.password.errors.minlength">Password must be at least 6 characters</div>
                                        </div>
                                </div>

                                <!-- <div class="form-group">
                                    <input required="" name="confirmpassword" type="password"
                                        class="form-control" placeholder="Confirm Password"
                                        formControlName="confirmpassword">
                                        <div *ngIf="submitted && r.confirmpassword.errors" >
                                            <div *ngIf="r.confirmpassword.errors.required">Confirm Password is required</div>
                                            <div *ngIf="r.confirmPassword.errors.checkIfMatchingPasswords">Passwords must match</div>
                                        </div>
                                </div> -->

                                <div class="form-terms">
                                    <div class="custom-control custom-checkbox mr-sm-2">
                                        <input type="checkbox" class="custom-control-input"
                                            id="customControlAutosizing1">
                                        <label class="custom-control-label"
                                            for="customControlAutosizing1"><span style="
                                            margin-top: 2px;
                                            position: relative;
                                            display: block;
                                        ">I agree all statements
                                                in &nbsp; <a href="javascript:void(0)" >Terms
                                                    &amp;
                                                    Conditions</a></span></label>
                                    </div>
                                </div>
                                <div class="form-button" style="  padding-top: 20px;">
                                    <button class="btn btn-primary register-btn w-100" type="submit">Register</button>
                                    <!-- <button class="btn btn-primary" type="button" (click)="login()">Login</button> -->
                                </div>
                               
                            </form>
                            <div style="margin-top:30px;">

                                <h3 class="alreadylol"><span>Already have an account</span>  </h3>
                               
                                <!-- <i class="icon-unlock mr-2"></i> -->
                              <a href="/dashboard/default" class="btn w-50" style="border-right: 1px solid #ccc;">Log in</a>

                              <a href="./" class="btn  w-50">Home</a> 
                            </div>

                              
                          
                </div>
                <div class="col-lg-7" style="background: url(../assets/images/imagebg.jpg); background-size: cover;"></div>
               
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    <!-- </div> -->
</div>