<form [formGroup]="cityListForm">
    
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5> Add City</h5>
            </div>
       
            <div class="row card-body tab2-card" >
                <div class="col-12">
                    <div class="form-group row">
                        <label for="countryId" class="col-xl-3 col-md-3">Country Name</label>
                        <div class="col-lg-9 col-md-9">
                            <select (change)="onCountrySelection($event)" class="form-control" formControlName="countryId" id="countryId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of countryList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option>
                            </select>
                        </div>
                        
                    </div>
                    <div class="form-group row">
                        <label for="provinceId" class="col-xl-3 col-md-3">Province Name</label>
                        <div class="col-lg-9 col-md-9">
                            <select (change)="onProvinceSelection($event)" class="form-control" formControlName="provinceId" id="provinceId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of provinceList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option> 
                            </select>
                        </div>
                       
                    </div>
                    <div class="form-group row">
                        <label for="districtId" class="col-xl-3 col-md-3">District Name</label>
                        <div class="col-lg-9 col-md-9">
                            <select (change)="onDistrictSelection($event)" class="form-control" formControlName="districtId" id="districtId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of districtList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option>
                            </select>
                        </div>
                       
                    </div>
                    <div class="form-group row">
                        <label for="municipalitiesId" class="col-xl-3 col-md-3">Muncipality Name</label>
                        <div class="col-lg-9 col-md-9">
                            <select (change)="onMuncipalitySelection($event)" class="form-control" formControlName="municipalitiesId" id="municipalitiesId">
                                <option [value]='0'>--Select--</option>
                                <option *ngFor='let obj of municipalityList; let i =index;' [value]='obj.id'>
                                    {{obj.name}}
                                </option>
                            </select>
                        </div>
                       
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span> Name</label>
                        <div class="col-lg-9 col-md-9">
                            <input class="form-control " formControlName="name"
                        name="name" type="text" required="">
                        </div>
                        
                    </div>
                    <div class="form-group row editor-label">
                        <label class="col-xl-3 col-lg-3 col-md-3" >Description</label>
                       <div class=" col-xl-9 col-md-8">
                          <textarea rows="4" class="w-100 form-control" formControlName="description" required></textarea>
                       </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label for="name" class="col-xl-3 col-md-3"><span>*</span>Description</label>
                        <div class="col-lg-9 col-md-9">
                            <input class="form-control " formControlName="description"
                        name="description" type="text" required="">
                        </div>
                        
                    </div> -->
                     <div class="pull-right">
                        
                        <button type="button" class="btn btn-primary" [routerLink]="['/managedelevery/city-list']">
                            Discard
                        </button>
                        <button type="button" class="btn btn-success ml-2" [routerLink]="['/managedelevery/city-list']" (click)="saveCity()">
                            {{this.cityid>0?"Update":"Save"}}
                        </button>
                    </div>
                    
                    
                </div>
            </div>
                               
         </div>
    </div>
</form> 
    