import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemPermission } from 'src/app/shared/models/systemPermission';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { SystempermissionService } from 'src/app/shared/service/systemPermission/systempermission.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-create-system-permission',
  templateUrl: './create-system-permission.component.html',
  styleUrls: ['./create-system-permission.component.scss']
})
export class CreateSystemPermissionComponent implements OnInit {
  public SystemPermissoinForm:FormGroup;
  public spid:number;
  constructor(private fb:FormBuilder,
    private route:ActivatedRoute,
    private _router:Router,
    private systemPermissionService:SystempermissionService,
    private alertyfy:AlertyfyService,
    private router:Router,
    private spinningManager: SpinnerManagerService
    ) {
    this.spid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {
    this.createSystemPermissionForm();
    this.Edit();
  }
  public createSystemPermissionForm(){
    this.SystemPermissoinForm=this.fb.group({
     id:[0],
      name:['',Validators.required],
      header:[''],
      description:[''],
      groupID:[0],
      isGroupMaster:[false],
      pagePath:[''],
      headerCode:[''],
      code:['']

    })
    this.spinningManager.hideSpinner();
  }
  onSubmit(){
    var formObj=this.SystemPermissoinForm.value;
    var systemPermission=new SystemPermission(formObj);
    this.systemPermissionService.upsertSystemPermission(systemPermission).subscribe(res=>{
      if(res){
        this.alertyfy.success("Successfully Inserted.");
        this.router.navigate(['/PermissionManagement/list-system-permission']);
      }
       },
    error=>{
       this.alertyfy.error(error);
    });
  }
  Edit(){
  this.systemPermissionService.getSystemPermissionById(this.spid).subscribe(res=>{
    this.SystemPermissoinForm.setValue(res);
    this.spinningManager.hideSpinner();
  },error=>{
    
  })
}

}
