<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Create System Permission</h5>
        </div>
        <div class="card-body">
            <form [formGroup]="SystemPermissoinForm" class="needs-validation" novalidate>
                <input type="hidden" formControlName="id" id="id" />
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4"><span>*</span>Name</label>
                    <div class="col-xl-9 col-md-8">
                        <input class="form-control" required formControlName="name" type="text" id="name" />
                        <span *ngIf="
                !SystemPermissoinForm.controls.name.valid &&
                SystemPermissoinForm.controls.name.touched
              " class="error-block">Please Provide Name</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-xl-3 col-md-4">Header</label>
          <div class="col-xl-9 col-md-8">
            <input
              class="form-control"
              formControlName="header"
              type="text"
              id="header"
            />
            <!-- <span *ngIf="!DataPermissoinForm.controls.name.valid && DataPermissoinForm.controls.name.touched" class="error-block">Please Provide Name</span> -->
                    </div>
                </div>
                <div class="form-group row editor-label">
                    <label class="col-xl-3 col-md-4">Description</label>
                    <div class="col-xl-9 col-md-8">
                        <ck-editor id="description" formControlName="description" cols="30" rows="10" language="en" [fullPage]="false"></ck-editor>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-md-4">Is Group Master</label>
                    <div class="checkbox checkbox-primary col-xl-9 col-md-8" style="padding-left: 15px">
                        <input id="checkbox-primary-2" formControlName="isGroupMaster" type="checkbox" data-original-title="" title="" />
                        <label for="checkbox-primary-2">Enable Group Master</label>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4">Page Path</label>
                    <div class="col-xl-9 col-md-8">
                        <input class="form-control" formControlName="pagePath" type="text" id="pagePath" />
                    </div>
                    <!-- <span *ngIf="!DataPermissoinForm.controls.name.valid && DataPermissoinForm.controls.name.touched" class="error-block">Please Provide Name</span> -->
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4"><span>*</span>Header Code</label
          >
          <div class="col-xl-9 col-md-8">
            <input
              class="form-control"
              required
              formControlName="headerCode"
              type="text"
              id="headerCode"
            />
            <span
              *ngIf="
                !SystemPermissoinForm.controls.headerCode.valid &&
                SystemPermissoinForm.controls.headerCode.touched
              "
              class="error-block"
              >Please Provide Header Code</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-xl-3 col-md-4"><span>*</span>Code</label>
                    <div class="col-xl-9 col-md-9">
                        <input class="form-control" required formControlName="code" type="text" id="code" />
                        <span *ngIf="
                !SystemPermissoinForm.controls.code.valid &&
                SystemPermissoinForm.controls.code.touched
              " class="error-block">Please Provide Code</span
            >
          </div>
        </div>
        <div class="pull-right">
          <button
            [routerLink]="['/PermissionManagement/list-system-permission']"
            type="button"
            class="btn btn-primary"
          >
            Back to List
          </button>
          <button
            [disabled]="!SystemPermissoinForm.valid"
            type="button"
            (click)="onSubmit()"
            class="btn btn-success ml-2"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
