import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PratinidhiSabhaService {

  constructor(private apiService: ApiService) { }
  getAllPratinidhiSabha(){
    return this.apiService.get('PratinidhiSabha/GetAll');
  }
  getPratinidhiSabhaById(id: any){
    return this.apiService.get('PratinidhiSabha/getbyid/?id='+id);
  }
  createPratinidhiSabha(data:any){
    return this.apiService.post('PratinidhiSabha/Create',data)
  }
  deletePratinidhiSabha(id:any){
    return this.apiService.post('PratinidhiSabha/delete/?id='+id);
  }
  updatePratinidhiSabha(data:any){
    return this.apiService.post('PratinidhiSabha/update',data);
  }
  getPratinidhiSabha(query: QueryObject){
    return this.apiService.post('PratinidhiSabha/PratinidhiSabhaItem', query);
  }

  getPratinidhiSabhaByDistrict(id:any){
    return this.apiService.get('PratinidhiSabha/GetPratinidhiSabhaByDistrictId/'+id);
  }
}
