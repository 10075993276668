<form [formGroup]="provinceListForm">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Add Province</h5>
            </div>
            <div class="row card-body tab2-card">
                <div class="col-12">
                    <div class="form-group row">
                        <label for="countryId" class="col-xl-3 col-lg-3 col-md-3 col-md-3"><span>*</span> Country</label>
                        <select placeholder="Select a Country" class="custom-select form-control col-xl-9 col-md-8" formControlName="countryId" required="required">
                            <option value="" disabled>Select Country</option>
                            <option *ngFor="let obj of countryList; let i = index;" [value]='obj.id' type="number">
                                {{ obj.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-xl-3 col-lg-3 col-md-3 col-md-3"><span>*</span>Province</label>
                        <input class="form-control col-xl-9 col-md-8" formControlName="name" name="name" type="text" required="">
                    </div>
                    <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-4" >Description</label>
                       <div class="col-xl-8 col-md-7 p-0 m-0">
                          <textarea rows="4" class="w-100 form-control" formControlName="description" required></textarea>
                       </div>
                    </div>
                    <!-- <div class="form-group row editor-label">
                        <label class="col-xl-3 col-md-4"> Description</label>
                        <div class="col-12 mt-2" >
                           <ck-editor formControlName="description" id="description" language="en" [fullPage]="false"></ck-editor>
                        </div>
                     </div> -->
                    <!-- <div class="form-group row">
                        <label for="name" class="col-xl-3 col-lg-3 col-md-3 col-md-3"><span>*</span>Description</label>
                        <input class="form-control col-xl-9 col-md-8" formControlName="description" name="description" type="text" required="">
                    </div> -->
                    <div class="form-group row">
                        <label for="name" class="col-xl-3 col-lg-3 col-md-3 col-md-3"><span>*</span>Province Code</label>
                        <input class="form-control col-xl-9 col-md-8" formControlName="provinceCode" id="provinceCode"
                          type="text" required="">
                    </div>
                    <div class="pull-right">

                        <button type="button" class="btn btn-primary" [routerLink]="['/managedelevery/province-list']">
                            Discard
                        </button>
                        <button type="button" class="btn btn-success ml-2" [routerLink]="['/managedelevery/province-list']" (click)="saveProvinceList()">
                            {{this.provinceid>0?"Update":"Save"}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
