import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/core/auth.service';

@Component({
  selector: 'app-general-store',
  templateUrl: './general-store.component.html',
  styleUrls: ['./general-store.component.scss']
})
export class GeneralStoreComponent implements OnInit {

  storeId: any;
  storeForm: FormGroup;
  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private authSerice: AuthService
              ) { }

  ngOnInit(): void {
    localStorage.setItem('usertype', 'generaluser');
    this.createSellerCenter();
  }
  logout(){
    // this.oauthService.logOut();
    this.authSerice.logout();
  }

  createSellerCenter(){
    this.storeForm = this.fb.group({
      sellerInformationForm: this.fb.group({
        userDetailId: [null],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        phoneNumber: [null, Validators.required],
        email: [null, Validators.required],
        password:[null, Validators.required],
        id: [null],
        name: [null, Validators.required],
        categoryId: [null, Validators.required],
        pinCode: [null, Validators.required],
        addressLine1: [null, Validators.required],
        addressLine2: [null],
        countryId: [null, Validators.required],
        provinceId: [null, Validators.required],
        districtId: [null, Validators.required],
        cityId: [null, Validators.required],
        googleAddress:[null],
        latitude:[null],
        longitude:[null]
      }),
      taxInformationForm: this.fb.group({
        vatNumberExists: [null, Validators.required],
        companyName: [null, Validators.required],
        panNumber: [null, Validators.required],
        accountHolderName: [null, Validators.required],
        accountNumber: [null, Validators.required],
        bankName: [null, Validators.required],
        branch:  [null, Validators.required],

      }),
      sellerInterviewForm: this.fb.group({
        category: this.fb.array([]),
        productFromQuestion: [],
        productFrom: this.fb.array([]),
        annualTurnOverQuestion: [],
        annualTurnOver: [null],
        numberOfProductQuestion: [],
        numberOfProduct: [null],
        sellOnOtherWebsiteQuestion: [],
        sellOnOtherWebsite: []
      })

    });
}

}
