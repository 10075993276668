import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PratinidhiSabha } from 'src/app/shared/models/pratinidhiSabha';
import { PratinidhiQueryObject } from 'src/app/shared/models/queryObject';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';
import { ProvinceDropDownList } from '../district-list/district-list.component';
import { DistrictDropDown } from '../municipality-list/municipality-list.component';
import { CountryList } from '../province-list/province-list.component';

@Component({
  selector: 'app-pratinidhi-sabha-list',
  templateUrl: './pratinidhi-sabha-list.component.html',
  styleUrls: ['./pratinidhi-sabha-list.component.scss']
})
export class PratinidhiSabhaListComponent implements OnInit {

  showFilters: Boolean = false;
  queryObject = new PratinidhiQueryObject();
  pratinidhiSabhaList: PratinidhiSabha[] =[];
  noDataFound: string;
  pageSize = environment.pageSize;

  columns = [
    // { title: ' ID', key: 'id', isSortable: true },
    { title: ' Pratinidhi Sabha', key: 'pratinidhi', isSortable: true },
    { title: 'Description', key: 'description', isSortable: true },
    { title: 'District', key: 'district', isSortable: true },
    { title: 'Pratinidhi Sabha Code', key: 'pratinidhiSabhaCode', isSortable: true },
    { title: 'Action' }
  ];
  public provinceList: ProvinceDropDownList[]=[];
  public countryList: CountryList[]=[];
  public districtList: DistrictDropDown[]=[];
  public pratinidhiList: DistrictDropDown[]=[];

  constructor(private _router: Router,
    private spinningManager: SpinnerManagerService,
    private pratinidhiService: PratinidhiSabhaService,
    private alertyfy: AlertyfyService,
    private districtService: DistrictService,
    private countryService: CountryService,
    private provinceService: ProvinceService) {
      this.spinningManager.showSpinner("Loading.....");
    }


  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.queryObject.pageSize = 10;
    this.getAllCountries();
    this.Reset();
  }

  getPratinidhiSabha(){
    this.spinningManager.showSpinner('Loading.....');
    this.pratinidhiService.getPratinidhiSabha(this.queryObject).subscribe(
      (res: any) => {
        this.queryObject.pageCount = res.totalItems;
        this.pratinidhiSabhaList = res.items;
        this.spinningManager.hideSpinner();
      //   for (const item of res.items) {
      //     const pratinidhiItem = new PratinidhiSabha();
      //     pratinidhiItem.id = item.id;
      //     pratinidhiItem.name = item.name;
      //     pratinidhiItem.description = item.description;
      //     pratinidhiItem.districtId = item.districtId;
      //     pratinidhiItem.pratinidhiSabhaCode = item.pratinidhiSabhaCode;
      //     pratinidhiItem.districtName = item.districtName;
      //     this.pratinidhiSabhaList.push(pratinidhiItem);
      //   }
      // },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      }
      });
  }

  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/add-pratinidhi-sabha');
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;

  }

  public Search(){
    this.getPratinidhiSabha();
  }

  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {
    })
  }
  getProvinces() {
    this.provinceService.getAllProvince()
      .subscribe(res => {
        this.provinceList = res;
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      });
  }

  getAllDistrictsByProvinceId(provinceId) {
    this.districtService.getDistrictByProvince(provinceId)
      .subscribe(res => {
        this.districtList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }

  getAllPratinidhiSabhaByDistrictId(districtId) {
    this.pratinidhiService.getPratinidhiSabhaByDistrict(districtId)
      .subscribe(res => {
        this.pratinidhiList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }



  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.districtId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.getPratinidhiSabha();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm
      (
        "Are you sure you want to delete?",
        () => {
          this.pratinidhiService.deletePratinidhiSabha(id)
            .subscribe(res => {
              this.alertyfy.success("Deleted Successfully.");
              this.getPratinidhiSabha();
            },
              (error) => {
                this.alertyfy.error("Something went wrong. Delete Failed.");
              });
        }
      );
  }

  public sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    // this.getMunicipalities();
  }

  public onPageChange(page) {
    this.queryObject.page = page;
    this.getPratinidhiSabha();
  }

  public exportToExcel(flag: boolean) {

    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.xlsx');
    // });
  }
  public exportToPdf(flag: boolean) {
    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.pdf');
    // });
  }

}
