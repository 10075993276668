import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    request = this.addToken(request, encodeURIComponent(token));
    return next.handle(request);
  }


  private addToken(request: HttpRequest<any>, token: string) {
    if (token) {
      return request.clone(
        {
          setHeaders: { Authorization: `Bearer ${token}` }
        });
    } else {
      return request;
    }
  }
}

// Not used