import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../api.service';
@Injectable({
  providedIn: 'root'
})
export class UserRegistrationfileService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient,private apiService: ApiService) { }
  public getDocumentByUserId(userId:number){
    return this.apiService.get('UserRegistraionFile/GetDocumentByUserId/'+userId);
  }
  public updateUserDocument(obj:any){
    return this.apiService.post('UserRegistraionFile/UpdateUserDocument',obj);
  }
}
