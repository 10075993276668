import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { authConfig } from '../../../sso.config';
import { UserDetailsService } from '../../service/userDetails/userDetails.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  public userdetails: any = [{}];
  baseUrlForImage = environment.imageUrl;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService, public userdetailsService: UserDetailsService,
    private authService: AuthService, private router: Router) {
    //this.configure();


  }

  private configure() {
    // this.oauthService.configure(authConfig);
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // //this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/sign-in']);
    // this.oauthService.logOut();
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  getUserDetails() {
    if(localStorage.getItem('userDetails')!=null){
      this.userdetails = JSON.parse(localStorage.getItem('userDetails'));
    }else{
      this.userdetailsService.getUserDetailsById().subscribe(res => {
        this.userdetails = res;
        localStorage.setItem('userDetails', JSON.stringify(this.userdetails));
      }, error => {
        
      });
    }
  }

  ngOnInit() {
    this.getUserDetails();
  }

}
