import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IActionMapping, ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { UserDetailsService } from 'src/app/shared/service/userDetails/userDetails.service';
import { ActionhistoryService } from '../../../shared/service/actionhistory/actionhistory.service';
import { ActionHistoryModule, ActionHistoryType } from "../../../shared/enums/ActionHistoryModule";
import { RolesService } from 'src/app/shared/service/roles/roles.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { UserRegistrationfileService } from '../../../shared/service/userRegistrationFile/user-registrationfile.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  public rolesArrary: any;
  public selectedRole = [];
  public storeList = [];
  public userId: any = 0;
  checkArray: FormArray;
  showStoreDropdown: boolean = false;
  public rlsId: number;
  public systemPermissionnodeItems = [{ "id": 0, "name": "System Permission", "children": [] }];
  public dataPermissionnodeItems = [{ "id": 0, "name": "Data Permission", "children": [] }];
  @ViewChild('actionHistory') actionHistoryChild: any;

  @ViewChild(TreeComponent)
  private treesystempermission: TreeComponent;


  @ViewChild(TreeComponent)
  private treedatapermission: TreeComponent;
  // actionMapping: IActionMapping = {
  //   mouse: {
  //     click: (tree, node) => this.check(node, !node.data.hasRole)
  //   }
  // };


  public ActionHistoryModuleList = ActionHistoryModule;

  constructor(private alertyfy: AlertyfyService,
    private rolesService: RolesService,
    private userDetails: UserDetailsService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private actionhistoryService: ActionhistoryService,
    private route: ActivatedRoute,
    private spinningManager: SpinnerManagerService,
    private userRegistrationFileServie: UserRegistrationfileService,
  ) {
    this.spinningManager.showSpinner("Loading.....");
    this.createAccountForm();
    this.createPermissionForm();
    this.getRoles();

    this.rlsId =
      // tslint:disable-next-line:radix
      this.route.snapshot.params.id === undefined ? 0 : parseInt(this.route.snapshot.params.id);
  }
  // columns = [
  //   { title: "Id", key: "Id", isSortable: true },
  //   { title: "FileTye", key: "FileTye", isSortable: true },
  //   { title: "FileImage", key: "FileImage", isSortable: true },
  //   { title: "VerifiedBy", key: "VerifiedBy", isSortable: true },
  //   { title: "VerifiedOn", key: "VerifiedOn", isSortable: true },
  //   { title: "CreatedOn", key: "CreatedOn", isSortable: true },
  //   { title: "Comment", key: "Comment", isSortable: true },


  // ];

  ngOnInit() {
    this.getSystemPermission();
    this.getDataPermission();

    this.checkArray = this.accountForm.get('roles') as FormArray;
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('id');
      if (this.userId != undefined && this.userId != null && this.userId > 0) {
        this.userDetails.getUserDetailsWithRoles(this.userId).subscribe((x: any) => {
          x.roleList.forEach(roleId => {
            this.checkArray.push(new FormControl(roleId));
            this.selectedRole.push(roleId);
          });
          this.accountForm.patchValue({
            id: x.id,
            firstName: x.firstName,
            lastName: x.lastName,
            email: x.email,
            phone: x.phoneNumber,
            rolesIds: this.selectedRole.length.toString(),
            storeId: x.storeId > 0 ? x.storeId : ''
          });

          this.accountForm.get('email').disable({ onlySelf: true });
          if(this.rolesArrary){
            this.showHideStoreDropDown();
          }
        });
      } else {
        this.userId = 0;
      }
    });
  }

  handleEventSystem(tree: TreeModel) {
    const obj = {
      "userDetailsId": this.rlsId,
      "SystemPermissionIds": []
    }
    this.systemPermissionnodeItems.forEach(x => {
      x.children.forEach(item => {
        if (item.hasRole) {
          obj.SystemPermissionIds.push(item.id)

        }
      })
    })

    this.userDetails.createUserSystemPermission(obj).subscribe(res => {
      this.alertyfy.success('Saved successfully.');
      this.spinningManager.hideSpinner();
    }, err => {
      this.alertyfy.error(err.error);
      this.spinningManager.hideSpinner();
    });
  }

  handleEventData(tree: TreeModel) {
    const obj = {
      "userDetailsId": this.rlsId,
      "DataPermissionIds": []
    }
    this.dataPermissionnodeItems.forEach(x => {
      x.children.forEach(item => {
        if (item.hasRole) {
          obj.DataPermissionIds.push(item.id);
        }
      })
    })

    this.userDetails.createUserDataPermission(obj).subscribe(res => {
      this.alertyfy.success('Saved successfully.');
    }, err => {
      this.alertyfy.error(err.error);
    });
  }

  getSystemPermission() {

    this.userDetails.getSystemPermission(this.rlsId)
      .subscribe(res => {
        for(var i=0;i<res.length;i++){
          if(res[i].children.filter(a=> {return a.hasRole==false;}).length==0){
              res[i]["hasRole"]=true;
          }
          const checkedItems = res[i].children.filter(a=> {return a.hasRole==true;}).length;
          if(checkedItems!=0 && res[i].children.length != checkedItems){
            res[i]["indeterminateSystem"]=true;
          }
        }
        this.systemPermissionnodeItems = res;
        if(this.treesystempermission){
          this.treesystempermission.treeModel.update();
        }
      },

        error => {
          if (error) {
            this.alertyfy.error(error);
          } else {
            this.alertyfy.error("Cannot reach Server.");
          }
        });


  }

  public check(node, hasRole) {
    this.updateChildNodeCheckboxSystem(node, hasRole);
    this.updateParentNodeCheckboxSystem(node.realParent);
    this.updateChildNodeCheckboxData(node, hasRole);
    this.updateParentNodeCheckboxData(node.realParent);
  }

  public updateChildNodeCheckboxSystem(node, hasRole) {
    node.data.hasRole = hasRole;
    node.data.indeterminateSystem = false;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckboxSystem(child, hasRole));
    }
  }
  public updateParentNodeCheckboxSystem(node) {
    if (!node) {
      return;
    }

    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.hasRole || child.data.indeterminateSystem) {
        allChildrenChecked = false;
      }
      if (child.data.hasRole) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.hasRole = true;
      node.data.indeterminateSystem = false;
    } else if (noChildChecked) {
      node.data.hasRole = false;
      node.data.indeterminateSystem = false;
    } else {
      node.data.hasRole = true;
      node.data.indeterminateSystem = true;
    }
    this.updateParentNodeCheckboxData(node.parent);
  }
  public updateChildNodeCheckboxData(node, hasRole) {
    node.data.hasRole = hasRole;
    node.data.indeterminateData = false;
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckboxData(child, hasRole));
    }
  }
  public updateParentNodeCheckboxData(node) {
    if (!node) {
      return;
    }

    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.hasRole || child.data.indeterminateData) {
        allChildrenChecked = false;
      }
      if (child.data.hasRole) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.hasRole = true;
      node.data.indeterminateData = false;
    } else if (noChildChecked) {
      node.data.hasRole = false;
      node.data.indeterminateData = false;
    } else {
      node.data.hasRole = true;
      node.data.indeterminateData = true;
    }
    this.updateParentNodeCheckboxData(node.parent);
  }

  getDataPermission() {

    this.userDetails.getDataPermission(this.rlsId)
      .subscribe(res => {
        for(var i=0;i<res.length;i++){
          if(res[i].children.filter(a=> {return a.hasRole==false;}).length==0){
              res[i]["hasRole"]=true;
          }
          const checkedItems = res[i].children.filter(a=> {return a.hasRole==true;}).length;
          if(checkedItems!=0 && res[i].children.length != checkedItems){
            res[i]["indeterminateData"]=true;
          }
        }
        this.dataPermissionnodeItems = res;
        if(this.treedatapermission!=null){
          this.treedatapermission.treeModel.update();
        }
      },

        error => {
          if (error) {
            // this.alertyfy.error(error);
          } else {
            //this.alertyfy.error("Cannot reach Server.");
          }
        });


  }

  checkRoleExist(role) {
    let check = this.selectedRole.indexOf(role) > -1;
    return check;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      id: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required, this.userNameValidator(), Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")],
      phone: [''],
      // password: [''],
      // confirmPwd: [''],
      roles: this.formBuilder.array([], Validators.minLength(1)),
      rolesIds: ['', Validators.required],
      storeId: [''],
    });
    this.spinningManager.hideSpinner();
  }

  onCheckboxChange(e) {
    //const checkArray = this.accountForm.get('roles') as FormArray;
    if (e.target.checked) {
      this.checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      this.checkArray.controls.forEach((item: FormControl) => {
        if (item.value.toString() === e.target.value.toString()) {
          this.checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.accountForm.controls.rolesIds.setValue(this.checkArray.length > 0 ? this.checkArray.length.toString() : "");
    this.showHideStoreDropDown();
  }

  showHideStoreDropDown() {
    var selectedValues = this.checkArray.value;
    if (selectedValues != null && selectedValues.length > 0) {
      this.showStoreDropdown = false;
      for (var count = 0; count < selectedValues.length; count++) {
        var filteredRole = this.rolesArrary.filter((item, index) => {
          var filterRoleItem = item.roles.filter((itemInner, indexInner) => {
            if (itemInner.id == Number(selectedValues[count]) && itemInner.showStoreDropDown) {
              this.showStoreDropdown = true;
            }
          })
        });
      }
    } else {
      this.showStoreDropdown = false;
    }
    if (this.showStoreDropdown == false) {
      this.accountForm.controls.storeId.setValue('');
    }
  }

  roleIsDisabled(item, groupId) {
    var selectedRoles = this.checkArray.value;
    if (selectedRoles.length > 0) {
      var filtered = selectedRoles.filter((itemInner, index) => {
        return itemInner == item.id;
      })
      if ((filtered != null && filtered.length > 0) || (item.isUniqueRole == false && this.isInSameGroup(item, groupId))) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isInSameGroup(itemSingle, groupId) {
    var isInSameGroup = false;
    var selectedValues = this.checkArray.value;
    for (var count = 0; count < selectedValues.length; count++) {
      var filteredRole = this.rolesArrary.filter((item, index) => {
        var currentGroupId = item.groupId;
        var filterRoleItem = item.roles.filter((itemInner, indexInner) => {
          if (itemInner.id == Number(selectedValues[count]) && currentGroupId == groupId) {
            isInSameGroup = true;
          }
        })
      });
    }

    return isInSameGroup;
  }

  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  getRoles() {
    this.userDetails.getRoles().subscribe(x => {
      this.rolesArrary = x;
      if(this.selectedRole.length > 0){
        this.showHideStoreDropDown();
      }
    });
  }

  onSubmit() {
    if (this.accountForm.valid) {
      let fb = this.accountForm.value;
      let user: any = {};
      user.accountId = fb.id === '' ? 0 : fb.id;
      user.firstName = fb.firstName;
      user.lastName = fb.lastName;
      user.email = fb.email;
      user.phoneNumber = fb.phone;
      user.roleId = fb.roles;
      user.storeId = fb.storeId;

      this.userDetails.registerUser(user).subscribe(x => {
        this.spinningManager.hideSpinner();
        if (this.userId > 0) {
          this.actionhistoryService.create('User updated', ActionHistoryType.UPDATE, ActionHistoryModule.USER, this.userId).subscribe(result => {
            this.actionHistoryChild.refreshData();
          });
        } else {
          this.actionhistoryService.create('User created', ActionHistoryType.CREATE, ActionHistoryModule.USER, Number(x)).subscribe(result => {
            this.actionHistoryChild.refreshData();
          });
        }
        this.alertyfy.success('User saved successfully.');
        this._router.navigate(['/users/list-user']);
      });
    } else {
      this.accountForm.markAllAsTouched();
    }
  }

  userNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userDetails.checkDuplicateEmailAddress(control.value).pipe(
        map(res => {
          // if res is true, username exists, return true
          return res ? { userNamExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  treeOptions: ITreeOptions = {
    //actionMapping: this.actionMapping,
    displayField: 'name',
    idField: 'id'
  }
}

