<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Account">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" class="needs-validation user-add" novalida (ngSubmit)="onSubmit()">
                                        <h4>Account Details</h4>
                                        <input type="hidden" formControlName="id">
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>First Name</label>
                                            <div class="col-xl-9 col-md-9">
                                                <input class="form-control " id="validationCustom0" formControlName="firstName" type="text" required="">
                                                <span *ngIf="!accountForm.controls.firstName.valid && accountForm.controls.firstName.touched" class="error-block">First Name is required.</span>
                                            </div>

                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span>Last Name</label>
                                            <div class="col-xl-9 col-md-9">
                                                <input class="form-control " id="validationCustom1" formControlName="lastName" type="text" required="">
                                                <span *ngIf="!accountForm.controls.lastName.valid && accountForm.controls.lastName.touched" class="error-block">Last Name is required.</span>
                                            </div>

                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom2" class="col-xl-3 col-md-4"><span>*</span>Email</label>
                                            <div class="col-xl-9 col-md-9">
                                                <input class="form-control" id="validationCustom2" formControlName="email" type="email" required="" [email]="true">
                                                <span *ngIf="accountForm.controls.email.errors?.required && accountForm.controls.email.touched" class="error-block">Email is required.</span>
                                                <span *ngIf="accountForm.controls.email.errors?.userNamExists && accountForm.controls.email.touched" class="error-block">Email is already registered.</span>
                                                <span *ngIf="accountForm.controls.email.errors?.email && accountForm.controls.email.touched" class="error-block">Invalid email address.</span>
                                            </div>

                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span> Phone Number</label>
                                            <div class="col-xl-9 col-md-9">
                                                <input class="form-control" id="validationCustom1" formControlName="phone" type="text" required="">
                                                <span *ngIf="!accountForm.controls.phone.valid && accountForm.controls.phone.touched" class="error-block">Phone number is required.</span>
                                            </div>

                                        </div>
                                        <!-- <div class="form-group row">
                                            <label for="validationCustom3" class="col-xl-3 col-md-4"><span>*</span>
                                                Password</label>
                                            <input class="form-control col-xl-9 col-md-9" id="validationCustom3"
                                                type="password" required=""  formControlName="password">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Confirm Password</label>
                                            <input class="form-control col-xl-9 col-md-9" id="validationCustom4"
                                                type="password" required=""  formControlName="confirmPwd">
                                        </div> -->

                                        <div class="form-group row">
                                            <label class="col-xl-3 col-md-3"><span>*</span>Roles</label>
                                            <div class="col-xl-9 col-md-9 ">
                                                <div *ngFor="let itemTop of this.rolesArrary" class="row">
                                                    <div class="col-xl-12" *ngFor="let item of itemTop.roles;">
                                                        <ng-container *ngIf="this.selectedRole.indexOf(item.id)!==-1; else elseBlock">
                                                            <input type="checkbox" [value]="item.id" (change)="onCheckboxChange($event)" checked [disabled]="roleIsDisabled(item, itemTop.groupId)"> {{item.displayName }}
                                                        </ng-container>
                                                        <ng-template #elseBlock>
                                                            <input type="checkbox" [value]="item.id" (change)="onCheckboxChange($event)" [disabled]="roleIsDisabled(item, itemTop.groupId)"> {{item.displayName }}
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <span *ngIf="!accountForm.controls.rolesIds.valid" class="error-block">Select at least one role.</span>
                                            </div>

                                        </div>
<!--
                                        <div class="form-group row" *ngIf="showStoreDropdown">
                                            <label for="storeId" class="col-xl-3 col-md-4"><span>*</span>Store</label>
                                            <div class="col-xl-9 col-md-9">
                                                <select class="form-control" formControlName="storeId">
                                                    <option value="">-- Select --</option>
                                                    <option *ngFor="let item of storeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <span *ngIf="!accountForm.controls.firstName.valid && accountForm.controls.firstName.touched" class="error-block">First Name is required.</span>
                                            </div>

                                        </div> -->
                                        <div class="pull-right">
                                            <button type="submit" class="btn btn-success">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <!-- <ngb-tab title="System Permissoin" *ngIf="this.rlsId!=0">
                            <ng-template ngbTabContent>
                              <div class="mt-3">
                                <tree-root #treesystempermission [nodes]="systemPermissionnodeItems" [options]="treeOptions"
                                  [focused]="true">
                                  #region added to bind tree-structure
                                  <ng-template #treeNodeTemplate let-node="node" let-index="index">
                                    <input (change)="check(node, !node.data.hasRole)" type="checkbox"
                                      [indeterminate]="node.data.indeterminateSystem" [checked]="node.data.hasRole"> {{ node.data.name }}
                                  </ng-template>
                                  #endregion
                                </tree-root>
                              </div>
                              <button class="btn btn-primary d-block mx-auto" type="submit"
                                (click)="handleEventSystem(treesystempermission.treeModel)">Save</button>
                            </ng-template>

                          </ngb-tab> -->

                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle *ngIf="this.rlsId!=0">Data Permission</ng-template>
                            <ng-template ngbTabContent>
                              <div class="mt-3">
                                <tree-root #treedatapermission [nodes]="dataPermissionnodeItems" [options]="treeOptions">
                                  #region added to bind tree structure
                                  <ng-template #treeNodeTemplate let-node="node" let-index="index">
                                    <input (change)="check(node, !node.data.hasRole)" type="checkbox"
                                      [indeterminate]="node.data.indeterminateData" [checked]="node.data.hasRole"> {{ node.data.name }}
                                  </ng-template>
                                  #endregion
                                </tree-root>
                              </div>
                              <button class="btn btn-primary d-block mx-auto" type="submit"
                                (click)="handleEventData(treedatapermission.treeModel)">Save</button>
                            </ng-template>

                          </ngb-tab> -->
                        <ngb-tab>
                            <ng-template ngbTabTitle>Documents</ng-template>
                            <ng-template ngbTabContent>
                                <app-user-document [userID]='userId'></app-user-document>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-action-history #actionHistory [code]="ActionHistoryModuleList.USER" [codeId]="userId > 0 ? userId : 0"></app-action-history> -->
<!-- Container-fluid Ends-->
