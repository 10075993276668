import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAddDecimal]'
})
export class AddDecimalDirective {

  constructor(private el:ElementRef) { }


  @HostListener('input', ['$event.target.value'])
  onInput(value:string){
    const integerPattern = /^\d+$/;
   if(integerPattern.test(value)){
    this.el.nativeElement.value = value + '.00'
   }
  }

}
