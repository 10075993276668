export enum systemPermissions{
   //Manage Delivery
   Provinces = 8,
   Countries = 7,
   ManageDelivery = 6,
   Districts = 9,
   Muncipalites = 10,
   Cities = 11,
   Regions = 12,

   //Inventory
   Inventory = 13,
   Inventory_Add = 14,
   Inventory_List = 15,
   Inventory_Products = 20,

   //Newsletter
   Newsletter = 16,
   Newsletter_Add = 17,
   Newsletter_List = 18,
   Newsletter_Subscribers = 19,

   //Slider
   Slider = 21,
   Slider_Carousel_List_Homepage = 22,
   Slider_Carousel_List_Affiliate = 23,

   //Category
   Category = 24,
   Category_List = 25,
   Marketplace_Fee = 26,
   Master_Property = 27,
   Create_Category = 28,
   Create_Master_List = 29,
   Create_Master_Property = 30,
   Master_List = 31,

   //Offers
   Offers = 32,
   Offers_Detail = 33,
   Create_Offer = 34,

   //Product
   Product = 35,
   Product_List = 36,
   Product_Create = 37,
   Product_RatingReview = 38,

   //Administration
   Administration = 39,
   Menu_Category = 42,
   Settings = 43,
   Roles = 44,
   Ware_House = 45,

   //Page Management
   Page_Category = 40,
   Page = 41,
   LLPage=84,

   //RewardType
   Reward_Type = 46,
   RewardType_List=86,
   RewardType_Create=86,

   //Email Management
   EmailManagement = 47,
   EmailTemplate = 48,
   EmailQueue = 49,
   EmailLog = 50,

   //Brand
   Brand = 51,
   BrandList = 52,
   BrandCreate = 53,

   //Manage Shipping
   ManageShipping = 54,
   DeliveryCompanies = 55,
   ShippingCostVariation = 56,

   //Manage Affiliate
   ManageAffiliate = 57,
   AddAffiliate = 58,

   //Home Page Settings
   ClientHome = 59,
   TopRankingList = 60,
   TopSelectionList = 61,
   FlashDealList = 62,
   NewArrivalList = 63,
   MoreToLove = 64,
   FeatureBrandList = 65,
   FeatureCategoryList = 66,
   FeatureStoreList = 67,
   SliderRightList = 68,
   SliderBottomList = 69,

   //Dashboard
   Dashboard = 70,
   //Sales
   Sales = 71,
   RunningOrders = 72,
   Orders = 73,
   Transactions = 74,

   //Store
   Store = 75,
   StoreCreate = 76,
   StoreList = 77,

   //Push Notification
   PushNotification = 78,
   GeneralReminder = 79,
   CreateGeneralReminder = 80,

   //Permission Management
   PermissionManagement = 81,
   DataPermission = 82,
   SystemPermission = 83,
   
   //User management
   Users=88,
   User_List=89,
   Create_User=90
}