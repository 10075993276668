import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorWorkHistroyQueryObject } from 'src/app/shared/models/queryObject';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';
import { Doctor } from 'src/app/shared/models/doctor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpsertDoctorWorkHistoryComponentComponent } from '../upsert-doctor-work-history-component/upsert-doctor-work-history-component.component';
import { DoctorWorkHistoryService } from 'src/app/shared/service/ManageDelivery/doctor-work-history.service';

@Component({
  selector: 'app-list-doctor-work-history-component',
  templateUrl: './list-doctor-work-history-component.component.html',
  styleUrls: ['./list-doctor-work-history-component.component.scss']
})
export class ListDoctorWorkHistoryComponentComponent implements OnInit {

  queryObject = new DoctorWorkHistroyQueryObject();
  showFilters: Boolean = false;
  noDataFound: string;
  pageSize = environment.pageSize;
  memberName: any;
  @Input() doctorid: number = 0;

  columns = [
    { title: 'Action' },
    { title: 'Hospital Name', key: 'hospitalName', isSortable: true },
    { title: 'Hospital Address', key: 'hospitalAddress', isSortable: true },
    { title: 'Hospital ContactNo', key: 'hospitalContactNo', isSortable: true },
    { title: 'Working Hours', key: 'workingHours', isSortable: true },
    { title: 'StartDate', key: 'startDate', isSortable: true },
    { title: 'EndDate', key: 'endDate', isSortable: true },
    { title: 'SalaryPerMonth', key: 'salaryPerMonth', isSortable: true },
    { title: 'Designation', key: 'designation', isSortable: true },
    { title: 'DoctorId', key: 'doctorId', isSortable: true },
    { title: 'IsPartTime', key: 'isPartTime', isSortable: true },
    { title: 'IsPrivate', key: 'isPrivate', isSortable: true },
    
    
  ];
  public List: Doctor[] = [];
  

  constructor(
    private _router: Router,
    private _service: DoctorWorkHistoryService ,
    private spinningManager: SpinnerManagerService,
    private alertyfy: AlertyfyService,
    private modalService: NgbModal

  ) {
  }

  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.queryObject.doctorId = this.doctorid;
    this.search();

  }
  private getNgbFormatDate(_date_) {
    var result = _date_.split("-");
    var ngbDate = {
      year: Number(result[0]),
      month: Number(result[1]),
      day: Number(result[2]),
    };
    return ngbDate;
  }

  search() {
    this.spinningManager.showSpinner('Loading.....');
    this._service.search(this.queryObject).subscribe(
      (res: any) => {
        this.List = res.items;
        this.queryObject.pageCount = res.totalItems;
        this.spinningManager.hideSpinner();

      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      }
    );
    this.spinningManager.hideSpinner();

  }


  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this._service.delete(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.search();
          },
            (error) => {
              this.alertyfy.error(error);
            });
      });
  }

 

  // showHideFilter() {
  //   this.showFilters = !this.showFilters;
  // }

  // public Search() {
  //   this.search();
  // }

  // public Reset() {
  //   this.queryObject.isSortAsc = true;
  //   this.queryObject.sortBy = '';
  //   this.queryObject.searchString = '';
  //   this.queryObject.page = 1;
  //   this.queryObject.pageSize = this.pageSize;
  //   this.search();
  // }

  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.search();
  }

  onPageChange(page) {
    this.queryObject.page = page;
    this.search();
  }
  createDataPermisionPopUp(doctorworkhistoryId: number, doctorId: number) {
      const modalRef = this.modalService.open(UpsertDoctorWorkHistoryComponentComponent, {
        size: <any>"lg",
      });
      modalRef.componentInstance.doctorId = doctorId;
      modalRef.componentInstance.doctorworkhistoryId = doctorworkhistoryId;

      modalRef.result
        .then((emitted) => {
          if (emitted.isSavePressed) {
            // this.productIdValueArray = emitted.productIdValueArray.filter(function (item) {
            //   return item.status == true;
            // });
            //this.processSelectedProduct();
            this.search();
          }
        })
        .catch((reason) => {});
    }
  

}
