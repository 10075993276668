import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(private apiService:ApiService) { }

getAllPrograms(){
  return this.apiService.get('Program/GetAll');
}
getProgramById(id: any){
  return this.apiService.get('Program/getById?id='+id);
}
createProgram(data:any){
  return this.apiService.post('Program/Create',data)
}
updateProgram(data:any){
  return this.apiService.post('Program/update',data);
}
deleteProgram(id:any){
  return this.apiService.post('Program/delete?id='+id);
}
// UpsertCountry(data){
//     return this.apiService.post('Country/UpsertCountry',data);
// }
// getCountry(query: QueryObject){
//   return this.apiService.post('Country/CountryItem', query);
// }
// public exportAllItemsToExcel(query:QueryObject){
//   return this.apiService.downloadFile('Country/ExportToExcel',query)
// }
// public exportAllItemsToPDF(query:QueryObject){
//   return this.apiService.downloadFile('Country/ExportToPdf',query);
// }
}