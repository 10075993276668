export class Doctor {
    id: number;
    nmcNumber : string;
    nmcRegistrationDate : Date;
    isPermanent : boolean;
    designation : string;
    totalExperience : string;
    currentHospitalAddress: string;
    currentHospitalContactNo : string;
    isPrivateHospital : boolean;
    imagepath: string;
    firstName: string;
    lastName: string;
    fullName: string;
    sex: string;
    dateOfBirth: string;
    mobile: string;
    phone: string;
    job: string;
    countryName: string;
    countryId: number;
    //programsId: number;
    programsName: string;
    provinceName: string;
    provinceId: number;
    districtName: string;
    districtId: number;
    municipalitiesId: number;
    municipalitiesName: number;
    wardNo: string;
    pratinidhiSabhaName: string;
    pratinidhiSabhaId: number;
    pradeshSabhaName: string;
    pradeshSabhaId: number;
    chetraName: string;
    chetraId: number;
    program : string;
    affiliatedUniversity : string;
    collegeName : string;
    passedCountry : string;
    passedDate : Date;
    financialScheme: string;
    email : string;
    bloodGroup: string;
    ndaNumber: string;
    registedOnBranchId: number;
    passedCountryId: number;
    qualification : string;
  }
  