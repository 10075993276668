import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorQueryObject } from 'src/app/shared/models/queryObject';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';
import { ProvinceDropDownList } from '../district-list/district-list.component';
import { DistrictDropDown } from '../municipality-list/municipality-list.component';
import { CountryList } from '../province-list/province-list.component';
import { saveAs as importedSaveAs } from "file-saver";
import { Doctor } from 'src/app/shared/models/doctor';
import { DoctorService } from 'src/app/shared/service/ManageDelivery/doctor.service';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit {

  queryObject = new DoctorQueryObject();
  showFilters: Boolean = false;
  noDataFound: string;
  registedBranchList:any[];
  pageSize = environment.pageSize;
  memberName: any;

  columns = [
    { title: 'Action' },
    //{ title: 'ProgramsName', key: 'ProgramsName', isSortable: true },
    { title: 'Id', key: 'Id', isSortable: true },
    { title: 'Full Name', key: 'FullName', isSortable: true },
    { title: 'Sex', key: 'Sex', isSortable: true },
    { title: 'DOB', key: 'DateOfBirth', isSortable: true },
    { title: 'Mobile No.', key: 'Mobile', isSortable: true },
    { title: 'Phone No.', key: 'Phone', isSortable: true },
    { title: 'Job', key: 'Job', isSortable: true },
    { title: 'Country', key: 'CountryName', isSortable: true },
    { title: 'Province', key: 'ProvinceName', isSortable: true },
    { title: 'District', key: 'DistrictName', isSortable: true },
    { title: 'Municipality', key: 'MunicipalitiesName', isSortable: true },
    { title: 'Pratinidhi', key: 'PratinidhiSabhaName', isSortable: true },
    { title: 'Pradesh', key: 'PradeshSabhaName', isSortable: true },
    { title: 'Ward No.', key: 'WardNo', isSortable: true },
    { title: 'NMC Number', key: 'nmcNumber', isSortable: true },
    { title: 'NMC Registration Date', key: 'nmcRegistrationDate', isSortable: true },
    { title: 'Is Permanent', key: 'isPermanent', isSortable: true },
    { title: 'Designation', key: 'designation', isSortable: true },
    { title: 'Total Experience', key: 'totalExperience', isSortable: true },
    { title: 'Current Hospital Address', key: 'currentHospitalAddress', isSortable: true },
    { title: 'Current Hospital ContactNo', key: 'currentHospitalContactNo', isSortable: true },
    { title: 'Is Private Hospital', key: 'isPrivateHospital', isSortable: true },
    { title: 'Qualification', key: 'qualification', isSortable: true },
    { title: 'Affiliated University', key: 'affiliatedUniversity', isSortable: true },
    { title: 'College', key: 'collegeName', isSortable: true },
    //{ title: 'passedCountryName', key: 'passedCountryName', isSortable: true },
    { title: 'Passed Date', key: 'passedDate', isSortable: true },
    { title: 'Financial Scheme', key: 'financialScheme', isSortable: true },
    { title: 'Email', key: 'email', isSortable: true },
    { title: 'Blood Group', key: 'bloodGroup', isSortable: true },
    { title: 'NDA Number', key: 'ndaNumber', isSortable: true },
  ];
  public doctorList: Doctor[] = [];
  public provinceList: ProvinceDropDownList[]=[];
  public countryList: CountryList[]=[];
  public districtList: DistrictDropDown[]=[];
  public pratinidhiList: DistrictDropDown[]=[];
  public pradeshList: DistrictDropDown[]=[];

  constructor(
    private _router: Router,
    private doctorService: DoctorService,
    private spinningManager: SpinnerManagerService,
    private alertyfy: AlertyfyService,
    private provinceService: ProvinceService,
    private countryService: CountryService,
    private districtService: DistrictService,
    private pratinidhiService:PratinidhiSabhaService,
    private pradeshService:PradeshSabhaService
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 8;
    // this.getProvinces();
    // this.getAllCountries();
    this.getRegistedBranch();
    this.getAllPradeshSabha();
    // this.getDoctor();
    this.Reset();

  }
  // private getNgbFormatDate(_date_) {
  //   var result = _date_.split("-");
  //   var ngbDate = {
  //     year: Number(result[0]),
  //     month: Number(result[1]),
  //     day: Number(result[2]),
  //   };
  //   return ngbDate;
  // }

  getDoctor() {
    this.spinningManager.showSpinner('Loading.....');
    this.doctorService.getMemberItems(this.queryObject).subscribe(
      (res: any) => {
        this.doctorList = [];
        this.queryObject.pageCount = res.totalItems;
        this.spinningManager.hideSpinner();
        for (const item of res.items) {
          const doctorItem = new Doctor();
          doctorItem.id = item.id;
          doctorItem.firstName = item.firstName;
          doctorItem.lastName = item.lastName;
          doctorItem.fullName = item.fullName;
          doctorItem.sex = item.sex;
          doctorItem.dateOfBirth = item.dateOfBirth;
          doctorItem.mobile = item.mobile;
          doctorItem.phone = item.phone;
          doctorItem.job = item.job;
          doctorItem.countryId = item.countryId;
          doctorItem.countryName = item.countryName;
          doctorItem.provinceId = item.provinceId;
          doctorItem.provinceName = item.provinceName;
          doctorItem.districtId = item.districtId;
          doctorItem.districtName = item.districtName;
          doctorItem.municipalitiesId = item.municipalitiesId;
          doctorItem.municipalitiesName = item.municipalitiesName;
          doctorItem.wardNo = item.wardNo;
          doctorItem.pratinidhiSabhaId = item.pratinidhiSabhaId;
          doctorItem.pratinidhiSabhaName = item.pratinidhiSabhaName;
          doctorItem.pradeshSabhaId = item.pradeshSabhaId;
          doctorItem.pradeshSabhaName = item.pradeshSabhaName;
          //doctorItem.programsId = item.programsId;
          // doctorItem.programsName = item.programsName;
          doctorItem.qualification = item.qualification;
          
          doctorItem.nmcNumber = item.nmcNumber;
          doctorItem.nmcRegistrationDate = item.nmcRegistrationDate;
          doctorItem.isPermanent = item.isPermanent;
          doctorItem.designation = item.designation;
          doctorItem.totalExperience = item.totalExperience;
          doctorItem.currentHospitalAddress = item.currentHospitalAddress;
          doctorItem.currentHospitalContactNo = item.currentHospitalContactNo;
          doctorItem.isPrivateHospital = item.isPrivateHospital;
          doctorItem.affiliatedUniversity = item.affiliatedUniversity;
          doctorItem.collegeName = item.collegeName;
          //doctorItem.passedCountryName = item.passedCountryName;
          doctorItem.passedDate = item.passedDate;
          doctorItem.financialScheme = item.financialScheme;
          doctorItem.email = item.email;
          doctorItem.bloodGroup = item.bloodGroup;
          doctorItem.ndaNumber = item.ndaNumber;

          // doctorItem.chetraId = item.chetraId;
          // doctorItem.chetraName = item.chetraName;
          this.doctorList.push(doctorItem);
          
        }
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      }
    );
  }

  loadMember(){
    this.getDoctor();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.doctorService.deleteMember(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getDoctor();
          },
            (error) => {
              this.alertyfy.error(error);
            });
      });
  }

  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/create-doctor');
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;
  }

  public Search() {
    this.getDoctor();
  }

  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.ndaNumber = '';
    this.queryObject.page = 1;
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.districtId = 0;
    this.queryObject.pradeshSabhaId = 0;
    this.queryObject.registedOnBranchId = 0;
    this.queryObject.qualification = '';
    this.queryObject.chetraId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.loadMember();
  }

  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.loadMember();
  }

  onPageChange(page) {
    this.queryObject.page = page;
    this.loadMember();
  }

  public exportToExcel(flag: boolean) {
    this.spinningManager.showSpinner('Exporting Excel.....');
    this.queryObject.printall = flag;
    this.doctorService
      .exportAllItemsToExcel(this.queryObject)
      .subscribe((res: Blob) => {
        importedSaveAs(res, 'DoctorList.xlsx');
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      });
  }
  public exportToPdf(flag: boolean) {
    this.spinningManager.showSpinner('Exporting PDF.....');
    this.queryObject.printall = flag;
    this.doctorService
      .exportAllItemsToPDF(this.queryObject)
      .subscribe((res: Blob) => {
        importedSaveAs(res, 'DoctorList.pdf');
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      });
  }

 public exportPDFbyId(data: any,firstname,lastname){
  this.spinningManager.showSpinner('Exporting PDF.....');
  var memberName= firstname+""+lastname;
    this.doctorService.exportByIdPDF(data.id).subscribe((res: Blob) =>{
      // for(let i=0;i<this.doctorList.length;i++){
      //   if(this.doctorList[i].id == data.id){
      //     this.memberName = this.doctorList[i].fullName.toLocaleUpperCase();
      //   }
      // }
      importedSaveAs(res, `${memberName}.pdf`);
      this.spinningManager.hideSpinner();
    },
    (error) => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });
 }

 public printBackPage(){
  this.spinningManager.showSpinner('Exporting BackPage.....');
  this.doctorService.printBackPage().subscribe((res:Blob) => {
      importedSaveAs(res, 'BackPage.pdf');
      this.spinningManager.hideSpinner();
  })
 }
 getRegistedBranch(){
  this.doctorService.getRegistedBranch().subscribe((res)=>{
     this.registedBranchList = res;
  })
}
  // getAllCountries() {
  //   this.countryService.getAllCountry()
  //     .subscribe(res => {
  //       this.countryList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //       error => {
  //         this.alertyfy.error(error);
  //         this.spinningManager.hideSpinner();
  //       });
  // }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {
    })
  }
  // getProvinces() {
  //   this.provinceService.getAllProvince()
  //     .subscribe(res => {
  //       this.provinceList = res;
  //       this.spinningManager.hideSpinner();
  //     }, error => {
  //       this.spinningManager.hideSpinner();
  //     });
  // }

  // getAllDistrictsByProvinceId(provinceId) {
  //   this.districtService.getDistrictByProvince(provinceId)
  //     .subscribe(res => {
  //       this.districtList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //       error => {
  //         this.alertyfy.error(error);
  //         this.spinningManager.hideSpinner();
  //       });
  // }

  // getAllPratinidhiSabhaByDistrictId(districtId) {
  //   this.pratinidhiService.getPratinidhiSabhaByDistrict(districtId)
  //     .subscribe(res => {
  //       this.pratinidhiList = res;
  //       this.spinningManager.hideSpinner();
  //     },
  //       error => {
  //         this.alertyfy.error(error);
  //         this.spinningManager.hideSpinner();
  //       });
  // }


  getAllPradeshSabha() {
    this.pradeshService.getAllPradeshSabha()
      .subscribe(res => {
        this.pradeshList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }

}
