export class Municipality {
    id: number;
    name:string;
    description: string;
    districtId:number;
    provinceId: number;
    countryId: number;
    districtName: string;
    provinceName: string;
    countryName:string;
    
}