<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">

        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-8">
                            <h5>Regions List <button *ngIf="this.mps.manageDataPermission([dataPermission.Regions_Add])" type="button" class="btn btn-success ml-2" [routerLink]="['/managedelevery/add-region']">
                                Add New <i class="fa fa-plus" aria-hidden="true"></i>

                            </button></h5>
                        </div>
                        <div class="col-lg-4">
                            <a href="javascript:void(0)" (click)="showHideFilter()" style="float: right; font-size: 16px; color: black;">
                                <i class="fa fa-filter" aria-hidden="true"></i> Filter</a>

                        </div>
                    </div>



                </div>
                <div class="card-body">
                    <div class="row" *ngIf="showFilters" style="background: #ecf0f1;
                    margin: 0px -30px;
                    padding: 15px;
                    margin-top: -15px;
                    margin-bottom: 18px;">
                        <div class="col-8">
                            <input class="form-control" placeholder="Search Text" id="Product" type="text" required="" [(ngModel)]="queryObject.searchString" (keyup.enter)="Search()">
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-secondary w-100" (click)="Search()">Search</button>

                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary w-100" (click)="Reset()">Reset</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="btn-group mr-3" *ngIf="this.mps.manageDataPermission([dataPermission.Regions_Export_Excel])">
                                <button type="button" class="btn btn-secondary">Export to Excel</button>
                                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                    <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
                                    <div class="dropdown-menu" ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="exportToExcel(false);">Current Page</button>
                                        <button ngbDropdownItem (click)="exportToExcel(true);">All Page</button>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-group mr-3" *ngIf="this.mps.manageDataPermission([dataPermission.Regions_Export_PDF])">
                                <button type="button" class="btn btn-secondary">Export to PDF</button>
                                <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                                    <button class="btn btn-secondary dropdown-toggle-split" ngbDropdownToggle></button>
                                    <div class="dropdown-menu" ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="exportToPdf(false);">Current Page</button>
                                        <button ngbDropdownItem (click)="exportToPdf(true);">All Page</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="table-responsive">
                        <table class="table text-nowrap table-bordered">
                            <thead>
                                <tr>
                                    <th *ngFor='let c of columns'>
                                        <div *ngIf="c.isSortable" (click)="sortBy(c.key)">
                                            {{c.title}}
                                            <i *ngIf="queryObject.sortBy===c.key" class="fa" [class.fa-sort-asc]="queryObject.isSortAsc" [class.fa-sort-desc]="!queryObject.isSortAsc">
                                        </i>
                                        </div>
                                        <div *ngIf="!c.isSortable">
                                            {{c.title}}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="regions?.length;else no_data_temp">
                                <tr *ngFor='let item of regions'>
                                    <td>
                                        {{item.id}}
                                    </td>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{item.description}}
                                    </td>
                                    <td>
                                        {{item.countryName}}
                                    </td>
                                    <td class="edit-tableicon">
                                        <a *ngIf="this.mps.manageDataPermission([dataPermission.Regions_Edit])" [routerLink]="['/managedelevery/add-region', item.id]">
                                            <i class="fa fa-edit">
                                        </i>
                                        </a>
                                        <i *ngIf="this.mps.manageDataPermission([dataPermission.Regions_Delete])" (click)="onDeleteConfirm(item.id)" class="fa fa-trash">
                                    </i>
                                    </td>
                                </tr>
                            </tbody>
                            <ng-template #no_data_temp>
                                <tbody>
                                    <td colspan="5">
                                        <h4 class="text-center d-block w-100 mt-3">{{this.noDataFound}}</h4>
                                    </td>
                                </tbody>

                            </ng-template>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-8 col-sm-8 col-lg-8">
                            <ngb-pagination [collectionSize]="queryObject.pageCount" [maxSize]="5" [pageSize]="queryObject.pageSize" [page]="queryObject.page" (pageChange)="onPageChange($event)" aria-label="Default pagination"></ngb-pagination>

                        </div>
                        <div class="col-md-4 col-4 col-lg-4">
                            <div class="form-group row mt-3">
                                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                    No of Items
                                </div>
                                <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                    <select style="margin-top: -10px;" class="custom-select form-control" (change)="changePageSize($event)" [(ngModel)]="queryObject.pageSize" id="pageSize">
                                        <option [value]='10'>10</option>
                                        <option [value]='20'>20</option>
                                        <option [value]='50'>50</option>
                                        <option [value]='100'>100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>