<div class="row fix-spacing">
  <div class="col-md-4">
    <input type="file" #file placeholder="Choose file" (change)="onPhotoChange($event)" (change)="uploadFile(file.files)" style="display: none; " multiple>
    <button type="button" class="btn btn-success ml-3" (click)="file.click()">Upload Photo</button>
  </div>
  <div class="col-md-4">
    <span class="upload" *ngIf="progress > 0">
      {{progress}}%
    </span>
    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>

  <div class="col-md-4 photo">
    <img [src]='imageUrl' alt="" class="img-fluid">
    <!-- <input type="file" (change)="onPhotoChange($event)"> -->
  </div>
</div>
