import { Component, OnInit } from '@angular/core';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { Router } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { environment } from 'src/environments/environment';
import { DistrictQueryObject, QueryObject } from '../../../shared/models/queryObject';
import { District } from 'src/app/shared/models/district';
import { saveAs as importedSaveAs } from 'file-saver';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { CountryList } from '../province-list/province-list.component';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';

@Component({
  selector: 'app-district-list',
  templateUrl: './district-list.component.html',
  styleUrls: ['./district-list.component.scss']
})
export class DistrictListComponent implements OnInit {
  [x: string]: any;
  pageSize = environment.pageSize;
  public districtList: District[];
  public district = [];
  public districtItem: any = [];
  noDataFound: string;
  public dataPermission = DataPermissions;
  public provinceList: ProvinceDropDownList[] = [];
  countryList: CountryList[] = [];

  showFilters: Boolean = false;
  //public districtList = [];
  queryObject = new DistrictQueryObject();
  columns = [
    // { title: 'ID', key: 'Id', isSortable: true },
    { title: 'District', key: 'Name', isSortable: true },
    { title: 'Description', key: 'Description', isSortable: true },
    { title: 'District Code', key: 'DistrictCode', isSortable: true },
    { title: 'Province', key: 'ProvinceName', isSortable: true },
    { title: 'Country', key: 'CountryName', isSortable: true },
    { title: 'Action' }

  ];
  constructor(
    private districtService: DistrictService,
    private _router: Router,
    private alertyfy: AlertyfyService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
    private provinceService: ProvinceService,
    private countryService: CountryService
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }


  ngOnInit() {
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.getProvinces();
    this.getAllCountries();
    this.loadDistrict();
  }
  getDistrictItem() {
    this.districtService.getDistrictItem(this.queryObject).subscribe((res: any) => {
      //this.districtService.getDistrict(this.queryObject).subscribe((res: any) => {
      this.districtList = [];
      this.queryObject.pageCount = res.totalItems;
      this.spinningManager.hideSpinner();
      for (const item of res.items) {
        const districtItem = new District();
        districtItem.id = item.id;
        districtItem.name = item.name;
        districtItem.description = item.description;
        districtItem.districtCode = item.districtCode;
        districtItem.provinceCode = item.provinceCode;
        districtItem.provinceName = item.provinceName;
        districtItem.provinceId = item.provinceId;
        districtItem.countryName = item.countryName;
        districtItem.countryId = item.countryId;
        this.districtList.push(districtItem);
      }
    }, error => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });
  }
  //pagination code
  loadDistrict() {
    this.getDistrictItem();
  }


  //Sorting Code
  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.loadDistrict();
  }
  //pagination code
  onPageChange(page) {
    this.queryObject.page = page;
    this.loadDistrict();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.districtService.deleteDistrict(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getDistrictItem();
          },
            (error) => {
              this.alertyfy.error(error);
            });
      });
  }
  public exportToExcel(flag: boolean) {

    this.queryObject.printall = flag;
    this.districtService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'DistrictList.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.districtService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'DistrictList.pdf');
    });
  }
  getProvinces() {
    this.provinceService.getAllProvince()
      .subscribe(res => {
        this.provinceList = res;
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      });
  }
  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {

    })
  }
  showHideFilter() {
    this.showFilters = !this.showFilters;
  }
  Search() {
    this.loadDistrict();
  }
  Reset() {
    this.queryObject.searchString = '';
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.page = 1;
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.pageSize = this.pageSize;
    this.provinceList = null;
    this.loadDistrict();
  }

}
export interface ProvinceDropDownList {
  provinceId: number;
  provinceName: string;
}

