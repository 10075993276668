import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PradeshSabha } from 'src/app/shared/models/pradeshSabha';
import { PradeshSabhaQueryObject } from 'src/app/shared/models/queryObject';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';
import { ProvinceDropDownList } from '../district-list/district-list.component';
import { DistrictDropDown } from '../municipality-list/municipality-list.component';
import { CountryList } from '../province-list/province-list.component';

@Component({
  selector: 'app-pradesh-sabha-list',
  templateUrl: './pradesh-sabha-list.component.html',
  styleUrls: ['./pradesh-sabha-list.component.scss']
})
export class PradeshSabhaListComponent implements OnInit {

  showFilters: Boolean = false;
  pradeshsabhaList:PradeshSabha[];
  noDataFound: string;
  pageSize = environment.pageSize;
  queryObject = new PradeshSabhaQueryObject();

  public provinceList: ProvinceDropDownList[]=[];
  public countryList: CountryList[]=[];
  public districtList: DistrictDropDown[]=[];
  public pradeshList: DistrictDropDown[]=[];
  columns = [
    // { title: 'ID', key: 'id', isSortable: true },
    { title: 'Pradesh Sabha', key: 'name', isSortable: true },
    { title: 'Description', key: 'description', isSortable: true },
    { title: 'Pradesh Sabha Code', key: 'pradeshSabhaCode', isSortable: true },
    { title: 'District', key: 'districtName', isSortable: true },
    { title: 'Action' },
  ];

  constructor(private _router: Router,
    private spinningManager:SpinnerManagerService,
    private alertyfy: AlertyfyService,
    private _pradeshsabha:PradeshSabhaService,
    private districtService: DistrictService,
    private countryService: CountryService,
    private provinceService: ProvinceService) {
      this.spinningManager.showSpinner("Loading.....");
     }


  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.loadPradeshSabha();
    this.getAllCountries();
  }

  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/add-pradesh-sabha');
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;

  }



  getPradeshSabha() {
    this.spinningManager.showSpinner('Loading.....');
    this._pradeshsabha.getPradeshSabha(this.queryObject).subscribe(
      (res: any) => {
        this.queryObject.pageCount = res.totalItems;
        this.pradeshsabhaList = res.items;
        this.spinningManager.hideSpinner();
        // for (const item of res.items) {
        //   const pradeshsabhas = new PradeshSabha();
        //   pradeshsabhas.id = item.id;
        //   pradeshsabhas.name = item.name;
        //   pradeshsabhas.description = item.description;
        //   pradeshsabhas.pradeshSabhaCode = item.pradeshSabhaCode;
        //   pradeshsabhas.pratinidhiSabhaName = item.pratinidhiSabhaName;
        //   pradeshsabhas.pratinidhiSabhaId = item.pratinidhiSabhaId;
        //   pradeshsabhas.districtId = item.districtId;
        //   pradeshsabhas.districtName = item.districtName;
        //   this.pradeshsabhaList.push(pradeshsabhas);
        // }
      },
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      }
    );
  }

  loadPradeshSabha(){
    this.getPradeshSabha();
  }

  public Reset(){
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.countryId = 0;
    this.queryObject.provinceId = 0;
    this.queryObject.districtId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.getPradeshSabha();
  }

  public Search(){
    this.getPradeshSabha();
  }

  getAllCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllProvinceByCountryId(countryId) {
    this.provinceService.getProvinceByCountry(countryId).subscribe(res => {
      this.provinceList = res;
    }, error => {
    })
  }
  getProvinces() {
    this.provinceService.getAllProvince()
      .subscribe(res => {
        this.provinceList = res;
        this.spinningManager.hideSpinner();
      }, error => {
        this.spinningManager.hideSpinner();
      });
  }

  getAllDistrictsByProvinceId(provinceId) {
    this.districtService.getDistrictByProvince(provinceId)
      .subscribe(res => {
        this.districtList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }
  getAllPradeshSabhaByDistrictId(districtId) {
    this._pradeshsabha.getPradeshSabhaByDistrictId(districtId)
      .subscribe(res => {
        this.pradeshList = res;
        this.spinningManager.hideSpinner();
      },
        error => {
          this.alertyfy.error(error);
          this.spinningManager.hideSpinner();
        });
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this._pradeshsabha.deletePradeshSabha(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.loadPradeshSabha();
          },
            (error) => {
              this.alertyfy.error(error);
            });
      });
  }



  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.loadPradeshSabha();
  }

  onPageChange(page) {
    this.queryObject.page = page;
    this.loadPradeshSabha();
  }

  public exportToExcel(flag: boolean) {

    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.xlsx');
    // });
  }
  public exportToPdf(flag: boolean) {
    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.pdf');
    // });
  }

}
