import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
//import {Res} from '../../models/res';
import { from } from 'rxjs';
import { QueryObject } from '../../models/queryObject';


@Injectable({
  providedIn: 'root'
})
export class CountryService {
  [x: string]: any;

  constructor(private apiService:ApiService) {

  }
  getAllCountry(){
    return this.apiService.get('Country/GetAll');
  }
  getCountryById(id: any){
    return this.apiService.get('Country/GetById/'+id);
  }
  createCountry(data:any){
    return this.apiService.post('Country/CreateCountry',data)
  }
  upsertCountry(data:any){
    return this.apiService.post('Country/UpsertCountry',data);
  }
  deleteCountry(id:any){
    return this.apiService.post('Country/DeleteCountry/'+id);
  }
  UpsertCountry(data){
      return this.apiService.post('Country/UpsertCountry',data);
  }
  getCountry(query: QueryObject){
    return this.apiService.post('Country/CountryItem', query);
}
 public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('Country/ExportToExcel',query)
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('Country/ExportToPdf',query);
  }
  }






