import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './components/users/users.module';
import { AuthModule } from './components/auth/auth.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManagedeleveryModule } from './components/managedelevery/managedelevery.module';
import { TokenInterceptor } from '../app/shared/token-interceptor.service';
import { AuthGuard } from './shared/core/AuthGaurd';
import { PermissionManagementModule } from './components/permission-management/permission-management.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgBootstrapFormValidationModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    AuthModule,
    ManagedeleveryModule,
    SharedModule,
    UsersModule,
    HttpClientModule,
    PermissionManagementModule,
    NgxSpinnerModule,
    TreeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  entryComponents: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {provide: Window, useValue: window},
    AuthGuard
  ],
  // providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
