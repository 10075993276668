 <div class="site-container">
   <header id="header" class="header affix-top" #home>
     <div class="top-bar">
       <div class="container">
         <div class="row">
           <div class="col-md-12 col-lg-6 hide-phone">
             <p>
               <i class="fa fa-phone" aria-hidden="true"></i>
               041-528898 / 9844028898
             </p>
           </div>
           <div class="col-md-12 col-lg-6">
             <ul class="social-icons clearfix">
               <li>
                 <a href="#" class="facebook-icon">
                   <i class="fa fa-facebook" aria-hidden="true"></i>
                 </a>
               </li>
               <li>
                 <a href="#" class="google-icon">
                   <i class="fa fa-google-plus" aria-hidden="true"></i>
                 </a>
               </li>
               <li>
                 <a href="#" class="instagram-icon">
                   <i class="fa fa-instagram" aria-hidden="true"></i>
                 </a>
               </li>
               <li>
                 <a href="#" class="youtube-icon">
                   <i class="fa fa-youtube-play" aria-hidden="true"></i>
                 </a>
               </li>

               <li>
                 <a href="#" data-toggle="modal" data-target="#exampleModal" class="user-login">
                   <i class="fa fa-user-circle" aria-hidden="true"></i>
                   <span>Login</span>
                 </a>
               </li>
             </ul>
           </div>
         </div>

       </div>
     </div>
     <div class="bottom-bar">
       <div class="container">
         <nav class="navbar navbar-expand-lg">
           <a class="navbar-brand" href="#">
             <img src="../../../../assets/images/main-logo.png" alt="" class="img-fluid">
           </a>
           <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
             aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
             <!-- <span class="navbar-toggler-icon fa fa-ham"></span> -->
             <i class="fa fa-bars" aria-hidden="true"></i>
           </button>
           <div class="collapse navbar-collapse" id="navbarNav">
             <ul class="navbar-nav ml-auto">
               <li class="nav-item active">
                 <a class="nav-link">Home
                   <span class="sr-only" (click)="scrollToElement(home)">(current)</span>
                 </a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" (click)="scrollToElement(about)">About</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" (click)="scrollToElement(service)">Services</a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" (click)="scrollToElement(team)">Team</a>
               </li>

               <li class="nav-item">
                 <a class="nav-link" (click)="scrollToElement(contact)">Contact us</a>
               </li>
             </ul>
           </div>
         </nav>

       </div>
     </div>

   </header>

   <section class="banner-wrapper">

     <div class="banner-img">
       <img src="../../../../assets/images/banner1.jpg" class="img-fluid" alt="">
     </div>

   </section>

   <div class="main-wrapper">
     <section class="about-section" id="#about" #about>
       <div class="container">
         <div class="row">
           <div class="col-md-12">
             <h2 class="about-heading">ABOUT OUR Company</h2>
             <p>
               Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore esse quia sit inventore cupiditate
               distinctio
               <br>
               Lorem ipsum dolor sit amet consectetur adipisicing elit.
             </p>
           </div>
         </div>
         <div class="row">
           <div class="col-md-6">
             <div class="icon-box clearfix">
               <a href="#">
                 <i class="fa fa-stethoscope"></i>
               </a>
               <div class="content-box">
                 <h2>Whiten you Teeth</h2>
                 <p>
                   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, quasi quis dolorum iste vero architecto
                   minus eligendi nam alias veritatis.
                 </p>
               </div>
             </div>
             <div class="icon-box clearfix">
               <a href="#">
                 <i class="fa fa-stethoscope"></i>
               </a>
               <div class="content-box">
                 <h2>Get a Shining Teeth</h2>
                 <p>
                   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, quasi quis dolorum iste vero architecto
                   minus eligendi nam alias veritatis.
                 </p>
               </div>
             </div>
           </div>
           <div class="col-md-6">
             <div class="about-right">
               <img src="../../../../assets/images/image-02.jpg" alt="">
             </div>
           </div>
         </div>
       </div>
     </section>


     <section class="services-content" id="service" #service>
       <div class="container">
         <div class="row services-box">
           <div class="col-lg-4 col-md-6 col-sm-12">
             <div class="inner-box">
               <div class="hover-bg" style="background: url(../../../../assets/images/feature-1.jpg);"></div>
               <div class="icon-box">
                 <i class="fa fa-stethoscope"></i>
               </div>
               <h3>
                 <a href="#">Specialised <br> Support</a>
               </h3>
               <p>
                 Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                 Illum magnam doloremque quas consequatur rerum iusto dolores
               </p>
             </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
             <div class="inner-box">
               <div class="hover-bg" style="background: url(../../../../assets/images/feature-1.jpg);"></div>
               <div class="icon-box">
                 <i class="fa fa-stethoscope"></i>
               </div>
               <h3>
                 <a href="#">Specialised <br> Support</a>
               </h3>
               <p>
                 Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                 Illum magnam doloremque quas consequatur rerum iusto dolores
               </p>
             </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
             <div class="inner-box">
               <div class="hover-bg" style="background: url(../../../../assets/images/feature-1.jpg);"></div>
               <div class="icon-box">
                 <i class="fa fa-stethoscope"></i>
               </div>
               <h3>
                 <a href="#">Specialised <br> Support</a>
               </h3>
               <p>
                 Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                 Illum magnam doloremque quas consequatur rerum iusto dolores
               </p>
             </div>
           </div>
         </div>
       </div>
     </section>

     <section class="team-content related-section" id="target" #team>
       <div class="container">
         <div class="row">
           <div class="col-md-12">
             <h2 class="about-heading">Our Team Members</h2>
             <p>
               Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore esse quia sit inventore cupiditate
               distinctio
               <br>
               Lorem ipsum dolor sit amet consectetur adipisicing elit.
             </p>
           </div>
         </div>
         <div class="row">
           <div class="col-md-4">
             <div class="members-content">
               <a href="#">
                 <img src="../../../../assets/images/team1.jpg" class="img-fluid" alt="">
               </a>
               <div class="team-details clearfix">
                 <div class="left">
                   <h2>
                     Dr. Ajay Shah
                     <span> B.D.AS., M.P.H </span>
                   </h2>
                 </div>
                 <div class="right">
                   <ul>
                     <li>
                       <a href="#">
                         <i class="fa fa-facebook"></i>
                       </a>
                     </li>
                     <li>
                       <a href="#">
                         <i class="fa fa-twitter"></i>
                       </a>
                     </li>
                     <li>
                       <a href="#">
                         <i class="fa fa-envelope"></i>
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
           <div class="col-md-4">
             <div class="members-content">
               <a href="#">
                 <img src="../../../../assets/images/team2.jpg" class="img-fluid" alt="">
               </a>
               <div class="team-details clearfix">
                 <div class="left">
                   <h2>
                    Dr. Ajay Shah
                    <span> B.D.AS., M.P.H </span>
                   </h2>
                 </div>
                 <div class="right">
                   <ul>
                     <li>
                       <a href="#">
                         <i class="fa fa-facebook"></i>
                       </a>
                     </li>
                     <li>
                       <a href="#">
                         <i class="fa fa-twitter"></i>
                       </a>
                     </li>
                     <li>
                       <a href="#">
                         <i class="fa fa-envelope"></i>
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
           <div class="col-md-4">
             <div class="members-content">
               <a href="#">
                 <img src="../../../../assets/images/team3.jpg" class="img-fluid" alt="">
               </a>
               <div class="team-details clearfix">
                 <div class="left">
                   <h2>
                    Dr. Ajay Shah
                    <span> B.D.AS., M.P.H </span>
                   </h2>
                 </div>
                 <div class="right">
                   <ul>
                     <li>
                       <a href="#">
                         <i class="fa fa-facebook"></i>
                       </a>
                     </li>
                     <li>
                       <a href="#">
                         <i class="fa fa-twitter"></i>
                       </a>
                     </li>
                     <li>
                       <a href="#">
                         <i class="fa fa-envelope"></i>
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>


     <section class="related-section contact-text" #contact>
       <div class="container">
         <div class="row">
           <div class="col-md-12">
             <h2 class="about-heading">Contact Us</h2>
             <p>
               Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore esse quia sit inventore cupiditate
               distinctio
               <br>
               Lorem ipsum dolor sit amet consectetur adipisicing elit.
             </p>
           </div>
         </div>
       </div>
     </section>


     <section class="contact-container">
       
      <div class="contact-content clearfix">
        <div class="contact-left">
          <div class="contact-main">
            <div class="contact-form">
              <h2>Send Us Message</h2>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="dev[name]" value="" placeholder="Name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="dev[surname]" value="" placeholder="Surname">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="dev[phone]" value="" placeholder="Phone">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" name="dev[email]" value="" placeholder="Email">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea class="form-control" placeholder="Enter your message" name="dev[message]"
                      rows="6"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <button type="submit" class="btn btn-default">Submit Message</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d8474.809902959007!2d85.92387541226748!3d26.737398331817225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d26.734869999999997!2d85.92414!5e0!3m2!1sen!2snp!4v1646888526968!5m2!1sen!2snp"
            width="100%" class="map" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
     </section>



   </div>


   <footer class="main-footer">
     <div class="container">
       <div class="footer-content">
         <div class="row">
           <div class="col-lg-6 col-md-6">
             <div class="footer-left">
               <div class="footer-logo">
                 <img src="../../../../assets/images/main-logo.png" alt="">
               </div>
               <p>

                 Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium, aperiam alias amet perspiciatis
                 quibusdam, rem nostrum saepe odit mollitia dolorem beatae facere architecto quod. Quis non obcaecati
                 dolore illum in.
               </p>
               <ul class="list-style-two">
                 <li>
                   <a href="#">
                     <span class="icon fa fa-map-marker"></span>
                     Aspatal Road, Janakpurdham</a>
                 </li>
                 <li>
                   <a href="#">
                     <span class="icon fa fa-phone"></span>
                     041-528898 / 9844028898
                   </a>
                 </li>
                 <li>
                   <a href="mailto:info@example.com">
                     <span class="icon fa fa-envelope"></span>
                     info@appandentalchambers.com</a>
                 </li>
               </ul>
             </div>
           </div>
           <div class="col-lg-3 col-md-6">
             <div class="footer-left">
               <h2>USEFUL LINKS</h2>
               <ul class="list-footer">
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
                 <li>
                   <a href="#">
                     lorem Ipsumm
                   </a>
                 </li>
               </ul>
             </div>
           </div>
           <div class="col-lg-3 col-md-6">
             <div class="footer-left">
               <h2>working Hours</h2>
               <ul class="working-hrs">
                 <li>Sunday :<span>10.00 - 04.00</span></li>
                 <li>Monday :<span>10.00 - 04.00</span></li>
                 <li>Tuesday :<span>10.00 - 04.00</span></li>
                 <li>Wednesday :<span>10.00 - 04.00</span></li>
                 <li>Thursday :<span>10.00 - 04.00</span></li>
                 <li>Friday :<span>10.00 - 04.00</span></li>
                 <li>Saturday :<span>10.00 - 04.00</span></li>
               </ul>
             </div>
           </div>
         </div>
       </div>
     </div>
   </footer>

   <div class="copyright">
     <div class="container">
       <p>
         &copy; Copyright 2022 All right reserved Appan Dental Chambers
       </p>
     </div>

   </div>

   <!-- Login Modal -->
   <div class="modal fade close-fix" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
     <div class="modal-dialog  modal-dialog-centered" role="document">
       <div class="modal-content">
         <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>

        </button>
      </div> -->
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">&times;</span></button>
         <div class="modal-body">


           <div class="">
             <div class="login-maincontent">
               <div class="container">
                 <div class="">
                   <!-- d-flex justify-content-center align-items-center -->
                   <div class="tab2-card login-wrapper p-3 p-md-3">

                     <ngb-tabset class="tab-coupon mb-0">
                       <ngb-tab>
                         <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Login</ng-template>
                         <ng-template ngbTabContent>
                           <div class="tab-pane fade active show" id="account" role="tabpanel"
                             aria-labelledby="account-tab">
                             <form [formGroup]="loginForm" class="login-form" novalidate="" (ngSubmit)="onSubmit()">

                               <div class="form-group">
                                 <input required="" name="login[username]" type="email" class="form-control"
                                   placeholder="Username" id="exampleInputEmail1" formControlName="userName">
                               </div>
                               <div class="form-group d-flex">
                                 <input required="" name="login[password]" type="password" class="form-control"
                                   placeholder="Password" formControlName="password">
                               </div>

                               <div class="form-group d-md-flex">
                                 <div class="custom-control custom-checkbox">
                                   <input type="checkbox" class="custom-control-input" id="customControlAutosizing">
                                   <label class="custom-control-label" for="customControlAutosizing">Remember me</label>
                                 </div>
                               </div>
                               <div class="form-button">
                                 <button class="btn btn-primary" type="submit">Login</button>
                               </div>
                             </form>
                           </div>
                         </ng-template>
                       </ngb-tab>
                     </ngb-tabset>

                   </div>
                 </div>
               </div>
             </div>
           </div>


         </div>
       </div>
     </div>
   </div>


 </div>
