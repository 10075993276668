<div class="mt-3">
    <table class="table">
        <tr>
            <th>Id</th>
            <th>File type</th>
            <th>File image</th>
            <th>Verified by</th>
            <th>Verified on</th>
            <th>Created on</th>
            <th>Comment</th>
            <th>Action</th>
        </tr>
        <tr *ngFor="let item of userRegistrationFiles">
            <td>{{item.id}}</td>
            <td>{{item.masterRegistrationFile.type+"["+item.masterRegistrationFile.name+"]"}}</td>
            <td>
                <!-- <div (click)="showLagreImage(item)">
                  <img *ngIf="item.show==false || item.show==undefined" class="img-thumbnail m-r-15" height="100px" width="100px" [src]='this.baseUrlForImage + "/"+ item.fileDetails'>
                  <img *ngIf="item.show==true" [src]='this.baseUrlForImage + "/"+ item.fileDetails'>
                </div> -->
                <img  class="img-thumbnail m-r-15" height="100px" width="100px" [src]='this.baseUrlForImage + "/"+ item.fileDetails' (click)="openImage(contentImage,item)">
                
            </td>
            <td>
                {{item.userDetails.firstName+" "+ item.userDetails.lastName}}
            </td>
            <td>
                {{item.verifiedOn | date}}
            </td>
            <td>
                {{item.createdOn |date}}
            </td>
            <td>
                {{item.comment}}
            </td>
            <td>
                  <button class="btn btn-primary mb-2  w-40" (click)="open(content, item, true)">Accept</button>
                  <button class="btn btn-primary mb-2  w-40" (click)="open(content, item, false)"> Reject </button>
            </td>
         </tr>
    </table>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Comments For {{userRegistrationToUpdate.isVerfied?" Acceptance": "Rejection"}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
              <div class="md-form">
                  <textarea type="text" name="form7" class="md-textarea form-control" [(ngModel)]="userRegistrationToUpdate.comment" mdbInput></textarea>
                  <!-- <label for="form7">Comments</label> -->
                </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
      </div>
  </ng-template>
  <ng-template #contentImage let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><b>File Type:</b> {{popupHeader}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
            <div class="md-form">
              <img  [src]='this.imageUrl' alt="Image not found" height="350px" width="450px">
              </div>
        </div>
      </form>
    </div>
</ng-template>
</div>   
