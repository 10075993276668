import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { Province } from 'src/app/shared/models/province';
import { query } from '@angular/animations';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-province',
  templateUrl: './add-province.component.html',
  styleUrls: ['./add-province.component.scss'],
})
export class AddProvinceComponent implements OnInit {
  provinceListForm: FormGroup;
  countryList = [];
  dropdownSettings = {};
  provinceid: number = 0;
  btnSave = 'Save';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private provinceService: ProvinceService,
    private countryService: CountryService,
    private alertyfy: AlertyfyService,
    private route: ActivatedRoute,
    private spinningManager: SpinnerManagerService
  ) {
    this.provinceListForm = this.fb.group({
      id: '',
      countryId: '',
      name: '',
      description: '',
      provinceCode: '',
    });
    this.provinceid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
    if (this.provinceid > 0) {
      this.editProvince();
    }
    this.spinningManager.showSpinner('Saving.....');
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      enableCheckAll: false,
    };

    this.getAllCountries();
  }

  editProvince() {
    this.provinceService
      .getProvinceById(this.provinceid)
      .subscribe((result) => {
        this.provinceListForm = this.fb.group({
          countryId: result['countryId'],
          name: result['name'],
          description: result['description'],
          provinceCode: result['provinceCode'],
        });
      });
  }

  getAllCountries() {
    this.spinningManager.showSpinner('Loading.....');
    this.countryService.getAllCountry().subscribe(
      (res) => {
        this.countryList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {}
    );
  }
  saveProvinceList() {
    if (this.provinceid && this.provinceid > 0) {
      const _province = {
        id: this.provinceid,
        name: this.provinceListForm.controls.name.value,
        description: this.provinceListForm.controls.description.value,
        provincecode: this.provinceListForm.controls.provinceCode.value,
        countryId: this.provinceListForm.controls.countryId.value,
      };

      this.provinceService.upsertProvince(_province).subscribe(
        (result) => {
          this.router.navigateByUrl('/managedelevery/province-list');
          this.spinningManager.hideSpinner();
        },
        (error) => {
          this.spinningManager.hideSpinner();
        }
      );
    } else {
      this.spinningManager.showSpinner('Saving.....');
      var _province = new Province();
      var provinceObj = this.provinceListForm.value;
      _province.id = provinceObj.id || 0;
      _province.countryId = provinceObj.countryId;
      _province.name = provinceObj.name;
      _province.description = provinceObj.description;
      _province.provinceCode = provinceObj.provinceCode;
      this.provinceService.upsertProvince(_province).subscribe(
        (res) => {
          if (res) {
            this.alertyfy.success('Successfully Inserted.');
            this.provinceListForm.reset();
            this.router.navigateByUrl('/managedelevery/province-list');
            this.spinningManager.hideSpinner();
          }
        },
        (error) => {
          this.alertyfy.saveError('Province');
          this.spinningManager.hideSpinner();
          this.router.navigateByUrl('/managedelevery/province-list');
        }
      );
    }
  }
}
