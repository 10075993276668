import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/core/auth.service';
import { UserDetailsService } from '../../../shared/service/userDetails/userDetails.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  returnUrl: string;
  //win: Window;
  constructor(private formBuilder: FormBuilder, private userDetailsService: UserDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private win: Window) {
    this.createLoginForm();
    this.createRegisterForm();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  owlcarousel = [
    {
      title: "Welcome to Movaxle",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Movaxle",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Movaxle",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]

  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })
  }

  scrollToElement($element): void {
    // console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    })
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.userDetailsService.login(this.loginForm.value).subscribe((res: any) => {
        if (res && res.status == 1) {
          localStorage.setItem('token', res.access_token);
          this.authService.loggedIn.next(true);
          this.userDetailsService.getUserDetailsById().subscribe(res => {
            localStorage.setItem('userDetails', JSON.stringify(res));
            let username = res.firstName !== '' ? res.firstName : res.email;
            this.authService.user.next(username);
            this.authService.userImage.next(res.imagePath);
            this.router.navigateByUrl(this.returnUrl);
            this.win['jQuery']("#exampleModal").modal("hide");
          });
        } else {
        }
      })
    } else {

    }
  }
}
