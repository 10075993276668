import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PradeshSabhaService {

  constructor(private apiService: ApiService) { }
  getAllPradeshSabha(){
    return this.apiService.get('PradeshSabha/GetAll');
  }
  getPradeshSabhaById(id: any){
    return this.apiService.get('PradeshSabha/getbyid?id='+id);
  }
  createPradeshSabha(data:any){
    return this.apiService.post('PradeshSabha/Create',data)
  }
  deletePradeshSabha(id:any){
    return this.apiService.post('PradeshSabha/delete?id='+id);
  }
  updatePradeshSabha(data:any){
    return this.apiService.post('PradeshSabha/update',data);
  }

  getPradeshSabha(data:QueryObject){
    return this.apiService.post('PradeshSabha/PradeshSabhaItem',data);
  }

  getPradeshSabhaByDistrictId(id:number){
    return this.apiService.get('PradeshSabha/GetPradeshSabhaByDistrictyId/'+id);
  }
}
