<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>{{this.dpid==0?'Create Data Permission':'Update Data Permission'}}</h5>
            
        </div>
        <div class="card-body tab2-card">
            <form [formGroup]="DataPermissoinForm" class="needs-validation" novalidate>
                <input type="hidden" formControlName="id" id="id">
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4"><span>*</span>Name</label>
                    <input class="form-control col-xl-9 col-md-8" required formControlName="name" type="text" id="name">
                    <span *ngIf="!DataPermissoinForm.controls.name.valid && DataPermissoinForm.controls.name.touched" class="error-block">Please Provide Name</span>
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4">Header</label>
                    <input class="form-control col-xl-9 col-md-8" formControlName="header" type="text" id="header">
                    <!-- <span *ngIf="!DataPermissoinForm.controls.name.valid && DataPermissoinForm.controls.name.touched" class="error-block">Please Provide Name</span> -->
                </div>
                <div class="form-group row editor-label">
                    <label class="col-xl-3 col-md-4">Description</label>
                    <div class="col-xl-9 col-md-8 editor-space">
                        <ck-editor id="description" formControlName="description" cols="30" rows="10" language="en" [fullPage]="false"></ck-editor>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-md-4">Is Group Master</label>
                    <div class="checkbox checkbox-primary col-xl-9 col-md-8">
                        <input id="checkbox-primary-2" formControlName="isGroupMaster" type="checkbox" data-original-title="" title="">
                        <label for="checkbox-primary-2">Enable Group Master</label>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4">Page Path</label>
                    <input class="form-control col-xl-9 col-md-8" formControlName="pagePath" type="text" id="pagePath">
                    <!-- <span *ngIf="!DataPermissoinForm.controls.name.valid && DataPermissoinForm.controls.name.touched" class="error-block">Please Provide Name</span> -->
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4"><span>*</span>Header Code</label>
                    <input class="form-control col-xl-9 col-md-8" required formControlName="headerCode" type="text" id="headerCode">
                    <span *ngIf="!DataPermissoinForm.controls.headerCode.valid && DataPermissoinForm.controls.headerCode.touched" class="error-block">Please Provide Header Code</span>
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xl-3 col-md-4"><span>*</span>Code</label>
                    <input class="form-control col-xl-9 col-md-8" required formControlName="code" type="text" id="code">
                    <span *ngIf="!DataPermissoinForm.controls.code.valid && DataPermissoinForm.controls.code.touched" class="error-block">Please Provide Code</span>
                </div>
                <div class="form-group row">
                    <div class="col-12 m-0 p-0">
                        <button [routerLink]="['/PermissionManagement/list-data-permission']" type="button" class="pull-right btn ml-2 btn-primary">Back to List</button>
                        <button [disabled]="!DataPermissoinForm.valid" type="button" (click)="onSubmit()" class="pull-right btn btn-success">{{this.dpid==0?'Create':'Update'}}</button>
    
                    </div>
                </div>
               
            </form>
            <!-- Values:{{this.DataPermissoinForm.value | json}} -->
        </div>
    </div>
</div>