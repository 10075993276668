<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5> Change Password</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="ChangePasswordForm" (ngSubmit)="changePassword()" class="needs-validation user-add">
                        <div class="form-group row">
                            <label for="password" class="col-xl-12 col-md-12">New Password</label>
                            <div class="col-xl-12 col-md-12">
                                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && cp.password.errors }" />
                                <span class="errormsg" *ngIf="cp.password.touched && cp.password.errors?.required">Password is required</span>
                                <span class="errormsg" *ngIf="cp.password.touched && cp.password.errors?.minlength">Password should be minimum {{cp.password.errors?.minlength?.requiredLength}} character in length</span>
                                <span class="errormsg" *ngIf="error">{{errormessage}} </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="confirmPassword" class="col-xl-12 col-md-12">Confirm Password</label>
                            <div class="col-xl-12 col-md-12">
                                <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && cp.confirmPassword.errors }" />
                                <span class="errormsg" *ngIf="cp.confirmPassword.touched && cp.confirmPassword.errors?.required">Confirm Password is required</span>
                                <span class="errormsg" *ngIf="cp.confirmPassword.touched && cp.confirmPassword.errors?.confirmedValidator">Password and Confirm Password must be match.</span>
                            </div>
                        </div>
                        <div class="pull-right">
                            <button [disabled]="loading" class="btn btn-success">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Change Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->