import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { userAddress, userbasicinfo, userdetails } from 'src/app/shared/models/profile';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { CityService } from 'src/app/shared/service/ManageDelivery/citylist.service';
import { CountryService } from 'src/app/shared/service/ManageDelivery/country.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { ProvinceService } from 'src/app/shared/service/ManageDelivery/province.service';
import { UserDetailsService } from 'src/app/shared/service/userDetails/userDetails.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  baseUrlForImage = environment.imageUrl;
  userdetails: any;
  userbasicinfo: userbasicinfo;
  userAddresses: userAddress[] = [];
  primaryUserAddress: userAddress;
  editFields = false;
  editAddressFields = false;
  profileForm: FormGroup;
  addressForm: FormGroup;
  dateofBirth: any;
  dateofBirth_: NgbDateStruct;
  selectedFile = null;
  genders = [];
  countries = [];
  provinces = [];
  districts = [];
  cities = [];
  public locationData: any;

  constructor(
    private userdetailsService: UserDetailsService,
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private countryService: CountryService,
    private cityService: CityService,
    private alertify: AlertyfyService,
    private fb: FormBuilder,
    private fbAdress: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.getUserDetails();
    this.createProfileForm();
    this.createAddressForm();
    this.getGenders();
  }
  getGenders() {
    this.userdetailsService.getGenders()
      .subscribe(res => {
        this.genders = res;
      });
  }
  editProfilePicture(event) {
    this.selectedFile = (event.target.files[0] as File);
    const formData = new FormData();
    formData.append('image', (event.target.files[0] as File));
    this.userdetailsService.changeProfilePicture(formData)
      .subscribe(res => {
        this.userdetails = res;
        this.fillUserBasicInfo();
        this.alertify.success('Profile Picture Updated.');
      },
        error => {
          this.alertify.error(error);
        });
  }
  createProfileForm() {
    this.profileForm = this.fb.group({
      id: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      gender_: '',
      gender: ['', Validators.required],
      phoneNumber: '',
      dateofBirth: ['', Validators.required],

    });
  }
  createAddressForm() {
    this.addressForm = this.fb.group({
      id: '',
      city: '',
      cityId: ['', Validators.required],
      district: '',
      districtId: ['', Validators.required],
      province: '',
      provinceId: ['', Validators.required],
      country: '',
      countryId: ['', Validators.required],
      tole: '',
      gharNumber: '',
      phoneNumber: '',
      address: ['', Validators.required],
      addressType: '',
      addressTypeId: '',
      googleAddress: '',
      latitude: '',
      longitude: ''
    });
  }
  setProfileFormValues() {
    var dob = this.userdetails != null && this.userdetails.dateofBirth != null ? this.getNgbFormatDate((this.userdetails.dateofBirth).split('T')[0]) : null;
    this.profileForm.setValue({
      id: this.userdetails.id,
      firstName: this.userdetails.firstName,
      lastName: this.userdetails.lastName,
      email: this.userdetails.email,
      gender_: this.userdetails.gender_,
      gender: this.userdetails.gender,
      phoneNumber: this.userdetails.phoneNumber,
      dateofBirth: dob,
    });
    this.dateofBirth_ = dob;
  }
  setAddressFormValues() {
    this.addressForm.setValue({
      id: this.primaryUserAddress.id,
      city: this.primaryUserAddress.city,
      cityId: this.primaryUserAddress.cityId,
      district: this.primaryUserAddress.district,
      districtId: this.primaryUserAddress.districtId,
      province: this.primaryUserAddress.province,
      provinceId: this.primaryUserAddress.provinceId,
      country: this.primaryUserAddress.country,
      countryId: this.primaryUserAddress.countryId,
      tole: this.primaryUserAddress.tole,
      gharNumber: this.primaryUserAddress.gharNumber,
      phoneNumber: this.primaryUserAddress.phoneNumber,
      address: this.primaryUserAddress.address,
      addressType: this.primaryUserAddress.addressType,
      addressTypeId: this.primaryUserAddress.addressTypeId,
      googleAddress: this.primaryUserAddress.googleAddress,
      latitude: this.primaryUserAddress.latitude,
      longitude: this.primaryUserAddress.longitude

    });
    return;
  }
  getUserDetails() {
    this.userdetailsService.getUserDetailsById()
      .subscribe(res => {
        if (res.dateofBirth != null) {
          var _date = (res.dateofBirth).split('T')[0];
          this.dateofBirth = _date;
        }
        this.userdetails = res;
        this.userAddresses = res.userAddress;
        this.fillUserBasicInfo();
        this.fillPrimaryUserAddress();
      },
        error => {
          this.alertify.error(error);
        });
  }
  fillUserBasicInfo() {
    var _userbasicinfo = new userbasicinfo();
    _userbasicinfo.id = this.userdetails.id;
    _userbasicinfo.firstName = this.userdetails.firstName;
    _userbasicinfo.lastName = this.userdetails.lastName;
    _userbasicinfo.profilePictureUrl = this.baseUrlForImage + this.userdetails.imagePath;
    _userbasicinfo.email = this.userdetails.email;
    this.userbasicinfo = _userbasicinfo;
  }
  fillPrimaryUserAddress() {
    var primaryUserAddress = this.userAddresses.filter(x => x.isPrimaryAdress)[0];
    if (primaryUserAddress != undefined && primaryUserAddress != null) {
      this.primaryUserAddress = primaryUserAddress;
    } else {
      this.primaryUserAddress = new userAddress();
    }
  }
  editProfile() {
    this.editFields = true;
    this.setProfileFormValues();
  }

  async editAddress() {
    await this.getLocation();
    await this.setAddressFormValues();
    await this.makeAdressFieldsEditable();
  }

  makeAdressFieldsEditable() {
    this.editAddressFields = true;
    return;
  }
  saveProfile() {
    if (this.profileForm.valid) {
      this.editFields = false;
      const dob = new Date(this.dateofBirth_.year, this.dateofBirth_.month - 1, this.dateofBirth_.day) || new Date();
      var userDetails = new userdetails();
      var _userDetails = this.profileForm.getRawValue();
      userDetails.id = Number(_userDetails.id);
      userDetails.firstName = _userDetails.firstName;
      userDetails.lastName = _userDetails.lastName;
      userDetails.email = _userDetails.email;
      userDetails.phoneNumber = _userDetails.phoneNumber;
      userDetails.gender = _userDetails.gender;
      userDetails.dateofBirth = dob;
      this.userdetailsService.saveProfile(userDetails)
        .subscribe(res => {
          var _date = (res.dateofBirth).split('T')[0];
          this.dateofBirth = _date;
          this.userdetails = res;
          this.userAddresses = res.userAddress;
          this.fillUserBasicInfo();
          this.fillPrimaryUserAddress();
          this.alertify.success('Saved.');
        },
          error => {
            this.alertify.error(error);
          });
    } else {
      this.profileForm.markAllAsTouched();
    }
  }
  saveAddress() {
    if (this.addressForm.valid) {
      var useraddress = new userAddress();
      var _useraddress = this.addressForm.value;
      useraddress.id = Number(_useraddress.id);
      useraddress.countryId = _useraddress.countryId;
      useraddress.provinceId = _useraddress.provinceId;
      useraddress.districtId = _useraddress.districtId;
      useraddress.cityId = _useraddress.cityId;
      useraddress.address = _useraddress.address;
      useraddress.addressTypeId = Number(_useraddress.addressTypeId);
      useraddress.address = _useraddress.address;
      useraddress.phoneNumber = _useraddress.phoneNumber;
      useraddress.tole = _useraddress.tole;
      useraddress.gharNumber = _useraddress.gharNumber;
      useraddress.googleAddress = this.locationData.address;
      useraddress.latitude = this.locationData.latitude;
      useraddress.longitude = this.locationData.longitude;
      // useraddress.googleAddress=_useraddress.address;
      // useraddress.latitude=_useraddress.latitude;
      // useraddress.longitude=_useraddress.longitude

      this.userdetailsService.saveAddress(useraddress)
        .subscribe(res => {
          this.getUserDetails();
          this.editAddressFields = false;
          this.alertify.success('Saved Address.');
        },
          error => {
            this.alertify.error(error);
          });
    } else {
      this.addressForm.markAllAsTouched();
    }
  }
  cancelAddressEdit() {
    this.editAddressFields = false;
  }
  getCountries() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countries = res;
      });
  }
  onCountrySelection(event) {
    this.primaryUserAddress.countryId = event.target.value;
    this.provinceService.getProvinceByCountry(event.target.value)
      .subscribe(res => {
        this.provinces = res;
        this.districts = [];
        this.cities = [];
      },
        error => {

        });
  }
  onProvinceSelection(event) {
    this.primaryUserAddress.districtId = event.target.value;
    this.districtService.getDistrictByProvince(event.target.value)
      .subscribe(res => {
        this.districts = res;
        this.cities = [];
      },
        error => {

        });
  }
  onDistrictSelection(event) {
    this.primaryUserAddress.districtId = event.target.value;
    this.cityService.getCityByDistrictId(event.target.value)
      .subscribe(res => {
        this.cities = res;
      },
        error => {

        });
  }
  onCitySelection(event) {
    this.primaryUserAddress.cityId = event.target.value;
  }
  getLocation() {
    this.countryService.getAllCountry()
      .subscribe(res => {
        this.countries = res;
        if (this.primaryUserAddress != null && this.primaryUserAddress.countryId != undefined && this.primaryUserAddress.countryId != null && this.primaryUserAddress.countryId != '') {
          this.provinceService.getProvinceByCountry(this.primaryUserAddress.countryId)
            .subscribe(res => {
              this.provinces = res;
              if (this.primaryUserAddress.provinceId != undefined && this.primaryUserAddress.provinceId != null) {
                this.districtService.getDistrictByProvince(this.primaryUserAddress.provinceId)
                  .subscribe(res => {
                    this.districts = res;
                    if (this.primaryUserAddress.districtId != undefined && this.primaryUserAddress.districtId != null) {
                      this.cityService.getCityByDistrictId(this.primaryUserAddress.districtId)
                        .subscribe(res => {
                          this.cities = res;
                        },
                          error => {

                          });
                    }
                  },
                    error => {

                    });
              }
            },
              error => {

              });
        }
      },
        error => {

        });
    return;
  }
  private getNgbFormatDate(date) {
    var result = date.split('-');
    var ngbDate = {
      year: Number(result[0]),
      month: Number(result[1]),
      day: Number(result[2]) + 1
    }
    return ngbDate;
  }
  getLocationEventData(obj: any) {
    this.locationData = obj;
    this.addressForm.controls['googleAddress'].patchValue(this.locationData.address);
    this.addressForm.controls['latitude'].patchValue(this.locationData.latitude);
    this.addressForm.controls['longitude'].patchValue(this.locationData.longitude);
  }
}
