<!-- <button (click)="login()" >Login</button> -->
<!-- <ngx-spinner>
   <p style="color:red"> Loading... </p>
 </ngx-spinner> -->
<!-- <ngx-spinner bdColor="rgb(88,84,84,0.5)" size="large" color="#fff" type="timer" [fullScreen]="true">
  <p style="color:#fff"> Loading... </p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgb(88,84,84,0.5)" type="timer" template="<img  src='../assets/images/loader_logo.png' style='width: 95px;
  margin-bottom: 30px; animation: zoominoutsinglefeatured 1s infinite;' class='animelogo'/>">
    <p style="color:#fff; margin-left: 25px;"> {{spinnerManager.title}} </p>
</ngx-spinner>
<router-outlet></router-outlet>