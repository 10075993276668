<form [formGroup]="upsertform">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Add Work History</h5>
            </div>
            <div class="row card-body tab2-card" >
                <div class="col-12">
                  <div class="form-group row  mr-0 ">
                    <label for="name" class="col-xl-4 col-md-4"> Designation</label>
                    <input class="form-control col-xl-8 col-md-8" formControlName="designation"
                    id="name" type="text" required="">
                </div>
                    <div class="form-group row  mr-0 ">
                        <label for="name" class="col-xl-4 col-md-4"> Hospital/Clinic Name</label>
                        <input class="form-control col-xl-8 col-md-8" formControlName="hospitalName"
                        id="name" type="text" required="">
                    </div>
                   
                    <div class="form-group row  mr-0 ">
                        <label for="name" class="col-xl-4 col-md-4"> Hospital/Clinic Contact No</label>
                        <input class="form-control col-xl-8 col-md-8" formControlName="hospitalContactNo"
                        id="name" type="text" required="">
                    </div>
                    <div class="form-group row  mr-0 ">
                        <label for="name" class="col-xl-4 col-md-4"> Working Hours</label>
                        <input class="form-control col-xl-8 col-md-8" formControlName="workingHours"
                        id="name" type="text" required="">
                    </div>
                    <div class="form-group row mr-0">
                        <label for="startDate" class="col-xl-4 col-md-4"
                          >Start Date</label
                        >
                        <input
                          class="form-control col-xl-8 col-md-8"
                          formControlName="startDate"
                          id="startDate"
                          type="date"
                        />
                      </div>
                    <div class="form-group row mr-0">
                        <label for="endDate" class="col-xl-4 col-md-4"
                          >EndDate</label
                        >
                        <input
                          class="form-control col-xl-8 col-md-8"
                          formControlName="endDate"
                          id="endDate"
                          type="date"
                        />
                      </div>
                    <div class="form-group row  mr-0 ">
                        <label for="name" class="col-xl-4 col-md-4"> Salary Per Month</label>
                        <input class="form-control col-xl-8 col-md-8" formControlName="salaryPerMonth"
                        id="name" type="text" required="" appAddDecimal>
                    </div>
                 
                    <div class="form-group row  mr-0 ">
                      <label for="name" class="col-xl-4 col-md-4"> Hospital/Clinic Address</label>
                      
                      <textarea class="form-control col-xl-8 col-md-8" formControlName="hospitalAddress"
                      id="name" style="height: 5rem;"></textarea>
                  </div>
                    <div class="row form-group">
                        
                        <div class="offset-md-4 col-md-2 col-sm-12">
                            <input
                              class="form-control"
                              formControlName="isPartTime"
                              type="checkbox"
                              class="checkbox_animated"
                              name="parttime"
                              id="parttime"

                            />
                          <label for="parttime" class="cursor-pointer"> PartTime </label>

                          </div>
                          <div class="col-md-2 col-sm-12">
                            <input
                              class="form-control"
                              formControlName="isPrivate"
                              type="checkbox"
                              class="checkbox_animated"
                              name="private"
                              id="private"
                            />
                          <label for="private"  class="cursor-pointer"> Private </label>

                        </div>
                      </div>
                  
                        <div class="row">
                            <div class="col-12">
                                <div class="pull-right">
                                    <button (click)="activeModal.close('Ok click')" type="button" class="btn btn-primary">
                                        Discard
                                    </button>
                                    <button type="button" id="btnSave" class="btn btn-success ml-2" (click)="save()">
                                        {{btnSave}}
                                    </button>
                                </div>
                            </div>
                        </div>
                       
                   
                </div>
            </div>
                               
         </div>
    </div>
</form> 
<!-- Container-fluid Ends-->