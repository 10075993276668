import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPermission } from 'src/app/shared/models/dataPermission';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { DatapermissionService } from 'src/app/shared/service/dataPermission/datapermission.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-create-data-permission',
  templateUrl: './create-data-permission.component.html',
  styleUrls: ['./create-data-permission.component.scss']
})
export class CreateDataPermissionComponent implements OnInit {
  public DataPermissoinForm:FormGroup;
  public dpid:number;
  constructor(private fb:FormBuilder,
    private route:ActivatedRoute,
    private _router:Router,
    private datapermissionService:DatapermissionService,
    private alertyfy:AlertyfyService,
    private router:Router,
    private spinningManager: SpinnerManagerService
    ) {
    this.dpid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);
      this.spinningManager.showSpinner("Loading.....");
   }

  ngOnInit(): void {
    this.createDataPermissionForm();
    this.Edit();
  }
  public createDataPermissionForm(){
    this.DataPermissoinForm=this.fb.group({
      id:[0],
      name:['',Validators.required],
      header:[''],
      description:[''],
      groupID:[0],
      isGroupMaster:[false],
      pagePath:[''],
      headerCode:[''],
      code:['']

    })
    this.spinningManager.hideSpinner();
  }
  onSubmit(){
    this.spinningManager.showSpinner("Saving.....");
    var formObj=this.DataPermissoinForm.value;
    var dataPermission = new DataPermission(formObj);
    this.datapermissionService.upsertDataPermission(dataPermission).subscribe(res=>{
      if(res){
        this.alertyfy.success("Successfully Inserted.");
        this.spinningManager.hideSpinner();
        this.router.navigate(['/PermissionManagement/list-data-permission']);
      }
    },
    error=>{
      this.spinningManager.hideSpinner();
       this.alertyfy.error(error);
  
    });
}
Edit(){
  this.datapermissionService.getDataPermissionById(this.dpid).subscribe(res=>{
    this.DataPermissoinForm.setValue(res);
    this.spinningManager.hideSpinner();
  },error=>{
    this.spinningManager.hideSpinner();
  })
}

}
