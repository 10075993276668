import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  baseUrl = environment.apiUrl;

  constructor(private apiService: ApiService) { }
  public getAllRoles(){
    return this.apiService.get('Roles/GetAll')
  }
  public getSystemPermission(id:number){
    return this.apiService.get('Roles/GetRoleSystemPermission/'+id);
  }
  public getDataPermission(id:number){
    return this.apiService.get('roles/GetRoleDataPermission/'+id);
  }

  public createRoleSystemPermission(obj:any){
    return this.apiService.post('roles/saveRoleSystemPermission', obj);
  }
  public createRoleDataPermission(obj:any){
    return this.apiService.post('roles/saveRoleDataPermission', obj);
  }

  // public updateRoles(obj:any){
  //   return this.apiService.post('Roles/UpsertRoles',obj);
  // }
  public getRoleById(id:number){
    return this.apiService.get('Roles/GetById/'+id);
  }
  public createRole(obj:any){
    return this.apiService.post('Roles/CreatetRoles',obj);
  }
  public upsert(obj:any){
    return this.apiService.post('Roles/UpsertRoles',obj);
  }
  public deleteById(id:number){
    return this.apiService.post('Roles/DeleteRole/'+id);
  }
}
