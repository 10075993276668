import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { GeneralStoreComponent } from './components/general-store/general-store.component';
import { SuccessComponent } from './components/general-store/success/success.component';
import { AuthGuard } from './shared/core/AuthGaurd';
import { LoginsuccessfulComponent } from './components/auth/loginsuccessful/loginsuccessful.component';
import { TermsandconditionsComponent } from './components/pages/termsandconditions/termsandconditions.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/default',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      datapermissionIds: ['4','5','6'],
    },
    children: content
  },
  {
    path: 'termsconditions', component: TermsandconditionsComponent
  },
  {
    // path: 'auth/login',
    path: 'registerstore',
  component: LoginComponent,
  },
  {
    path: 'auth/loginsuccessful',
    component: LoginsuccessfulComponent,
  },
  {
    path: 'sellercenter/create',
    component: GeneralStoreComponent,
  },
  {
    path: 'sellercenter/success',
    component: SuccessComponent,
  },
  {
    path: 'shared/components/page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'sign-in',
    component: SignInComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
