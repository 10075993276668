import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {

  termsConditions;
  constructor() { }

  ngOnInit(): void {
  }

}
