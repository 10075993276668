export class DataPermission{
  id: number;
  header: string;
  name: string;
  description: string;
  groupID: number | null;
  isGroupMaster: boolean | null;
  pagePath: string;
  headerCode: string;
  code: string;

   constructor(formObj: any) {
    this.id = Number(formObj.id) || 0;
    this.header = formObj.header;
    this.name = formObj.name;
    this.description = formObj.description;
    this.groupID = formObj.groupID;
    this.isGroupMaster = formObj.isGroupMaster;
    this.pagePath = formObj.pagePath;
    this.headerCode = formObj.headerCode;
    this.code = formObj.code;
  }
}
