import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chetra } from 'src/app/shared/models/chetra';
import { ChetraQueryObject } from 'src/app/shared/models/queryObject';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ChetraService } from 'src/app/shared/service/ManageDelivery/chetra.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chetra-list',
  templateUrl: './chetra-list.component.html',
  styleUrls: ['./chetra-list.component.scss']
})
export class ChetraListComponent implements OnInit {

  showFilters: Boolean = false;
  queryObject = new ChetraQueryObject();
  chetraList: Chetra[] =[];
  noDataFound: string;
  pageSize = environment.pageSize;

  columns = [
    { title: ' ID', key: 'id', isSortable: true },
    { title: ' Chetra', key: 'chetra', isSortable: true },
    { title: 'Description', key: 'description', isSortable: true },
    { title: 'Pradesh', key: 'pradesh', isSortable: true },
    { title: 'Chetra Code', key: 'chetraCode', isSortable: true },
    { title: 'Action' }
  ];

  constructor(private _router: Router,
    private spinningManager: SpinnerManagerService,
    private chetraService: ChetraService,
    private alertyfy: AlertyfyService) {
      this.spinningManager.showSpinner("Loading.....");
    }


  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.queryObject.pageSize = 10;
    this.Reset();
  }

  getChetra(){
    this.spinningManager.showSpinner('Loading.....');
    this.chetraService.getChetra(this.queryObject).subscribe(
      (res: any) => {
        this.queryObject.pageCount = res.totalItems;
        this.chetraList = res.items;
        this.spinningManager.hideSpinner();
      (error) => {
        this.noDataFound = error;
        this.spinningManager.hideSpinner();
      }
      });
  }

  onCreateConfirm() {
    this._router.navigateByUrl('/managedelevery/add-chetra');
  }

  showHideFilter() {
    this.showFilters = !this.showFilters;

  }

  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.pradeshSabhaId = 0;
    this.queryObject.pageSize = this.pageSize;
    this.getChetra();
  }

  onDeleteConfirm(id) {
    this.alertyfy.confirm
      (
        "Are you sure you want to delete?",
        () => {
          this.chetraService.deleteChetra(id)
            .subscribe(res => {
              this.alertyfy.success("Deleted Successfully.");
              this.getChetra();
            },
              (error) => {
                this.alertyfy.error("Something went wrong. Delete Failed.");
              });
        }
      );
  }

  public sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    // this.getMunicipalities();
  }

  public onPageChange(page) {
    this.queryObject.page = page;
    this.getChetra();
  }

  public exportToExcel(flag: boolean) {

    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.xlsx');
    // });
  }
  public exportToPdf(flag: boolean) {
    // this.queryObject.printall = flag;
    // this.countryService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
    //   importedSaveAs(res, 'CountryList.pdf');
    // });
  }


}
