import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RegistedBranchService {
  constructor(private apiService:ApiService) {

  }
  getAll(){
    return this.apiService.get('RegistedBranch/GetAll');
  }
  
  getById(id: any){
    return this.apiService.get('RegistedBranch/GetById?id='+id);
  }
  create(data:any){
    return this.apiService.post('RegistedBranch/Create',data);
  }
  update(data:any){
    return this.apiService.post('RegistedBranch/Update',data);
  }
  delete(id:any){
    return this.apiService.post('RegistedBranch/Delete?id='+id);
  }
  search(query: QueryObject){
    return this.apiService.post('RegistedBranch/GetQueryRegistedBranch', query);
}
}
