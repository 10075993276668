import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';
import { userdetails } from '../../models/profile';


@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  baseUrlForImage = environment.imageUrl;

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient,private apiService: ApiService) { }
  
  public getSystemPermission(id:number){
    return this.apiService.get('UserDetails/GetUserSystemPermission/'+id);
  }
  public getDataPermission(id:number){
    return this.apiService.get('UserDetails/GetUserDataPermission/'+id);
  }

  public createUserSystemPermission(obj:any){
    return this.apiService.post('UserDetails/saveUserSystemPermission', obj);
  }
  public createUserDataPermission(obj:any){
    return this.apiService.post('UserDetails/saveUserDataPermission', obj);
  }
  getGenders(){
    return this.apiService.get('UserDetails/GetGenderEnum');
  }
  changeProfilePicture(data){
    return this.apiService.post('UserDetails/ChangeProfilePicture',data);
  }
  saveProfile(userDetails:any){
    return this.apiService.post('UserDetails/UpdateUserDetail',userDetails);
  }
  saveAddress(addressDetail:any){
    return this.apiService.post('UserDetails/UpdateUserAddress',addressDetail);
  }
  getUserDetailsById(){
    return this.apiService.get('UserDetails/GetUserDetailsById');
  }
  createUser(user){
    return this.http.post(this.baseUrl + 'UserDetails/CreateStoreOwner/', user);
  }
  getUserList(query: QueryObject){
    return this.http.post(this.baseUrl + 'UserDetails/UserDetailsList', query);
  }
  getRoles(){
    return this.http.get(this.baseUrl + 'UserDetails/RoleList');
  }
  registerUser(user){
    return this.http.post(this.baseUrl + 'UserDetails/RegisterUser/', user);
  }
  getUserDetailsWithRoles(id){
    return this.http.get(this.baseUrl + 'UserDetails/' + id);
  }
  public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('UserDetails/ExportToExcel',query);
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('UserDetails/ExportToPdf',query);
  }
  AffiliateViewDetails(id){
    return this.http.get(this.baseUrl + 'UserDetails/ViewAffiliateDetails/' + id);
  }

  AffiliateChoosenCategory(){
    return this.http.get(this.baseUrl + 'UserDetails/AffiliateCategory');
  }

  AffiliateChoosenCategoryById(id){
    return this.http.get(this.baseUrl + 'UserDetails/AffiliateCategory/' + id);
  }
  AddAffiliate(affiliate:any){
    return this.http.post(this.baseUrl + 'UserDetails/AffiliateRegistration', affiliate);
  }
  UpdateAffiliate(affiliate: any){
    return this.http.post(this.baseUrl + 'UserDetails/AffiliateAdditionalRegistration', affiliate);
  }
  
  AffilaiteInsterViewAnswer(){
    return this.http.get(this.baseUrl + 'UserDetails/AffiliateInterviewAnswer');
  }

  AffilaiteInsterViewAnswerById(id){
    return this.http.get(this.baseUrl + 'UserDetails/AffiliateInterviewAnswer/' + id);
  }
  public approveSubAffiliate(id){
    return this.http.post(`${this.baseUrl}UserDetails/ApproveSubAffiliates`, {id});
  }
  public rejectSubAffiliate(id){
    return this.http.post(`${this.baseUrl}UserDetails/RejectSubAffiliates`, {id});
  }

  public sendPasswordResetLink(id){
    return this.http.get(`${this.baseUrl}UserDetails/SendResetPasswordLink/${id}`);
  }

  checkDuplicateEmailAddress(email){
    var obj = {
      firstName: email,
      lastName: email,
      email: email,
      phoneNumber: email
    }
    return this.http.post(`${this.baseUrl}UserDetails/CheckIfEmailExists`, obj);
  }

  public changePassword(data){
    return this.http.post(`${this.baseUrl}UserDetails/ChangePassword`,data);
  }

  public sendConfirmationLink(id){
    return this.http.get(`${this.baseUrl}UserDetails/SendEmailConfirmationLink/${id}`);
  }

  public lockUnlockAccount(id){
    return this.http.get(`${this.baseUrl}UserDetails/LockUnlockAccount/${id}`);
  }

  public syncIdentityUsers(){
    return this.http.get(`${this.baseUrl}UserDetails/SyncUserWithIdentityServer`);
  }
  public getUserProfileInfo(){
    const item:any = JSON.parse(localStorage.getItem('userDetails'));
    return item;
    
  }
  public getUserProfileImage(){
    const item:any = JSON.parse(localStorage.getItem('userDetails'));
    return this.baseUrlForImage+item.imagePath;

  }
  public getUserName(){
    const item:any = JSON.parse(localStorage.getItem('userDetails'));
    return item.firstName+" "+item.lastName;
  }
  public getUserRoles(){
    const item:any = JSON.parse(localStorage.getItem('userDetails'));
    return item.roleNames;
  }

  public login(body: any){
    return this.http.post(`${this.baseUrl}Account/SignIn`, body);
  }
  
}
