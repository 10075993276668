import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ListSystemPermissionComponent } from './list-system-permission/list-system-permission.component';
import { CreateSystemPermissionComponent } from './create-system-permission/create-system-permission.component';
import { ListDataPermissionComponent } from './list-data-permission/list-data-permission.component';
import { CreateDataPermissionComponent } from './create-data-permission/create-data-permission.component';
import { AuthGuard } from 'src/app/shared/core/AuthGaurd';
import { systemPermissions } from 'src/app/shared/enums/systemPermissions';

const routes:Routes=[
  {
    path:'',
    children:[
      {
        path:'list-system-permission',
        component:ListSystemPermissionComponent,
        canActivate: [AuthGuard],
        data:{
          title:"System Permission",
          systemPermissionIds:[systemPermissions.SystemPermission],
          breadcrumb:"List System Permission"
        }
      },
       {
        path:'create-system-permission',
        component:CreateSystemPermissionComponent,
        data:{
          title:"Create System Permission",
          breadcrumb:"Create System Permission"
        }
      },
      {
        path:'create-system-permission/:id',
        component:CreateSystemPermissionComponent,
        data:{
          title:"Create System Permission",
          breadcrumb:"Create System Permission"
        }
      },
      {
        path:'list-data-permission',
        component:ListDataPermissionComponent,
        canActivate: [AuthGuard],
        data:{
          title:"Data Permission",
          systemPermissionIds:[systemPermissions.DataPermission],
          breadcrumb:"List Data Permission"
        }
      },
       {
        path:'create-data-permission',
        component:CreateDataPermissionComponent,
        data:{
          title:"Create Data Permission",
          breadcrumb:"Create Data Permission"
        }
      },
       {
        path:'create-data-permission/:id',
        component:CreateDataPermissionComponent,
        data:{
          title:"Create Data Permission",
          breadcrumb:"Create Data Permission"
        }
      },

    ]
    }
]
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports:[RouterModule]
})
export class PermissionmanagementRoutingModule { }
