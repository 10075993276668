import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authConfig } from './sso.config';
import { NgxSpinnerService } from "ngx-spinner";
import { SpinnerManagerService } from './shared/service/spinnerManager.service';
import { UserDetailsService } from './shared/service/userDetails/userDetails.service';
import { AuthService } from './shared/core/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  ngOnInit() {
    //this.spinner.show();

    // setTimeout(() => {

    //   this.spinner.hide();
    // }, 2000);
    //this.token();
  }
  constructor(private router: Router, private spinner: NgxSpinnerService,
    public spinnerManager: SpinnerManagerService, private userdetailsService: UserDetailsService,
    private authService: AuthService) {
    // this.configure();
    // const claims = this.oauthService.getIdentityClaims();
    // if(claims === null){
    //   this.router.navigate(['/auth/login']);
    //   return;
    // }
    // else{
    //   this.router.navigate(['/dashboard/default']);
    // }
  }

  login() {
    //this.configure();
    // this.oauthService.initCodeFlow();
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/sign-in']);
    //this.configure();
    // this.oauthService.logOut();
  }
  token() {
    // let claims: any = this.oauthService.getIdentityClaims();
    // localStorage.setItem('token', this.oauthService.getAccessToken());
    // return claims;
  }


  title = 'multikart-backend';
}
