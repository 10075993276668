import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Program } from 'src/app/shared/models/program';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ProgramService } from 'src/app/shared/service/ManageDelivery/program.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-program',
  templateUrl: './add-program.component.html',
  styleUrls: ['./add-program.component.scss']
})
export class AddProgramComponent implements OnInit {

  btnSave = 'Save';
  programForm: FormGroup;
  programid: number = 0;

  constructor(    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private programService: ProgramService,
    private spinningManager: SpinnerManagerService,
    private alertify: AlertyfyService) {
    this.programForm = this.fb.group({
      id: '',
      name: '',
      description: '',
      code: ''
    });

    this.programid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

        if (this.programid > 0) {
          this.editProgram();
        }
        this.spinningManager.hideSpinner();
   }

  ngOnInit(): void {
  }

editProgram() {
  this.programService.getProgramById(this.programid).subscribe((result) => {
    this.spinningManager.showSpinner("Loading.....");
    this.programForm = this.fb.group({
      name: result["name"],
      description: result["description"],
      code: result["code"],
    });
    this.btnSave = 'Update';
  }, (error) => {
    this.alertify.error(error.error);
    this.router.navigateByUrl('/managedelevery/program-list');
  })
  this.spinningManager.hideSpinner();
}

saveProgramList() {
  if (this.programid && this.programid > 0) {
    const dataupdate = {
      id: this.programid, name: this.programForm.controls.name.value,
      description: this.programForm.controls.description.value,
      code: this.programForm.controls.code.value
    };
    this.programService.updateProgram(dataupdate).subscribe(result => {
      this.spinningManager.showSpinner("Saving.....");
      this.router.navigateByUrl('/managedelevery/program-list');
      this.spinningManager.hideSpinner();
    }, error => {
      this.spinningManager.hideSpinner();
    })
  } else {
    var _program = new Program();
    var program = this.programForm.value;
    _program.id = Number(program.id) || 0;
    _program.name = program.name;
    _program.description = program.description;
    _program.code = program.code;

    this.programService.createProgram(_program)
      .subscribe(res => {
        if (res) {
          this.programForm.reset();
          this.router.navigateByUrl('/managedelevery/program-list');
          this.spinningManager.hideSpinner();
        }
      }, error => {
        this.alertify.error(error);
        this.spinningManager.hideSpinner();
      }
      );
  }
  error => {
    this.spinningManager.hideSpinner();
    this.router.navigateByUrl('/managedelevery/program-list');
  }

}

}
