export class District {
   // [x: string]: number;
    id: number;
    name:string;
    description: string;
    districtCode:string;
    provinceId:Number;
    provinceName: string;
    countryName:string;
   countryId:number;
 provinceCode:string;
}