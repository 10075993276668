import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PratinidhiSabha } from 'src/app/shared/models/pratinidhiSabha';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { DistrictService } from 'src/app/shared/service/ManageDelivery/district.service';
import { PratinidhiSabhaService } from 'src/app/shared/service/ManageDelivery/pratinidhi-sabha.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-pratinidhi-sabha',
  templateUrl: './add-pratinidhi-sabha.component.html',
  styleUrls: ['./add-pratinidhi-sabha.component.scss'],
})
export class AddPratinidhiSabhaComponent implements OnInit {
  pratinidhiSabhaForm: FormGroup;
  btnSave = 'Save';
  pratinidhiSabhaid: number = 0;
  districtList=[];

  constructor(
    private fb: FormBuilder,
    private spinningManager: SpinnerManagerService,
    private pratinidhiSabhaService: PratinidhiSabhaService,
    private router: Router,
    private alertyfy: AlertyfyService,
    private districtService: DistrictService,
    private route: ActivatedRoute
  ) {
    this.pratinidhiSabhaForm = this.fb.group({
      districtId: '',
      name: '',
      description: '',
      pratinidhiSabhaCode: '',
    });

    this.pratinidhiSabhaid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

    if (this.pratinidhiSabhaid > 0) {
      this.editPratinidhiSabha();
    }
    this.spinningManager.hideSpinner();
  }

  ngOnInit(): void {
    this.getAllDistrict();
  }

  savePratinidhiSabha() {
    this.spinningManager.showSpinner('Saving.....');
    this.pratinidhiSabhaid = Number(this.pratinidhiSabhaid);
    if (this.pratinidhiSabhaid && this.pratinidhiSabhaid > 0) {
      const pratinidhiUpdateData = {
        id: this.pratinidhiSabhaid,
        name: this.pratinidhiSabhaForm.controls.name.value,
        description: this.pratinidhiSabhaForm.controls.description.value,
        districtId: this.pratinidhiSabhaForm.controls.districtId.value,
        pratinidhiSabhaCode: this.pratinidhiSabhaForm.controls.pratinidhiSabhaCode.value
      };
      this.pratinidhiSabhaService
        .updatePratinidhiSabha(pratinidhiUpdateData)
        .subscribe(
          (result) => {
            this.router.navigateByUrl('/managedelevery/pratinidhi-sabha-list');
            this.spinningManager.hideSpinner();
          },
          (error) => {
            this.spinningManager.hideSpinner();
          }
        );
    } else {
      var pratinidhiSabha = new PratinidhiSabha();
      var pratinidhiSabhaObj = this.pratinidhiSabhaForm.value;
      pratinidhiSabha.id = Number(pratinidhiSabhaObj.id) || 0;
      pratinidhiSabha.name = pratinidhiSabhaObj.name;
      pratinidhiSabha.description = pratinidhiSabhaObj.description;
      pratinidhiSabha.districtId = Number(pratinidhiSabhaObj.districtId);
      pratinidhiSabha.pratinidhiSabhaCode = pratinidhiSabhaObj.pratinidhiSabhaCode;
      this.pratinidhiSabhaService
        .createPratinidhiSabha(pratinidhiSabha)
        .subscribe(
          (res) => {
            if (res) {
              this.alertyfy.success('Successfully Inserted.');
              this.pratinidhiSabhaForm.reset();
              this.router.navigateByUrl('/managedelevery/pratinidhi-sabha-list');
            }
          },
          (error) => {
            this.alertyfy.saveError('Pratinidhi Sabha');
          }
        );
    }
  }

  editPratinidhiSabha(){
    this.pratinidhiSabhaService.getPratinidhiSabhaById(this.pratinidhiSabhaid).subscribe(
      (result) => {
        this.spinningManager.showSpinner('Loading.....');
        this.pratinidhiSabhaForm = this.fb.group({
          name: result['name'],
          description: result['description'],
          districtId: result['districtId'],
          pratinidhiSabhaCode: result['pratinidhiSabhaCode'],
        });
        this.btnSave = 'Update';
      },
      (error) => {
        this.alertyfy.error(error.error);
        this.router.navigateByUrl('/managedelevery/pratinidhi-sabha-list');
      }
    );
    this.spinningManager.hideSpinner();
  }

  getAllDistrict() {
    this.districtService.getAllDistrict().subscribe(
      (res) => {
        this.districtList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertyfy.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }
}
