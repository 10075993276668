import { Component, OnInit } from '@angular/core';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';
import { DataPermission } from 'src/app/shared/models/dataPermission';
import { DataPermissionQueryObject, QueryObject } from 'src/app/shared/models/queryObject';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { DatapermissionService } from 'src/app/shared/service/dataPermission/datapermission.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-list-data-permission',
  templateUrl: './list-data-permission.component.html',
  styleUrls: ['./list-data-permission.component.scss']
})
export class ListDataPermissionComponent implements OnInit {
  public dataPermissionList: DataPermission[];
  public dataPermission = DataPermissions;
  noDataFound: string;
  columns = [
    { title: "Id", key: "Id", isSortable: true },
    { title: "Name", key: "Name", isSortable: true },
    { title: "Description", key: "Description", isSortable: true },
    { title: "Header Code", key: "Header Code", isSortable: true },
    { title: "Code", key: "Code", isSortable: true },
    // { title: "Is Group Master", key: "IsGroupMaster", isSortable: true },
    { title: "Action" },
  ];
  queryObject = new DataPermissionQueryObject();
  constructor(private dataPermissionService: DatapermissionService,
    private alertify: AlertyfyService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }

  ngOnInit(): void {
    this.noDataFound = environment.noDataFound;
    this.queryObject.page = 1;
    this.queryObject.pageSize = 10;
    this.getAllItem();
  }
  sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.getAllItem();

  }
  onPageChange(page) {
    this.queryObject.page = page;
    this.getAllItem();
  }
  public getAllItem() {
    this.dataPermissionService.getAllItem(this.queryObject).subscribe((res: any) => {
      this.queryObject.pageCount = res.totalItems;
      this.dataPermissionList = res.items;
      this.spinningManager.hideSpinner();
    }, error => {
      this.noDataFound = error;
      this.spinningManager.hideSpinner();
    });

  }
  public onDeleteConfirm(id: number) {
    if (confirm("Do you really want to delete?")) {
      this.dataPermissionService.deletDataPermission(id).subscribe((result) => {
        this.getAllItem();
        this.alertify.success("Deleted successfully");
      });
    }
  }

}
