import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { QueryObject } from '../../models/queryObject';

@Injectable({
  providedIn: 'root'
})
export class DoctorWorkHistoryService {
  constructor(private apiService: ApiService) { }
  getAll(){
    return this.apiService.get('DoctorWorkHistory/GetAll');
  }
  getById(id: any){
    return this.apiService.get('DoctorWorkHistory/GetById?id='+id); 

  }
  create(data:any){
    return this.apiService.post('DoctorWorkHistory/Create',data)
  }
  delete(id:any){
    return this.apiService.post('DoctorWorkHistory/delete?id='+id);
  }
  update(data:any){
    return this.apiService.post('DoctorWorkHistory/update',data);
  }
  getMember(query: QueryObject){
    return this.apiService.post('DoctorWorkHistory/DoctorWorkHistoryItem', query);
}
search(query: QueryObject){
  return this.apiService.post('DoctorWorkHistory/Search', query)
}

  

  
}
