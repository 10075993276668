import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {
  [x: string]: any;
  constructor(private apiService:ApiService) {

  }
  getAllIncludingCountry(){
    return this.apiService.get('Province/GetAllIncludingCountry');
  }
  getAllProvince(){
    return this.apiService.get('Province/GetAll');
  }
  getProvinceById(id: any){
    return this.apiService.get('Province/GetById/'+id);
  }

  getProvinceByCountry(countryId){
    return this.apiService.get('Province/GetByCountryId/'+countryId);
  }

  upsertProvince(data:any){
    return this.apiService.post('Province/UpsertProvince',data);
  }
  deleteProvince(id:any){
    return this.apiService.post('Province/DeleteProvince/'+id);
  }
  getProvince(query: QueryObject){
    return this.apiService.post('Province/ProvinceItem', query);
}
 public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('Province/ExportToExcel',query)
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('Province/ExportToPdf',query);
  }
}

