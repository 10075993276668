import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ActionhistoryService {

  constructor(private apiService: ApiService) { }

  public getAll(data){
    return this.apiService.post(`ActionHistory/GetAll`, data);
  }

  public create(comment: string, typeId: number, code: string, codeId: number){
    var obj = {
      comment,
      typeId,
      code,
      codeId
    }
    return this.apiService.post(`ActionHistory/Create`, obj);
  }
}
