import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ChetraService {

  constructor(private apiService: ApiService) { }
  getAllChetra(){
    return this.apiService.get('Chetra/GetAll');
  }
  getChetraById(id: any){
    return this.apiService.get('Chetra/getbyid/?id='+id);
  }
  createChetra(data:any){
    return this.apiService.post('Chetra/Create',data)
  }
  deleteChetra(id:any){
    return this.apiService.post('Chetra/delete/?id='+id);
  }
  updateChetra(data:any){
    return this.apiService.post('Chetra/update',data);
  }

  getChetra(query:QueryObject){
    return this.apiService.post('Chetra/ChetraItem',query);
  }

}
