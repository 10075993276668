<form [formGroup]="chetraForm">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Add Chetra</h5>
            </div>
            <div class="row card-body tab2-card">
                <div class="col-12">
                    <div class="form-group row">
                        <label for="pradeshSabhaId" class="col-xl-3 col-lg-3 col-md-3">Pradesh Sabha</label
            >
            <div class="col-xl-9 col-md-8">
              <select
                class="form-control"
                formControlName="pradeshSabhaId"
                id="pradeshSabhaId"
              >
              <option value="" disabled>--Select Pradesh Sabha--</option>
              <option *ngFor="let obj of pradeshList;let i=index" [value]='obj.id'
              >
                {{ obj.name }}
              </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-xl-3 col-lg-3 col-md-3"
              ><span>*</span>Chetra</label
            >
            <div class="col-xl-9 col-md-8">
              <input
                class="form-control"
                formControlName="name"
                name="name"
                type="text"
                required=""
              />
            </div>
          </div>
          <div class="form-group row editor-label">
            <label class="col-xl-3 col-lg-3 col-md-3">Description</label>
                        <div class="col-xl-9 col-md-8">
                            <textarea rows="4" class="w-100 form-control" formControlName="description" required></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="chetraCode" class="col-xl-3 col-lg-3 col-md-3">Chetra Code</label
            >
            <div class="col-xl-9 col-md-8">
              <input
                class="form-control"
                formControlName="chetraCode"
                id="chetraCode"
                type="text"
                required=""
              />
            </div>
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-primary"
              [routerLink]="['/managedelevery/chetra-list']"
            >
              Discard
            </button>
            <button
              type="button"
              class="btn btn-success ml-2"
              (click)="saveChetra()"
            >
              {{ btnSave }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
