export class DoctorWorkHistory {
    id: number;
    hospitalName: string;
    isPrivate:  boolean;
    hospitalAddress:string;
    hospitalContactNo:string;
    isPartTime:boolean;
    workingHours:string;
    startDate:string;
    endDate:string;
    salaryPerMonth:number;
    designation:string;
    doctorId:number;
   
}


