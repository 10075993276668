import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Chetra } from 'src/app/shared/models/chetra';
import { PradeshSabha } from 'src/app/shared/models/pradeshSabha';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { ChetraService } from 'src/app/shared/service/ManageDelivery/chetra.service';
import { PradeshSabhaService } from 'src/app/shared/service/ManageDelivery/pradesh-sabha.service';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';

@Component({
  selector: 'app-add-chetra',
  templateUrl: './add-chetra.component.html',
  styleUrls: ['./add-chetra.component.scss']
})
export class AddChetraComponent implements OnInit {

  chetraForm: FormGroup;
  btnSave = 'Save';
  chetraid: number = 0;
  pradeshList: [];

  constructor(private fb: FormBuilder,
    private chetraService: ChetraService,
    private spinningManager: SpinnerManagerService,
    private router: Router,
    private alertyfy: AlertyfyService,
    private pradeshService: PradeshSabhaService,
    private route: ActivatedRoute) {
    this.chetraForm = this.fb.group({
      pradeshSabhaId: '',
      name: '',
      description:'',
      chetraCode:'',
    });

    this.chetraid =
      this.route.snapshot.params.id === undefined
        ? 0
        : parseInt(this.route.snapshot.params.id);

    if (this.chetraid > 0) {
      this.editchetra();
    }
    this.spinningManager.hideSpinner();
  }

  ngOnInit(): void {
    this.getAllPradesh();
  }

  getAllPradesh(){
    this.pradeshService.getAllPradeshSabha().subscribe(
      (res) => {
        this.pradeshList = res;
        this.spinningManager.hideSpinner();
      },
      (error) => {
        this.alertyfy.error(error);
        this.spinningManager.hideSpinner();
      }
    );
  }

  saveChetra() {
    this.spinningManager.showSpinner('Saving.....');
    this.chetraid = Number(this.chetraid);
    if (this.chetraid && this.chetraid > 0) {
      const chetraUpdateData = {
        id: this.chetraid,
        name: this.chetraForm.controls.name.value,
        description: this.chetraForm.controls.description.value,
        pradeshSabhaId: this.chetraForm.controls.pradeshSabhaId.value,
        chetraCode: this.chetraForm.controls.chetraCode.value
      };
      this.chetraService
        .updateChetra(chetraUpdateData)
        .subscribe(
          (result) => {
            this.router.navigateByUrl('/managedelevery/chetra-list');
            this.spinningManager.hideSpinner();
          },
          (error) => {
            this.spinningManager.hideSpinner();
          }
        );
    } else {
      var chetra = new Chetra();
      var chetraObj = this.chetraForm.value;
      chetra.id = Number(chetraObj.id) || 0;
      chetra.name = chetraObj.name;
      chetra.description = chetraObj.description;
      chetra.pradeshSabhaId = Number(chetraObj.pradeshSabhaId);
      chetra.chetraCode = chetraObj.chetraCode;
      this.chetraService
        .createChetra(chetra)
        .subscribe(
          (res) => {
            if (res) {
              this.alertyfy.success('Successfully Inserted.');
              this.chetraForm.reset();
              this.router.navigateByUrl('/managedelevery/chetra-list');
            }
          },
          (error) => {
            this.alertyfy.saveError('Chetra Sabha');
          }
        );
    }
  }

  editchetra(){
    this.chetraService.getChetraById(this.chetraid).subscribe(
      (result) => {
        this.spinningManager.showSpinner('Loading.....');
        this.chetraForm = this.fb.group({
          name: result['name'],
          description: result['description'],
          pradeshSabhaId: result['pradeshSabhaId'],
          chetraCode: result['chetraCode'],
        });
        this.btnSave = 'Update';
      },
      (error) => {
        this.alertyfy.error(error.error);
        this.router.navigateByUrl('/managedelevery/chetra-list');
      }
    );
    this.spinningManager.hideSpinner();
  }

}
