import { Injectable } from '@angular/core';
import { QueryObject } from '../../models/queryObject';
import { ApiService } from '../api.service';


@Injectable({
  providedIn: 'root'
})
export class CityService {
  constructor(private apiService:ApiService) {

}
  getAllCity(){
    return this.apiService.get('CityList/GetAll');
  }
  getAllIncludingCountry(){
    return this.apiService.get('CityList/GetAllIncludingCountry');
  }
  getCityListById(id: any){
    return this.apiService.get('CityList/GetById/'+id);
  }
  getCityById(id: any){
    return this.apiService.get('CityList/GetCityById/'+id);
  }
  getCityByDistrictId(districtId: any){
    return this.apiService.get('CityList/GetCitiesByDistrictId/'+districtId);
  }
  upsertCityList(data:any){
    return this.apiService.post('CityList/UpsertCityList',data);
  }
  deleteCity(id:any){
    return this.apiService.post('CityList/DeleteCityList/'+id);
  }
  getCityList(query){
    return this.apiService.post('CityList/CityListItem', query);
}
public exportAllItemsToExcel(query:QueryObject){
    return this.apiService.downloadFile('CityList/ExportToExcel',query)
  }
  public exportAllItemsToPDF(query:QueryObject){
    return this.apiService.downloadFile('CityList/ExportToPdf',query);
  }

}
