import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },

  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },

  {
    path: 'managedelevery',
    loadChildren: () => import('../../components/managedelevery/managedelevery.module').then(m => m.ManagedeleveryModule),
    data: {
      breadcrumb: "Manage Locations"
    }
  },

  {
    path: 'PermissionManagement',
    loadChildren: () => import('../../components/permission-management/permission-management.module').then(m => m.PermissionManagementModule),
    data: {
      breadcrumb: "Permission Management"
    }
  },

];
