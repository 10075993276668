<div class="container-fluid" style="padding: 40px 0px;">
    <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
            <img src="../../assets/images/logo.png" style="width: 150px; display: block; margin:auto; margin-bottom: 25px;" />
            <a (click)="logout()" class="btn btn-secondary d-block mx-auto" style="background-color:transparent !important; border:none;"> <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
            </a>
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Master List</h5>
                </div> -->
                <div class="card-body">
                    <div class="box">

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>