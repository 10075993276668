import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  signIn(socialAuthService: any) {
    throw new Error('Method not implemented.');
  }
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isLoggedIn() {
    if (localStorage.getItem('token')) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn.asObservable();
  }

  get checkIsLoggedIn() {
    if (localStorage.getItem('token')) { return new BehaviorSubject<boolean>(true).asObservable(); }
    return new BehaviorSubject<boolean>(false).asObservable();
  }

  public user: BehaviorSubject<string> = new BehaviorSubject<string>('');
  get username() {
    if (localStorage.getItem('userDetails')) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      var username = userDetails.firstName !== '' ? userDetails.firstName : userDetails.email;
      this.user.next(username);
    } else {
      this.user.next('');
    }
    return this.user.asObservable();
  }


  public userImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  get profileImage() {
    if (localStorage.getItem('userDetails')) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(userDetails.imagePath);
      this.userImage.next(userDetails.imagePath);
    } else {
      this.userImage.next('');
    }
    return this.userImage.asObservable();
  }


  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }


  logout() {
    this.loggedIn.next(false);
    this.user.next('');
    localStorage.removeItem('token');
    localStorage.removeItem('cart');
    localStorage.removeItem('productVoucher');
    localStorage.removeItem('deliveryVoucher');
    localStorage.removeItem('productCoupon');
    localStorage.removeItem('deliveryCoupon');
    localStorage.removeItem('user');
    localStorage.removeItem('userDetails');
    this.router.navigate(['/']);
  }
}

