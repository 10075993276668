import { Component, OnInit } from '@angular/core';
import { RegionService } from 'src/app/shared/service/ManageDelivery/region.service';
import { Router } from '@angular/router';
import { AlertyfyService } from 'src/app/shared/service/alertyfy.service';
import { SearchQueryObject } from '../../../shared/models/queryObject';
import { Region } from 'src/app/shared/models/region';
import { environment } from 'src/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { SpinnerManagerService } from 'src/app/shared/service/spinnerManager.service';
import { ManagePermissionService } from 'src/app/shared/service/ManagePermissions/manage-permission.service';
import { DataPermissions } from 'src/app/shared/enums/DataPermissions';

@Component({
  selector: 'app-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.scss']
})
export class RegionListComponent implements OnInit {
  pageSize = environment.pageSize;
  public regions = [];
  public regionItem: any = [];
  noDataFound: string;
  queryObject = new SearchQueryObject();
  showFilters: Boolean = false;
  public dataPermission = DataPermissions;

  columns = [
    { title: 'ID', key: 'Id', isSortable: true },
    { title: 'Region Name', key: 'Name', isSortable: true },
    { title: 'Description', key: 'Description', isSortable: true },
    { title: 'Country Name', key: 'CountryName', isSortable: true },
    { title: 'Action' }
  ];

  constructor(
    private regionService: RegionService,
    private _router: Router,
    private alertyfy: AlertyfyService,
    private spinningManager: SpinnerManagerService,
    public mps: ManagePermissionService,
  ) {
    this.spinningManager.showSpinner("Loading.....");
  }
  ngOnInit() {
    this.noDataFound = environment.noDataFound;
    this.Reset();
  }

  getRegions() {
    this.regionService.getRegion(this.queryObject).subscribe((res: any) => {
      this.queryObject.pageCount = res.totalItems;
      this.regions = res.items;
      this.spinningManager.hideSpinner();
    }, error => {
      this.spinningManager.hideSpinner();
      this.noDataFound = error;
    });
  }


  showHideFilter() {
    this.showFilters = !this.showFilters;
  }

  public Reset() {
    this.queryObject.isSortAsc = true;
    this.queryObject.sortBy = '';
    this.queryObject.searchString = '';
    this.queryObject.page = 1;
    this.queryObject.pageSize = this.pageSize;
    this.getRegions();
  }

  public Search() {
    this.getRegions();
  }

  public sortBy(columnName) {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.getRegions();
  }

  public onPageChange(page) {
    this.queryObject.page = page;
    this.getRegions();
  }

  public changePageSize(event) {
    this.queryObject.pageSize = Number(event.target.value);
    this.getRegions();
  }





  onDeleteConfirm(id) {
    this.alertyfy.confirm(
      "Are you sure you want to delete?",
      () => {
        this.regionService.deleteRegion(id)
          .subscribe(res => {
            this.alertyfy.success("Deleted Successfully.");
            this.getRegions();
          },
            (error) => {
              this.alertyfy.error("Something went wrong. Delete Failed.");
            })

      });

  }
  public exportToExcel(flag: boolean) {

    this.queryObject.printall = flag;
    this.regionService.exportAllItemsToExcel(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'Regions.xlsx');
    });
  }
  public exportToPdf(flag: boolean) {
    this.queryObject.printall = flag;
    this.regionService.exportAllItemsToPDF(this.queryObject).subscribe((res: Blob) => {
      importedSaveAs(res, 'Regions.pdf');
    });
  }

}

