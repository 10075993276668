import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertyfyService } from '../../../shared/service/alertyfy.service';
import { UserDetailsService } from '../../../shared/service/userDetails/userDetails.service';
// import {authConfig} from '../login/sso.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
               private userDetailsService: UserDetailsService
              , private router: Router
              , private alertyfy: AlertyfyService) {
                //this.configure();
             
    }

 

  owlcarousel = [
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

 
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName : ['', Validators.required],
      phoneNumber : ['', Validators.required],
      userName: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      //confirmpassword: ['', Validators.required]
    }
    //  ,{
    //   validator: this.checkIfMatchingPasswords('phoneNumber', 'confirmpassword')
    //  }
  );
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      }
      else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  get r(){return this.registerForm.controls;}


  ngOnInit() {
    this.createRegisterForm();
  }
 
  login(){
    // this.oauthService.initCodeFlow();
  }

  onSubmit() {
    
    this.submitted= true;
    if(this.registerForm.invalid){
      return;
    }
    const registerForm = this.registerForm.value;
    const userName = registerForm.userName;
    const password = registerForm.password;
    const firstName = registerForm.firstName;
    const lastName = registerForm.lastName;
    const phoneNumber = registerForm.phoneNumber;
    this.userDetailsService.createUser({userName, password, firstName, lastName, phoneNumber}).subscribe((x: any) => {
      localStorage.setItem('storeUserId', x);
      this.router.navigate(['/sellercenter/create']);
    },error=>{
      this.alertyfy.error(error.error);
    });
  }

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
